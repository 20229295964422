export type TestQuestionAnswerStatus = ('active'|'inactive');

export class TestQuestionAnswer {
    id?: number;
    answer?: string;
    image?: string;
    is_correct?: boolean;
    status?: TestQuestionAnswerStatus;

    static fromJson(fields: any): TestQuestionAnswer {
        let item = Object.assign(new this(), fields);

        item.is_correct = !!item.is_correct;

        return item;
    }
}
