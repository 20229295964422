import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
    {
        canActivate: [AuthGuard],
        path: '',
        loadChildren: () => import('src/app/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
        ),
        data: {
            title: null,
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'profile',
        loadChildren: () => import('src/app/profile/profile.module').then(
            (m) => m.ProfileModule
        ),
        data: {
            roles: ['admin', 'marketing', 'partner', 'teacher', 'student'],
            title: 'Профил',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'company',
        loadChildren: () => import('src/app/company/company.module').then(
            (m) => m.CompanyModule
        ),
        data: {
            roles: ['partner'],
            title: 'Компания',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'awards',
        loadChildren: () => import('src/app/awards/awards.module').then((m) => m.AwardsModule),
        data: {
            roles: ['admin', 'partner', 'student'],
            title: 'Награди',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'calendar',
        loadChildren: () => import('src/app/calendar/calendar.module').then((m) => m.CalendarModule),
        data: {
            roles: ['admin', 'partner', 'teacher', 'student'],
            title: 'Календар',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'messages',
        loadChildren: () => import('src/app/messages/messages.module').then((m) => m.MessagesModule),
        data: {
            roles: ['admin', 'marketing', 'partner', 'teacher', 'student'],
            title: 'Съобщения',
        }
    },  {
        canActivate: [AuthGuard],
        path: 'careers',
        loadChildren: () => import('src/app/careers/careers.module').then(m => m.CareersModule),
        data: {
            roles: ['student'],
            title: null,
        }
    }, {
        canActivate: [AuthGuard],
        path: 'jobs',
        loadChildren: () => import('src/app/jobs/jobs.module').then((m) => m.JobsModule),
        data: {
            roles: ['admin', 'marketing', 'partner'],
            title: 'Обяви за работа',
        },
    }, {
        canActivate: [AuthGuard],
        path: 'payments',
        loadChildren: () => import('src/app/payments/payments.module').then((m) => m.PaymentsModule),
        data: {
            roles: ['admin', 'partner', 'student'],
            title: 'Плащания',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'groups',
        loadChildren: () => import('src/app/groups/groups.module').then((m) => m.GroupsModule),
        data: {
            roles: ['admin', 'partner', 'teacher', 'student'],
            title: null,
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'homework',
        loadChildren: () => import('src/app/homework/homework.module').then((m) => m.HomeworkModule),
        data: {
            roles: ['admin', 'partner', 'teacher', 'student'],
            title: 'Домашни',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'points',
        loadChildren: () => import('src/app/points/points.module').then((m) => m.PointsModule),
        data: {
            roles: ['admin', 'partner', 'teacher'],
            title: 'Точки',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'attendances',
        loadChildren: () => import('src/app/attendances/attendances.module').then((m) => m.AttendancesModule),
        data: {
            roles: ['admin', 'marketing'],
            title: 'Събития и записване',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'blog',
        loadChildren: () => import('src/app/blog/blog.module').then((m) => m.BlogModule),
        data: {
            roles: ['admin', 'marketing'],
            title: 'Блог',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'zoom',
        loadChildren: () => import('src/app/zoom/zoom.module').then((m) => m.ZoomModule),
        data: {
            roles: ['admin', 'partner'],
            title: 'Zoom',
        },
    },
    {
        path: 'certificates',
        loadChildren: () => import('src/app/certificates/certificates.module').then((m) => m.CertificatesModule),
        data: {
            title: 'Сертификати',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'exams',
        loadChildren: () => import('src/app/exams/exams.module').then((m) => m.ExamsModule),
        data: {
            roles: ['admin', 'partner', 'teacher', 'student'],
            title: null,
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'about',
        loadChildren: () => import('src/app/about/about.module').then((m) => m.AboutModule),
        data: {
            roles: ['admin', 'marketing', 'partner', 'student'],
            title: 'Инструкции',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'students',
        loadChildren: () => import('src/app/students/students.module').then((m) => m.StudentsModule),
        data: {
            roles: ['admin', 'partner', 'teacher'],
            title: 'Курсисти',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'teachers',
        loadChildren: () => import('src/app/teachers/teachers.module').then((m) => m.TeachersModule),
        data: {
            roles: ['admin', 'partner'],
            title: 'Преподаватели',
        },
    },
    {
        canActivate: [AuthGuard],
        path: 'partners',
        loadChildren: () => import('src/app/partners/partners.module').then((m) => m.PartnersModule),
        data: {
            roles: ['admin'],
            title: 'Партньори',
        },
    },
    {
        path: 'courses',
        loadChildren: () => import('src/app/courses/courses.module').then((m) => m.CoursesModule),
        data: {
            roles: ['admin', 'partner', 'teacher', 'student'],
            title: 'Курсове',
        }
    },
    {
        path: 'portal',
        loadChildren: () => import('src/app/portal/portal.module').then((m) => m.PortalModule),
        data: {
            title: 'Портал',
            page: 'portal',
            force_default_locale: true,
        }
    },
    {
        canActivate: [AuthGuard],
        path: 'polls',
        loadChildren: () => import('src/app/polls/polls.module').then((m) => m.PollsModule),
        data: {
            roles: ['admin', 'marketing', 'partner'],
            title: 'Анкети',
        }
    },
    {
        path: 'auth',
        loadChildren: () => import('src/app/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'errors',
        loadChildren: () => import('src/app/errors/errors.module').then((m) => m.ErrorsModule),
    },

    {
        path: '**',
        redirectTo: '/errors/404',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            anchorScrolling: 'enabled'
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
