import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/i18n.service';

@Pipe({
    name: 'dateLocale',
    // pure: true,
})
export class DateLocalePipe implements PipeTransform {
    constructor(
        private datePipe: DatePipe,
        private i18nService: I18nService,
    ) {
    }

    transform(
        value?: string|Date,
        format: string = 'short',
        timezone: any = undefined,
        locale?: string,
        invalidDateLabel: string = '',
        noWrap: boolean = true
    ): string | null {
        const isInvalid = value instanceof Date ? isNaN(value.getTime())
            : isNaN(new Date(value ?? '').getTime());

        if (isInvalid) {
            return invalidDateLabel;
        }

        locale = locale || this.i18nService.activeLocale();

        if (['bg', 'bg_BG'].includes(locale)) {
            format =
                  format === 'short' ? "dd.MM.yy 'г'., HH:mm 'ч'."
                : format === 'shortDate' ? "dd.MM.yy 'г'."
                : format === 'shortTime' ? "HH:mm 'ч'."
                : format;
        }

        const result: string = this.datePipe.transform(value, format, timezone, locale) || "";

        return !noWrap ? result : result?.split(' ')?.join('\u00A0') || "";
    }
}
