import { TestQuestionAnswer } from "./test-question-answer.model";

export type TestQuestionStatus = ('active'|'inactive');

export class TestQuestion {
    id?: number;
    test?: number;
    question?: string;
    status?: TestQuestionStatus;
    image?: string;
    answers?: TestQuestionAnswer[];

    static fromJson(fields: any): TestQuestion {
        let item = Object.assign(new this(), fields);

        item.answers = fields?.answers?.map((item: any) => TestQuestionAnswer.fromJson(item));
        return item;
    }
}
