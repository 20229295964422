<ng-container *ngIf="student?.id && user?.id">
    <div class="card" id="basicInfo">
        <div class="card-body">
            <div class="row">
                <div class="col-auto">
                    <div class="m-3">
                        <avatar [user]="student" size="big" class="d-none d-lg-inline-block"></avatar>
                        <avatar [user]="student" size="small" class="d-lg-none"></avatar>
                    </div>
                </div>
                <div class="col">
                    <span class="d-none d-md-block" *ngIf="user?.id !== student?.id && student?.status === 'active'">
                        <a routerLink="/messages" [queryParams]="{conversation: student?.id, tab: 'conversations'}" class="btn btn-primary btn-sm float-end">
                            <i class="fa-regular fa-envelope fa-fw"></i>
                            {{ 'Напиши съобщение' | transloco }}
                        </a>
                    </span>

                    <ng-container *ngIf="user && (user | roleAny: 'admin':'partner':'teacher') && (student?.franchise || student?.status)">
                        <h2 [class.mb-0]="user && (user | roleAny: 'admin') && student?.franchise">
                            <small class="badge rounded-pill text-bg-danger small me-1" *ngIf="student?.status === 'inactive'">{{ 'Неактивен' | transloco }}</small>
                            {{ student | userName }}
                        </h2>
                        <h3 *ngIf="user && (user | roleAny: 'admin') && student?.franchise">{{ 'Франчайз: \{{name}\}' | transloco:{name: student?.franchise?.name} }}</h3>
                    </ng-container>

                    <h2 *ngIf="!(user && (user | roleAny: 'admin':'partner':'teacher') && (student?.franchise || student?.status))">{{ student | userName }}</h2>

                    <div class="row">
                        <div class="col-12 col-md">
                            <dl>
                                <dt>E-mail</dt>
                                <dd>
                                    <i class="fa-solid fa-at fa-fw me-2"></i>
                                    <a *ngIf="student?.email?.length" [href]="'mailto:' + student?.email" target="_blank" (click)="$event.stopPropagation()">{{ student?.email ?? '-' }}</a>
                                    <ng-container *ngIf="!student?.email?.length">{{ 'Няма' | transloco }}</ng-container>
                                </dd>

                                <dt>{{ 'Телефон' | transloco }}</dt>
                                <dd>
                                    <i class="fa-solid fa-phone fa-fw me-2"></i>
                                    <a *ngIf="student?.phone?.length" [href]="'tel:' + student?.phone" target="_blank" (click)="$event.stopPropagation()">{{ student?.phone ?? '-' }}</a>
                                    <ng-container *ngIf="!student?.phone?.length">{{ 'Няма' | transloco }}</ng-container>
                                </dd>

                                <ng-container *ngIf="student?.info?.parent_name || student?.info?.parent_email || student?.info?.parent_phone">
                                    <dt class="mt-4">{{ 'Родител' | transloco }}</dt>
                                    <dd>
                                        <div class="mb-1">
                                            <i class="fa-solid fa-circle-info fa-fw me-2"></i>

                                            <ng-container class="fw-bold">{{ student?.info?.parent_name ?? '' }}</ng-container>
                                        </div>

                                        <div class="mb-1">
                                            <i class="fa-solid fa-at fa-fw me-2"></i>
                                            <a *ngIf="student?.info?.parent_email?.length" [href]="'mailto:' + student?.info?.parent_email" target="_blank" (click)="$event.stopPropagation()">{{ student?.info?.parent_email ?? '-' }}</a>
                                            <ng-container *ngIf="!student?.info?.parent_email?.length">{{ 'Няма' | transloco }}</ng-container>
                                        </div>

                                        <div class="mb-1">
                                            <i class="fa-solid fa-phone fa-fw me-2"></i>
                                            <a *ngIf="student?.info?.parent_phone?.length" [href]="'tel:' + student?.info?.parent_phone" target="_blank" (click)="$event.stopPropagation()">{{ student?.info?.parent_phone ?? '-' }}</a>
                                            <ng-container *ngIf="!student?.info?.parent_phone?.length">{{ 'Няма' | transloco }}</ng-container>
                                        </div>
                                    </dd>
                                </ng-container>
                            </dl>
                        </div>
                        <div class="col-12 col-md">
                            <dl>
                                <dt>{{ 'Дата на регистрация' | transloco }}</dt>
                                <dd>
                                    <i class="fa-regular fa-address-card fa-fw me-2"></i>
                                    <ng-container *ngIf="student?.created">{{ student?.created | dateLocale:"dd MMMM yyyy":null:'':('Не е посочено' | transloco ) }}</ng-container>
                                </dd>

                                <dt>{{ 'Град' | transloco }}</dt>
                                <dd>
                                    <i class="fa-solid fa-location-dot fa-fw me-2"></i>
                                    <span>{{ student?.info?.town?.name ?? ('Не е посочено' | transloco) }}</span>
                                </dd>

                                <dt>{{ 'Дата на раждане' | transloco }}</dt>
                                <dd>
                                    <i class="fa-solid fa-gift fa-fw me-2"></i>
                                    <ng-container *ngIf="student?.info?.birthday">{{ student?.info?.birthday | dateLocale:"dd MMMM yyyy":null:'':('Не е посочено' | transloco ) }}</ng-container>
                                    <ng-container *ngIf="!student?.info?.birthday">{{ 'Не е посочено' | transloco }}</ng-container>
                                </dd>


                            </dl>
                        </div>
                        <div class="col-12 col-md" *ngIf="
                            student?.info?.facebook?.length
                            || student?.info?.linkedin?.length
                            || student?.info?.tiktok?.length
                            || student?.info?.github?.length
                            || student?.info?.website?.length
                        ">
                            <dl>
                                <dt class="mb-2">{{ 'Социални мрежи' | transloco }}</dt>
                                <dd>
                                    <div class="mb-2" *ngIf="student?.info?.facebook?.length" title="Facebook">
                                        <i class="fa-brands fa-facebook fa-fw me-2"></i>
                                        <a [href]="student?.info?.facebook" target="_blank">{{ student?.info?.facebook }}</a>
                                    </div>

                                    <div class="mb-2" *ngIf="student?.info?.linkedin?.length" title="Linkedin">
                                        <i class="fa-brands fa-linkedin fa-fw me-2"></i>
                                        <a [href]="student?.info?.linkedin" target="_blank">{{ student?.info?.linkedin }}</a>
                                    </div>

                                    <div class="mb-2" *ngIf="student?.info?.tiktok?.length" title="TikTok">
                                        <i class="fa-brands fa-tiktok fa-fw me-2"></i>
                                        <a [href]="student?.info?.tiktok" target="_blank">{{ student?.info?.tiktok }}</a>
                                    </div>

                                    <div class="mb-2" *ngIf="student?.info?.github?.length" title="GitHub">
                                        <i class="fa-brands fa-github fa-fw me-2"></i>
                                        <a [href]="student?.info?.github" target="_blank">{{ student?.info?.github }}</a>
                                    </div>

                                    <div class="mb-2" *ngIf="student?.info?.website?.length" title="{{ 'Уеб Сайт (Портфолио, личен или др.)' | transloco }}">
                                        <i class="fa-solid fa-globe fa-fw me-2"></i>
                                        <a [href]="student?.info?.website" target="_blank">{{ student?.info?.website }}</a>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="student?.preferences?.length && user && ((user | roleAny:'admin':'partner') || user?.id === student?.id)">
                <div class="col-12">
                    <hr>
                </div>

                <div class="col-lg col-12">
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="col-lg-6 col-12">
                            <p class="mb-2 fw-bold">{{ 'LinkedIn предпочитания' | transloco }}</p>
                        </div>

                        <div class="col-lg-6 col-12">
                            <a *ngIf="student?.cv?.id" [href]="student?.cv?.url" target="_blank" class="btn btn-primary btn-sm float-end mb-lg-0 mb-3">
                                <i class="fa-regular fa-file-pdf fa-fw"></i>

                                <ng-container *ngIf="user?.id !== student?.id">
                                    {{ 'CV на курсист' | transloco }}
                                </ng-container>
                                <ng-container *ngIf="user?.id === student?.id">
                                    {{ 'CV' | transloco }}
                                </ng-container>
                            </a>
                        </div>
                    </div>

                    <div class="row preferences">
                        <div class="col-lg-6 col-12" *ngFor="let preferences of careerPreferences">
                            <p class="mb-lg-0 mb-2">
                                <i class="fas fa-check fa-fw me-2"></i> <span>{{ preferences | transloco }}</span><br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="mt-5"
        infinite-scroll
        [infiniteScrollDistance]="2"
        (scrolled)="onScrollPage($event)"
    >
        <tabs paramType="id" class="mt-5 compact" variant="orange" (tabChange)="onTabChange($event)">
            <tab *ngIf="user?.id !== student?.id" [label]="'Учебни групи' | transloco" id="groups">
                <div class="my-2" [class.mt-2]="tabs?.groups?.entity?.data?.length">
                    <div class="row mx-0 my-3 align-items-stretch" *ngIf="tabs?.groups?.entity?.data?.length">
                        <div class="col-lg-6 mb-2 mb-md-3" *ngFor="let group of tabs?.groups?.entity?.data; let index = index; trackBy: trackBy">
                            <card-group-small   [group]="group"
                                                [user]="user"
                                                [withEdit]="!!(user && (user | roleAny: 'partner':'admin'))"
                                                [withRemove]="false"
                            ></card-group-small>
                        </div>
                    </div>

                    <div *ngIf="!tabs?.groups?.entity?.data?.length" class="alert alert-warning">
                        {{ 'Няма налични учебни групи.' | transloco }}
                    </div>
                </div>
            </tab>

            <tab *ngIf="user?.id !== student?.id && user && (user | roleAny: 'admin':'partner')" [label]="'Плащания' | transloco" id="payments">
                <div class="my-2">

                    <div class="row mx-0 my-3 align-items-stretch" *ngIf="tabs?.payments?.entity?.data?.length">
                        <div class="col-lg-6 mb-2 mb-md-3" *ngFor="let payment of tabs?.payments?.entity?.data; let index = index; trackBy: trackBy">
                            <card-payment   class="d-block h-100"
                                            [payment]="payment"
                                            [withName]="false"
                                            (onClick)="onPayment($event, payment)"
                                            [displayPayText]="false"
                            ></card-payment>
                        </div>
                    </div>


                    <div *ngIf="!student?.payments?.length" class="alert alert-warning">
                        {{ 'Няма налични плащания.' | transloco }}
                    </div>
                </div>
            </tab>

            <tab *ngIf="user?.id !== student?.id" [label]="'Сертификати' | transloco" id="certificates">
                <div class="my-2">
                    <div class="certificate-list" *ngIf="tabs?.certificates?.entity?.data?.length">
                        <div *ngFor="let certificate of tabs?.certificates?.entity?.data">
                            <div class="col-lg-12">
                                <certificate [certificate]="certificate"
                                                [isLink]="true"
                                                [profileView]="true"
                                ></certificate>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!tabs?.certificates?.entity?.data?.length" class="alert alert-warning">
                        {{ 'Няма издадени сертификати.' | transloco }}
                    </div>
                </div>
            </tab>

            <tab *ngIf="user?.id !== student?.id" [label]="'Домашни' | transloco" id="homework">
                <div class="my-2">
                    <listing-table  *ngIf="tabs?.homework?.entity?.data?.length"
                                    [columns]="tabs?.homework?.columns ?? []"
                                    [rows]="tabs?.homework?.entity?.data ?? []"
                                    [total]="tabs?.homework?.entity?.meta?.total ?? 0"
                                    [perPage]="tabs?.homework?.entity?.data?.length ?? 0"
                                    [hideSearchBar]="true"
                                    [hideTotalBar]="true"
                    >
                        <ng-template itemTemplate="cell" let-item>
                            <div *ngIf="item.column.field === 'col_checked'">
                                <p [class.text-success]="item.row?.checked"
                                    [class.text-danger]="!item.row?.checked"
                                    class="text-nowrap mb-0"
                                >
                                    <i class="fa-solid fa-check d-inline px-1" *ngIf="item.row?.checked"></i>
                                    <i class="fa-solid fa-xmark d-inline px-1" *ngIf="!item.row?.checked"></i>
                                    {{ item.row?.checked ? ('Проверено' | transloco) : ('Непроверено' | transloco)}}
                                </p>
                            </div>
                            <div *ngIf="item.column.field === 'col_date'">
                                {{ item.row.date | dateLocale }}
                            </div>
                            <div *ngIf="item.column.field === 'preview'" class="text-center">
                                <a class="btn btn-primary btn-sm" [routerLink]="'/homework/groups/' + item.row.group?.id + '/show/' + item.row.id">
                                    {{ 'Преглед' | transloco }}
                                </a>
                            </div>

                            <div *ngIf="item.column.field === 'col_group'">
                                <a [routerLink]="'/groups/' + item.row.group?.id" *ngIf="item.row.group?.id">
                                    {{item.row.group?.title}}
                                </a>
                                <span *ngIf="!item.row.group?.id">-</span>
                            </div>
                        </ng-template>
                    </listing-table>

                    <div *ngIf="!tabs?.homework?.entity?.data?.length" class="alert alert-warning">
                        {{ 'Няма домашни, направени от този курсист.' | transloco }}
                    </div>
                </div>
            </tab>

            <tab *ngIf="user?.id !== student?.id" [label]="'Проекти' | transloco" id="projects">
                <div class="my-2">
                    <listing-table  *ngIf="tabs?.projects?.entity?.data?.length"
                                    [columns]="tabs?.projects?.columns ?? []"
                                    [rows]="tabs?.projects?.entity?.data ?? []"
                                    [total]="tabs?.projects?.entity?.meta?.total ?? 0"
                                    [perPage]="tabs?.projects?.entity?.data?.length ?? 0"
                                    [hideSearchBar]="true"
                                    [hideTotalBar]="true"
                    >
                        <ng-template itemTemplate="cell" let-item>
                            <div *ngIf="item.column.field === 'col_title'">
                                {{ item?.row?.project?.title }}
                            </div>

                            <div *ngIf="item.column.field === 'col_group'">
                                <a [routerLink]="'/groups/' + item.row.project?.group?.id" *ngIf="item.row.project?.group?.id">
                                    {{item.row.project?.group?.title}}
                                </a>
                                <span *ngIf="!item.row.project?.group?.id">-</span>
                            </div>

                            <div *ngIf="item.column.field === 'col_checked'">
                                <p [class.text-success]="item.row?.checked"
                                    [class.text-danger]="!item.row?.checked"
                                    class="text-nowrap mb-0"
                                >
                                    <i class="fa-solid fa-check d-inline px-1" *ngIf="item.row?.checked"></i>
                                    <i class="fa-solid fa-xmark d-inline px-1" *ngIf="!item.row?.checked"></i>
                                    {{ item.row?.checked ? ('Проверено' | transloco) : ('Непроверено' | transloco)}}
                                </p>
                            </div>
                            <div *ngIf="item.column.field === 'col_date'">
                                {{ item.row.date | dateLocale }}
                            </div>
                            <div *ngIf="item.column.field === 'preview'" class="text-center">
                                <a class="btn btn-primary btn-sm" [routerLink]="'/homework/project/' + item.row.project?.id + '/student/' + student?.id">
                                    {{ 'Преглед' | transloco }}
                                </a>
                            </div>
                        </ng-template>
                    </listing-table>

                    <div *ngIf="!tabs?.projects?.entity?.data?.length" class="alert alert-warning">
                        {{ 'Няма проекти, изпратени от този курсист.' | transloco }}
                    </div>
                </div>
            </tab>

            <tab [label]="'Тестове' | transloco" id="exams">
                <div class="my-2">
                    <listing-table  *ngIf="tabs?.exams?.entity?.data?.length"
                                    [columns]="tabs?.exams?.columns ?? []"
                                    [rows]="tabs?.exams?.entity?.data ?? []"
                                    [total]="tabs?.exams?.entity?.meta?.total ?? 0"
                                    [perPage]="tabs?.exams?.entity?.data?.length ?? 0"
                                    [hideSearchBar]="true"
                                    [hideTotalBar]="true"
                    >
                        <ng-template itemTemplate="cell" let-item>
                            <div *ngIf="item.column.field === 'col_start'">
                                {{ item.row.created_at | dateLocale:'dd MMM YY  hh:mm' }}
                            </div>
                            <div *ngIf="item.column.field === 'col_end'">
                                {{ item.row.finished_at | dateLocale:'dd MMM YY hh:mm' }}
                            </div>
                            <div *ngIf="item.column.field === 'col_group'">
                                <a [routerLink]="'/groups/' + item.row.group?.id" *ngIf="item.row.group?.id">
                                    {{item.row.group?.title}}
                                </a>
                                <span *ngIf="!item.row.group">-</span>
                            </div>
                            <div *ngIf="item.column.field === 'col_time'">
                                {{ '\{{minutes}\} мин.' | transloco:{minutes: item.row.elapsed ?? 0} }}
                            </div>
                            <div *ngIf="item.column.field === 'col_result'">
                                {{(item.row.result ?? 0) + ' / ' + (item.row.test.max ? item.row.test.max : 100) }}
                            </div>
                            <div *ngIf="item.column.field === 'col_type'">
                                {{item.row.test?.type ? item?.row?.test?.type?.description : '-'}}
                            </div>
                            <div *ngIf="item.column.field === 'col_exam'">
                                {{item.row.test?.type?.title == 'final' ? item.row.exam : '-'}}
                            </div>
                            <div *ngIf="item.column.field === 'col_show'" class="text-center">
                                <a  class="btn btn-primary btn-sm"
                                    [routerLink]="user && (user | roleAny:'student')
                                        ? ['/groups', item.row.group?.id ?? 0, 'modules', item.row.test?.module ?? 0, 'exam']
                                        : ['/groups', item.row.group?.id ?? 0, 'modules', item.row.test?.module ?? 0]
                                    "
                                    [queryParams]="user && (user | roleAny:'student')
                                        ? {}
                                        : { tab: 'exam', result: item.row.id }
                                    "
                                >
                                    {{ 'Преглед' | transloco }}
                                </a>
                            </div>
                        </ng-template>
                    </listing-table>

                    <div *ngIf="!tabs?.exams?.entity?.data?.length" class="alert alert-warning">
                        {{ 'Няма направени тестове.' | transloco }}
                    </div>
                </div>
            </tab>

            <tab [label]="'Точки' | transloco" id="score">
                <div class="my-2">
                    <listing-table  *ngIf="tabs?.score?.entity?.data?.length"
                                    [columns]="tabs?.score?.columns ?? []"
                                    [rows]="tabs?.score?.entity?.data ?? []"
                                    [total]="tabs?.score?.entity?.meta?.total ?? 0"
                                    [perPage]="tabs?.score?.entity?.data?.length ?? 0"
                                    [hideSearchBar]="true"
                                    [hideTotalBar]="true"
                    ></listing-table>

                    <div *ngIf="!tabs?.score?.entity?.data?.length" class="alert alert-warning">
                        {{ 'Няма информация за точки.' | transloco }}
                    </div>
                </div>
            </tab>

            <tab *ngIf="user?.id !== student?.id && student?.kid && user && (user | roleAny: 'admin':'partner')" [label]="'Награди' | transloco" id="awards">
                <div class="my-2">
                    <listing-table  *ngIf="tabs?.awards?.entity?.data?.length"
                                    [columns]="tabs?.awards?.columns ?? []"
                                    [rows]="tabs?.awards?.entity?.data ?? []"
                                    [total]="tabs?.awards?.entity?.meta?.total ?? 0"
                                    [perPage]="tabs?.awards?.entity?.data?.length ?? 0"
                                    [hideSearchBar]="true"
                                    [hideTotalBar]="true"
                    >
                        <ng-template itemTemplate="cell" let-item>
                            <div *ngIf="item.column.field === 'col_title'">
                                {{ item.row.award?.name }}
                            </div>
                            <div *ngIf="item.column.field === 'col_award'">
                                <img class="award-image" [src]="item.row.award?.image" />
                            </div>
                            <div *ngIf="item.column.field === 'col_status'">
                                <p
                                    [class.text-success]="item.row?.status === 'given'"
                                    [class.text-danger]="item.row?.status === 'claimed'"
                                    class="text-nowrap mb-0"
                                >
                                    <i class="fa-solid fa-check d-inline px-1" *ngIf="item.row?.status === 'given'"></i>
                                    <i class="fa-solid fa-xmark d-inline px-1" *ngIf="item.row?.status === 'claimed'"></i>

                                    {{ item.row?.status === 'given' ? ('Получена' | transloco) : ('Заявена' | transloco)}}
                                </p>
                            </div>
                            <div *ngIf="item.column.field === 'col_date'">
                                {{ item.row.date | dateLocale }}
                            </div>
                        </ng-template>
                    </listing-table>

                    <div *ngIf="!tabs?.awards?.entity?.data?.length" class="alert alert-warning">
                        {{ 'Няма информация за заявени награди.' | transloco }}
                    </div>
                </div>
            </tab>
        </tabs>
    </div>
</ng-container>

<modal-payments-edit *ngIf="showStudentPaymentDialog && paymentItem"
                        [payment]="paymentItem"
                        (onAction)="onClosePaymentAction($event)"
                        (onResult)="onPaymentUpdated($event)"
></modal-payments-edit>
