export type CoursePreviewStatus = ('info'|'price'|'about'|'methodology'|'video'|'marketing'|'finance');

export class CoursePreview {
    id?: number;
    title?: string;
    type?: CoursePreviewStatus;
    description?: string;

    static fromJson(fields: any): CoursePreview {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
