import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationExtras, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { filter, first, map, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    protected readonly ROUTE_GUEST: string = environment.routes.guest;
    protected readonly ROUTE_NON_GUEST: string = environment.routes.home;

    protected isAuthReady: boolean = false;

    constructor(
        @Optional() @Inject(PLATFORM_ID) private platform: Object,
        private router: Router,
        private authService: AuthService,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        if (isPlatformServer(this.platform)) {
            return new Promise((resolve, reject) => {
                let timeout: any = null;
                const authReadySubscribe: Subscription = this.authService.getAuthReady()
                    .subscribe({
                        next: async status => {
                            timeout && clearTimeout(timeout);

                            const authReady = String(status).toLowerCase() === 'true';
                            if (this.isAuthReady === authReady && authReady ) {
                                timeout = setTimeout(() => authReadySubscribe?.unsubscribe(), 10);
                                resolve(this.checkPermissions(route, state));
                                return;
                            }
                            this.isAuthReady = authReady;

                            if (this.isAuthReady) {
                                timeout = setTimeout(() => authReadySubscribe?.unsubscribe(), 10);
                                resolve(this.checkPermissions(route, state));
                            } else {
                                timeout = setTimeout(() => {
                                    setTimeout(() => authReadySubscribe?.unsubscribe(), 10);
                                    resolve(this.checkPermissions(route, state));
                                }, 1000);
                            }
                        },
                        error: error => {
                            reject(error);
                        }
                    });
            });
        } else if (this.isAuthReady) {
            // we have everything we need to make checkings
            return this.checkPermissions(route, state);
        } else {
            return new Promise((resolve, reject) => {
                this.authService.getAuthReady().pipe(
                    filter(status => status),  // don't care about "false" statuses
                    map(async status => {
                        this.isAuthReady = true
                        resolve(this.checkPermissions(route, state));
                    }),
                    first()
                ).subscribe();
            });
        }
    }

    checkPermissions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isGuest: boolean = !this.authService.user.isValid();
        const url = (state.url.split('/') ?? [])?.filter(item => item.length);
        const guestParams: NavigationExtras = {queryParams: {
            ...(url[0] !== 'auth'
                ? {redirect: '/' + url.join('/') }
                : null
            )
        }};

        let current = route.root;
        while (current.children[0] !== undefined) {
            current = current.children[0];
        }
        if ('data' in current
            && 'roles' in current?.data
            && current.data['roles']
            && !this.authService.user.can(current.data['roles'])
        ) {
            if (isGuest) {
                this.router.navigate([this.ROUTE_GUEST], guestParams);
                return false;
            } else if (!isGuest && !this.authService.user?.user?.role?.id) {
                this.authService.logout(false).subscribe(data => {
                    if (typeof localStorage !== 'undefined') {
                        localStorage?.clear();
                    }
                    this.router.navigate([this.ROUTE_GUEST], guestParams);
                });
                return false;
            } else if (!isGuest) {
                this.router.navigateByUrl(this.ROUTE_NON_GUEST);
                return false;
            }
        } else if ('data' in current && 'guest' in current?.data) {
            if (isGuest && !current.data['guest']) {
                this.router.navigate([this.ROUTE_GUEST], guestParams);
            } else if (!isGuest && current.data['guest']) {
                this.router.navigate([this.ROUTE_NON_GUEST]);
            }
        }

        return true;
    }

}
