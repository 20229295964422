import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { environment } from 'src/environments/environment';

export interface PaginationComponentTitles {
    first: string;
    last: string;
    previous: string;
    next: string;
};

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnDestroy {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;

    @Output('page') page: EventEmitter<number> = new EventEmitter();

    protected innerCurrent: number = 1;
    protected innerTotal: number = 0;
    protected innerPerPage: number = this.PAGINATION_LIMIT || 10;
    protected innerGrouping: number = 7;

    @Input() showNext: boolean = true;
    @Input() showPrevious: boolean = true;
    @Input() showFirst: boolean = true;
    @Input() showLast: boolean = true;
    @Input() titles: PaginationComponentTitles = {
        first: translate('Първа страница'),
        last: translate('Последна страница'),
        previous: translate('Предишна страница'),
        next: translate('Следваща страница'),
    };

    @Input('current')
    set current(value: number) {
        if (this.innerCurrent !== value) {
            this.innerCurrent = ~~value;
            this.selected = this.innerCurrent;
            this.calculatePages();
        }
    }
    get current(): number {
        return this.innerCurrent;
    }

    @Input('total')
    set total(value: number) {
        if (this.innerTotal !== value) {
            this.innerTotal = ~~value;
            this.calculatePages();
        }
    }
    get total(): number {
        return this.innerTotal;
    }

    @Input('perPage')
    set perPage(value: number) {
        if (this.innerPerPage !== value) {
            this.innerPerPage = ~~value;
            this.calculatePages();
        }
    }
    get perPage(): number {
        return this.innerPerPage;
    }

    @Input('grouping')
    set grouping(value: number) {
        if (this.innerGrouping !== value) {
            this.innerGrouping = ~~value;
            this.calculatePages();
        }
    }
    get grouping(): number {
        return this.innerGrouping;
    }

    isVisible: boolean = false;
    pages: number = 0;
    pagesList: Array<any> = [];
    selected: number = this.current || 0;

    constructor() { }

    ngOnInit(): void {
        this.calculatePages();


    }

    ngOnDestroy(): void {
    }


    onEmptySelect(event: Event): boolean {
        event.preventDefault();
        return false;
    }

    onSelect(event: Event, page: number): boolean {
        event.preventDefault();

        if (page === this.selected || page <= 0 || page > this.pages) {
            return false;
        }
        this.selected = page;
        this.page.emit(page);
        return false;
    }

    protected calculatePages(): void {
        this.pages = Math.ceil(this.total / this.perPage);
        let begin = 1;
        let end = this.pages + 1;
        if (this.grouping > 2 && this.grouping < this.pages) {
            begin = (this.current - 1) * this.perPage;
            let diff = Math.floor(this.grouping / 2);
            diff = this.current == 1 ? 0
                : this.current == this.pages ? this.grouping - 1
                : diff;
            begin = this.current - diff;
            begin = begin < 2 ? 1 : begin;
            end = begin + this.grouping;
            if (end > this.pages + 1) {
                end = this.pages + 1;
                begin = this.pages - this.grouping + 1;
            }
        }

        this.pagesList = Array.from(Array(end - begin), (_, i) => i + begin);
        this.isVisible = this.pagesList && this.pagesList.length > 1;
    }

}
