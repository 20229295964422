import { ModuleTestResult } from "./module-test-result.model";
import { Test } from "./test.model";
import { User } from "./user/user.model";

export class TestResult {
    user?: User;
    result?: ModuleTestResult;
    test?: Test;
    tests?: Test[];

    static fromJson(fields: any): TestResult {
        let item = Object.assign(new this(), fields);

        item.user = User.fromJson(fields?.user);
        item.result = ModuleTestResult.fromJson(fields?.result);
        item.test = Test.fromJson(fields?.test);
        item.tests = fields?.tests?.map((item: any) => Test.fromJson(item));

        return item;
    }
}
