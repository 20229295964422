import { StudentGroupVerificaton } from "../student-group-verification";
import { UserInfo } from "./user-info.model";
import { UserRole, UserRoleName } from "./user-role.model";
import { GroupCourse } from "./user-group-course.model";
import { Payment } from "../payment.model";
import { Certificate } from "../certificate/certificate.model";
import { FranchiseShort } from "../franchise-short.model";
import { ModuleTestResult } from "../module-test-result.model";
import { PointOther } from "../scoreboard/point-other.model";
import { HomeworkFile } from "../homework-file.model";
import { UserPreference } from "./user-preference.model";
import { TeacherInfo } from "./teacher-info.model";
import { ProjectFile } from "../project-file.model";
import { Present } from "../scoreboard/present.model";

export type UserStatus = ('active'|'inactive');
export type UserApproval = ('pending'|'approved'|'rejected');

export class User {
    static readonly REGEX_LINKEDIN = /(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/i;

    id?: number;
    name?: string;
    surname?: string;
    lastname?: string;
    cv?: {
        id: number;
        url: string;
    };
    email?: string;
    phone?: string;
    image?: string;
    status?: UserStatus;
    kid?: boolean;
    role?: UserRole;
    info?: UserInfo;
    teacher_info?: TeacherInfo
    inactive_modules?: number[];
    preferences?: UserPreference[];
    verification: StudentGroupVerificaton = new StudentGroupVerificaton;
    franchise?: FranchiseShort;
    certificates?: Certificate[];
    groups?: GroupCourse[];
    homeworks?: HomeworkFile[];
    projects?: ProjectFile[];
    payments?: Payment[];
    points?: PointOther[];
    tests?: ModuleTestResult[];
    awards?: Present[];

    created?: string;

    static fromJson(fields: any): User {
        let item = Object.assign(new this(), fields);
        item.role = UserRole.fromJson(fields?.role);
        item.info = UserInfo.fromJson(fields?.info);
        item.verification = StudentGroupVerificaton.fromJson(fields?.verification);
        item.franchise = FranchiseShort.fromJson(fields?.franchise);
        item.certificates = fields?.certificates?.map((item: any) => Certificate.fromJson(item));
        item.preferences = fields?.preferences?.map((item: any) => UserPreference.fromJson(item));
        item.groups = fields?.groups?.map((item: any) => GroupCourse.fromJson(item));
        item.homeworks = fields?.homeworks?.map((item: any) => HomeworkFile.fromJson(item));
        item.projects = fields?.projects?.map((item: any) => ProjectFile.fromJson(item));
        item.payments = fields?.payments?.map((item: any) => Payment.fromJson(item));
        item.tests = fields?.tests?.map((item: any) => ModuleTestResult.fromJson(item));
        item.points = fields?.points?.map((pointItem: any) => PointOther.fromJson(pointItem));
        item.awards = fields?.awards?.map((pointItem: any) => Present.fromJson(pointItem));

        return item;
    }

    /**
     * Determine if user is one of the roles in the listing
     * @param roles
     * @returns
     */
    can(roles: UserRoleName[]): boolean {
        return (roles || []).indexOf(this.role?.name as UserRoleName) !== -1;
    }

    /**
     * Check if user is guest
     */
    isGuest(): boolean {
        return !(this.name || '').length
            || !(this.id || 0)
            || !this.role?.id;
    }

    getFullName(): string {
        return [
            this.name || '',
            this.lastname || ''
        ].join(' ')
        ?.trim() ?? '';
    }

    getCareerPreferences() {
        const map: { [key: string]: string } = {
            'background_image': 'Background изображение',
            'profile_image': 'Профилна снимка',
            'headline': 'Headline',
            'about': 'Секция About',
            'education': 'Образование',
            'experience': 'Професионален опит',
            'tech_stack': 'Умения - технологии (езици за програмиране, frameworks и software), меки умения, чужди езици',
            'project': 'Проекти и допълнителни секции',
        };

        return this.preferences
            ?.filter(item => item?.name?.startsWith('linkedin.') && item.type === 'boolean' && item.value === true)
            ?.map(item => map[item?.name?.substring('linkedin.'.length) as string] ?? null)
            ?.filter(item => item);
    }
}
