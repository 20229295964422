<a [routerLink]="'/students/' + user?.id">{{ user | userName }}</a>

<div class="mt-1"  *ngIf="user?.phone?.length || user?.email?.length">
    <a class="small" *ngIf="user?.phone?.length" [href]="'tel:' + user?.phone" target="_blank" (click)="$event.stopPropagation()">
        <i class="fa-solid fa-phone fa-fw me-2" [title]="user?.phone ?? '-'"></i>
    </a>

    <a class="small" *ngIf="user?.email?.length" [href]="'mailto:' + user?.email" target="_blank" (click)="$event.stopPropagation()">
        <i class="fa-solid fa-at fa-fw me-2" [title]="user?.email ?? '-'"></i>
    </a>
</div>
