import { TestQuestion } from "./test-question.model";
import { TestType } from "./test-type.model";

export type TestStatus = ('active'|'inactive');

export class Test {
    id?: number;
    type?: TestType;
    module?: number;
    status?: TestStatus;
    name?: string;
    profession?: string;
    specialty?: string;
    course?: string;
    pass_percentage?: number;
    max_points?: number;
    duration?: number;
    questions?: TestQuestion[];
    is_final?: boolean;
    is_midterm?: boolean;

    static fromJson(fields: any): Test {
        let item = Object.assign(new this(), fields);

        item.type = TestType.fromJson(fields?.type);
        item.is_final = item?.time?.title === 'final';
        item.is_midterm = item?.time?.title === 'midterm';
        item.questions = fields?.questions?.map((event: TestQuestion) => TestQuestion.fromJson(event));
        return item;
    }
}
