import { Pipe, PipeTransform } from '@angular/core';
import { UserRoleName } from 'src/app/shared/models/user/user-role.model';
import { User } from 'src/app/shared/models/user/user.model';

@Pipe({
    name: 'roleAny'
})
export class RoleAnyPipe implements PipeTransform {
    transform(user: User, role: (UserRoleName|UserRoleName[]), ...roles: UserRoleName[]): boolean {
        const testUser: User = user instanceof User ? user : User.fromJson(user);

        if (Array.isArray(role)) {
            roles = [...roles, ...role];
        } else {
            roles.push(role);
        }

        return testUser.can(roles);
    }
}
