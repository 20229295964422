import { Component, OnDestroy, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Franchise } from '../../models/franchise.model';
import { FranchiseCourse } from '../../models/franchise-course.model';
import { Paginate } from '../../models/paginate.model';
import { environment } from 'src/environments/environment';
import { FranchiseService, FranchiseServiceCourseIndex } from '../../services/franchise.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { translate } from '@ngneat/transloco';
import { IInfiniteScrollEvent } from 'ngx-infinite-scroll';
import { User } from '../../models/user/user.model';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'overview-partner',
  templateUrl: './overview-partner.component.html',
  styleUrls: ['./overview-partner.component.scss']
})
export class OverviewPartnerComponent implements OnInit, OnDestroy {

    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;

    protected innerId: number|null = 0;
    protected subscriptions: Subscription[] = [];
    protected innerPartner?: Franchise;
    protected innerLoadingQueue: number = 0;
    protected innerReloadCourses: boolean = false;
    protected filter: FranchiseServiceCourseIndex = {
        limit: this.PAGINATION_LIMIT,
        page: 1,
    };

    @Input()
    set id(val: number|null) {
        if (this.innerId !== val) {
            this.innerId = val;
            this.getFranchise();
        }
    }
    get id(): number|null {
        return this.innerId;
    }

    @Input()
    set partner(val: Franchise) {
        if (this.innerPartner !== val) {
            this.innerPartner = val ?? undefined;

            val && this.getFranchiseCourses();

            this.computePortalPage();
        }
    }
    get partner(): Franchise {
        return this.innerPartner;
    }

    @Output() reloadCoursesChange: EventEmitter<boolean> = new EventEmitter();
    @Input()
    set reloadCourses(val: boolean) {
        if (this.innerReloadCourses !== val) {
            this.innerReloadCourses = false;

            this.partner?.id && this.getFranchiseCourses();

            setTimeout(() => {
                this.reloadCoursesChange.emit(this.innerReloadCourses);
            })
        }
    }
    get reloadCourses(): boolean {
        return this.innerReloadCourses;
    }

    @Input() loading: boolean = false;
    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();


    courses?: Paginate<FranchiseCourse>;
    portalPageUrl: string = '';
    user: User = new User;
    courseId: number|null = null;
    search: string|null = null;
    searchDebounce: any = null;
    searchDebounceTimeout: number = environment.debounce.default;
    showRemoveCourseConfirm: boolean = false;

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }

            this.loadingChange.emit(this.loading);
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        private franchiseService: FranchiseService,
        private messageService: MessageService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private locationService: Location,
    ) { }

    ngOnInit() {
        this.user = this.authService?.getUser() ?? this.user;

        this.route.queryParams.subscribe((data: any) => {
            this.search = data['q'] || '';
            this.filter.status = ['active', 'inactive', null].indexOf(data['status'] ?? '') >= 0 ? data['status'] : null;
            this.filter.page = 1;
            this.getFranchiseCourses();
        });
    }

    ngOnDestroy() {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

    onScrollDown(ev: IInfiniteScrollEvent): void {
        this.getFranchiseCourses(true);
    }


    onSearchEnter(event?: MouseEvent|Event): void {
        this.searchDebounce && clearTimeout(this.searchDebounce);
        this.onUpdateRoute();
    }

    onSearchDebounce(event?: MouseEvent|Event): void {
        this.searchDebounce && clearTimeout(this.searchDebounce);

        this.searchDebounce = setTimeout(() => {
            this.onUpdateRoute();
        }, this.searchDebounceTimeout || 500);
    }

    onClearSearch(event?: MouseEvent|Event): void {
        this.search = '';
        this.onUpdateRoute();
    }


    onRemove(event: {course: number, showRemoveCourse: boolean}): void {
        this.showRemoveCourseConfirm = event?.showRemoveCourse;
        this.courseId = event?.course ?? 0;
    }

    protected getFranchiseCourses(appendData: boolean = false): void {
        if (this.loading) {
            return;
        }

        if (appendData && this.courses?.data?.length) {
            if (this.courses?.reachEnd()) {
                return;
            }
            this.filter.page = this.courses?.nextPage();
        } else if (!this.courses?.data?.length) {
            this.filter.page = 1;
        }

        this.loadingQueue++;
        this.filter.q = this.search ?? '';

        const subscription = this.franchiseService.getCoursesList(this.partner?.id ?? 0,this.filter).subscribe({
            next: data => {
                this.loadingQueue--;

                if (!data.data) {
                    return;
                }

                if (appendData && this.courses?.data?.length) {
                    data.data = [
                        ...this.courses?.data ?? [],
                        ...data.data,
                    ];
                } else {
                    this.courses?.reset();
                }

                this.courses = data;
            },
            error: error => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'error',
                    detail: translate('Грешка при извличането на курсове!'),
                });
            }
        });
        this.subscriptions.push(subscription);
    }

    protected computePortalPage() {
        this.portalPageUrl = this.partner?.url?.length
            ? this.franchiseService.getLandingPageFullUrl(this.partner, '/portal/', this.partner?.homepage?.length ? 'contacts' : 'home')
            : '';
    }

    onRemoveCourseConfirmed(status: string) {
        this.showRemoveCourseConfirm = false;

        if(status === 'yes') {
            this.loadingQueue++;
            const subscription = this.franchiseService.deleteCourse(this.partner?.id ?? 0, this.courseId ?? 0).subscribe({
                next: data => {
                    this.loadingQueue--;
                    this.messageService.add({
                        severity: 'success',
                        detail: translate('Успешно премахнат на курс!'),
                    });

                    this.filter.page = 1;
                    this.getFranchiseCourses();
                },
                error: error => {
                    this.loadingQueue--;

                    this.messageService.add({
                        severity: 'error',
                        detail: translate('Грешка при премахване на курс!'),
                    });
                }
            });
            this.subscriptions.push(subscription);
        }
    }


    protected onUpdateRoute(): void {
        this.router.navigate([], {queryParams: {
            ...this.route?.snapshot?.queryParams,
            q: this.search?.trim() || null,
        }});
    }

    protected getFranchise() {
        this.loadingQueue++;

        const subscription = this.franchiseService.getFranchise(this.innerId ?? 0).subscribe({
            next: data => {
                this.loadingQueue--;

                this.partner = data?.data;
            },
            error: error => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'error',
                    detail: translate('Партньорът не съществува!'),
                });

                this.locationService.back();
            }
        });
        this.subscriptions.push(subscription);
    }
}
