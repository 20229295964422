<page-wrap  [headline]="course?.course?.title ?? 'Промяна на страница'"
            [navigation]="[{
                title: (user | roleAny:'admin') ? ('Партньори' | transloco) : ('Закупени курсове' | transloco),
                link: (user | roleAny:'admin') ? '/partners' : '/courses'
            },{
                title: (user | roleAny:'admin') ? (franchise?.name ?? '') : (course?.course?.title ?? ''),
                link: (user | roleAny:'admin') ? ('/partners/' + franchiseId) : ('/courses/' + courseId)
            },{
                title: ('Редакция на страница на курс' | transloco),
            },]"
>

    <ng-template section="sidebar"></ng-template>


        <form class="standard-form d-flex flex-column" [formGroup]="form" (submit)="onSubmit($event)" *ngIf="form">

            <div class="card mb-3 mb-lg-5">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h3 class="my-0 fw-bold">{{'Курс' | transloco}}</h3>
                    <div class="form-check form-switch d-inline-block">
                        <input class="form-check-input" type="checkbox" id="statusToggle" formControlName="status">
                        <label class="form-check-label" for="statusToggle">{{ this.form?.get('status')?.value ? ('Активен'|transloco) : ('Неактивен'|transloco) }}</label>
                    </div>
                </div>
                <div class="card-body">


                    <div class="row">
                        <div class="col-md-7">
                            <div class="row mb-4">
                                <label for="url" class="col-12">{{ 'Приятелски URL:' | transloco }}</label>
                                <div class="col-12">
                                    <input  requiredField requiredFieldAttribute="url"
                                            type="text"
                                            id="url"
                                            class="form-control"
                                            name="url"
                                            placeholder="programming-course-java-basics"
                                            formControlName="url"
                                            [attr.disabled]="loading ? true : null"
                                            [class.is-invalid]="errors?.url?.status === 'INVALID'"
                                    />
                                    <div class="invalid-feedback" *ngIf="errors?.url?.errors?.pattern">{{ 'Невалидни символи' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.url?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.url?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.url?.errors?.notunique">{{ 'Полето не съдържа уникален адрес' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.url?.errors?.any">{{ errors?.url?.errors?.any }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-5">
                            <div class="row mb-4" *ngIf="user && (user | roleAny:'admin')">
                                <label for="design" class="col-12">{{ 'Дизайн:' | transloco }}</label>
                                <div class="col-12">
                                    <p-dropdown requiredField requiredFieldAttribute="design"
                                                inputId="design"
                                                [class.is-invalid]="errors?.design?.status === 'INVALID'"
                                                [filter]="false"
                                                [options]="designOptions"
                                                formControlName="design"
                                                optionValue="id"
                                                optionLabel="title"
                                                styleClass="w-100"
                                    ></p-dropdown>
                                    <div class="invalid-feedback" *ngIf="errors?.design?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.design?.errors?.any">{{ errors?.design?.errors?.any }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4">
                        <label for="meta_title" class="col-12">{{ 'Заглавие на страницата и Meta заглавие:' | transloco }}</label>
                        <div class="col-12">
                            <input  requiredField requiredFieldAttribute="meta_title"
                                    type="text"
                                    id="meta_title"
                                    class="form-control"
                                    [placeholder]="'Мета заглавие' | transloco "
                                    formControlName="title"
                                    [attr.disabled]="loading ? true : null"
                                    [class.is-invalid]="errors?.title?.status === 'INVALID'"
                            />
                            <div class="invalid-feedback" *ngIf="errors?.title?.errors?.pattern">{{ 'Невалидни символи' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.title?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.title?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.title?.errors?.notunique">{{ 'Полето не съдържа уникален адрес' | transloco }}</div>
                            <div class="invalid-feedback" *ngIf="errors?.title?.errors?.any">{{ errors?.title?.errors?.any }}</div>
                        </div>
                    </div>

                    <div class="col-12 mb-4">
                        <div class="row">
                            <label for="meta_description" class="col-12">{{ 'Meta описание' | transloco }}<small *ngIf="course?.description?.length">
                                ({{ course?.description?.length }})
                            </small>:</label>
                            <div class="col-12">
                                <textarea   requiredField requiredFieldAttribute="meta_description"
                                            type="text"
                                            id="meta_description"
                                            class="form-control"
                                            rows="4"
                                            [placeholder]="'Meta описание' | transloco "
                                            formControlName="description"
                                            [attr.disabled]="loading ? true : null"
                                            [class.is-invalid]="errors?.description?.status === 'INVALID'"
                                ></textarea>
                                <div class="invalid-feedback" *ngIf="errors?.description?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.description?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                <div class="invalid-feedback" *ngIf="errors?.description?.errors?.any">{{ errors?.meta_description?.errors?.any }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mb-3" id="image_share">
                        <div class="row"><label class="col-12 mb-2">{{ 'Снимка за споделяне:' | transloco }}</label></div>
                        <upload-image (onFinished)="onImageShareFinished($event)"
                                        (onRemove)="imageShare = {};"
                                        [withRemove]="true"
                                        [labelDefault]="'Снимка, 1200x630px' | transloco"
                                        [loading]="loading"
                                        [class.is-invalid]="errors?.image_share?.status === 'INVALID'"
                                        [preview]="imageShare?.url ?? ''"
                        ></upload-image>

                        <div class="invalid-feedback" *ngIf="errors?.image_share?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.image_share?.errors?.maxlength">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                        <div class="invalid-feedback" *ngIf="errors?.image_share?.errors?.any">{{ errors?.meta_image_share?.errors?.any }}</div>
                    </div>
                </div>
            </div>

            <div class="card d-flex mb-5" >
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h3 class="my-0 fw-bold">{{'Оформление на сертификати' | transloco}}</h3>
                    <button type="button" class="btn btn-primary btn-sm" (click)="addCertificateStyle()">
                        <i class="fa-solid fa-fw" [class.fa-plus]="!showCertificate" [class.fa-minus]="showCertificate"></i> {{'Стилизация' | transloco}}
                    </button>
                </div>
                <div class="card-body" *ngIf="showCertificate" formGroupName="preferences">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="row">
                                        <div class="col-12 d-flex align-items-center my-2">
                                            <div class="form-check form-switch d-flex flex-column justify-content-center">
                                                <input class="form-check-input"
                                                        type="checkbox"
                                                        id="multiplePage"
                                                        formControlName="multiplePage" />
                                                <label class="form-check-label" for="multiplePage"></label>
                                            </div>
                                            <label for="multiplePage" class="me-auto me-md-2 text-md-end">{{ 'Множество страници' | transloco }}</label>
                                        </div>
                                        <div class="col-12 d-flex align-items-center my-2">
                                            <div class="form-check form-switch d-flex flex-column justify-content-center">
                                                <input class="form-check-input"
                                                        type="checkbox"
                                                        id="courseTitle"
                                                        formControlName="courseTitle" />
                                                <label class="form-check-label" for="type"></label>
                                            </div>
                                            <label for="courseTitle" class="me-auto me-md-2 text-md-end">{{ 'Заглавие на курс' | transloco }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <tabs class="compact" paramType="label" [fallbackToAccordion]="true">
                                    <tab [label]="'Първа страница' | transloco" id="tab_firstpage">
                                        <div class="mx-3">
                                            <div class="row">
                                                <div class="col-12 md-4 md-lg-0">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="my-3 ng-star-inserted" >
                                                                <div class="mb-2">
                                                                    <label>{{'Темплейт (pdf формат):' | transloco}}</label>
                                                                </div>
                                                                <upload-file (onFinished)="onTemplateFinished($event, 1)"
                                                                                *ngIf="!firstPage"
                                                                                [files]="1"
                                                                                [withRemove]="false"
                                                                                variant="inline"
                                                                                type="template"
                                                                ></upload-file>
                                                                <alert [type]="messageFilesType" [message]="messageFiles" (remove)="messageFiles = ''"></alert>
                                                                <div *ngIf="firstPage" class="d-md-flex d-flex-column w-100">
                                                                    <h3 class ="file-name d-flex flex-column flex-sm-row m-md-0 my-3">
                                                                        <a [href]="firstPage?.url" target="_blank">{{firstPage.title ?? ''}}</a>
                                                                    </h3>
                                                                    <button class="btn btn-outline-primary btn-sm mx-2" type="button" (click)="onTemplateRemove(firstPage, 1)">
                                                                        {{ 'Премахни' | transloco }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <!-- student layout -->
                                                        <div class="col-12 mb-4">
                                                            <div class="d-flex">
                                                                <h3 class="me-auto">{{'Име на курсист:' | transloco}}</h3>
                                                                <div class="d-flex flex-column">
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">X</span>
                                                                            <input  requiredField requiredFieldAttribute="studentXCoord"
                                                                                    type="text"
                                                                                    id="studentXCoord"
                                                                                    class="form-control"
                                                                                    [placeholder]="'X (хоризонтала)' | transloco "
                                                                                    formControlName="studentXCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.studentXCoord?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentXCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentXCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentXCoord?.errors?.any">{{ errors?.preferences?.controls?.studentXCoord?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">Y</span>

                                                                            <input  requiredField requiredFieldAttribute="studentYCoord"
                                                                                    type="text"
                                                                                    id="studentYCoord"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Y (вертикала)' | transloco "
                                                                                    formControlName="studentYCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.studentYCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentYCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentYCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentYCoord?.errors?.any">{{ errors?.preferences?.controls?.studentYCoord?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">W</span>
                                                                            <input  requiredField requiredFieldAttribute="studentWidth"
                                                                                    type="text"
                                                                                    id="studentWidth"
                                                                                    class="form-control"
                                                                                    [placeholder]="'широчина' | transloco "
                                                                                    formControlName="studentWidth"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.studentWidth?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentWidth?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentWidth?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentWidth?.errors?.any">{{ errors?.preferences?.controls?.studentWidth?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">H</span>
                                                                            <input  requiredField requiredFieldAttribute="studentHeight"
                                                                                    type="text"
                                                                                    id="studentHeight"
                                                                                    class="form-control"
                                                                                    [placeholder]="'височина' | transloco "
                                                                                    formControlName="studentHeight"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.studentHeight?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentHeight?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentHeight?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentHeight?.errors?.any">{{ errors?.preferences?.controls?.studentHeight?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3">
                                                                            <p-dropdown [options]="certificateFontOptions"
                                                                                        appendTo="body"
                                                                                        optionLabel="label"
                                                                                        optionValue="value"
                                                                                        formControlName="studentFont"
                                                                                        styleClass="w-100">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">
                                                                                <i class="fa-solid fa-text-height fa-fw"></i>
                                                                            </span>
                                                                            <input  requiredField requiredFieldAttribute="studentFontSize"
                                                                                    type="text"
                                                                                    id="studentFontSize"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Размер на шрифт' | transloco "
                                                                                    formControlName="studentFontSize"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.studentFontSize?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentFontSize?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentFontSize?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.studentFontSize?.errors?.any">{{ errors?.preferences?.controls?.studentFontSize?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                        </div>
                                                        <!-- date layout -->
                                                        <div class="col-12 mb-4">
                                                            <div class="d-flex">
                                                                <h3 class="me-auto">{{'Дата:' | transloco}}</h3>
                                                                <div class="d-flex flex-column">
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">X</span>
                                                                            <input  requiredField requiredFieldAttribute="frontDateXCoord"
                                                                                    type="text"
                                                                                    id="frontDateXCoord"
                                                                                    class="form-control"
                                                                                    [placeholder]="'X (хоризонтала)' | transloco "
                                                                                    formControlName="frontDateXCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.frontDateXCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateXCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateXCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateXCoord?.errors?.any">{{ errors?.preferences?.controls?.frontDateXCoord?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">H</span>
                                                                            <input  requiredField requiredFieldAttribute="frontDateYCoord"
                                                                                    type="text"
                                                                                    id="frontDateYCoord"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Y (вертикала)' | transloco "
                                                                                    formControlName="frontDateYCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.frontDateYCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateYCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateYCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateYCoord?.errors?.any">{{ errors?.preferences?.controls?.frontDateYCoord?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">W</span>
                                                                            <input  requiredField requiredFieldAttribute="frontDateWidth"
                                                                                    type="text"
                                                                                    id="frontDateWidth"
                                                                                    class="form-control"
                                                                                    [placeholder]="'широчина' | transloco "
                                                                                    formControlName="frontDateWidth"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.frontDateWidth?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateWidth?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateWidth?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateWidth?.errors?.any">{{ errors?.preferences?.controls?.frontDateWidth?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">H</span>
                                                                            <input  requiredField requiredFieldAttribute="frontDateHeight"
                                                                                    type="text"
                                                                                    id="frontDateHeight"
                                                                                    class="form-control"
                                                                                    [placeholder]="'височина' | transloco "
                                                                                    formControlName="frontDateHeight"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.frontDateHeight?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateHeight?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateHeight?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateHeight?.errors?.any">{{ errors?.preferences?.controls?.frontDateHeight?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3">
                                                                            <p-dropdown [options]="certificateFontOptions"
                                                                                        optionLabel="label"
                                                                                        optionValue="value"
                                                                                        appendTo="body"
                                                                                        formControlName="frontDateFont"
                                                                                        styleClass="w-100">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">
                                                                                <i class="fa-solid fa-text-height fa-fw"></i>
                                                                            </span>
                                                                            <input  requiredField requiredFieldAttribute="frontDateFontSize"
                                                                                    type="text"
                                                                                    id="frontDateFontSize"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Размер на шрифт' | transloco "
                                                                                    formControlName="frontDateFontSize"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.frontDateFontSize?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateFontSize?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateFontSize?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.frontDateFontSize?.errors?.any">{{ errors?.preferences?.controls?.secondDateFontSize?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </tab>
                                    <tab [label]="'Втора страница' | transloco" id="tab_secondpage">
                                        <div class="mx-3">
                                            <div class="row">
                                                <div class="col-12 md-4 md-lg-0">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="my-3 ng-star-inserted" >
                                                                <div class="mb-2">
                                                                    <label>{{'Темплейт (pdf формат):' | transloco}}</label>
                                                                </div>
                                                                <upload-file (onFinished)="onTemplateFinished($event, 2)"
                                                                                *ngIf="!secondPage"
                                                                                [files]="1"
                                                                                [withRemove]="false"
                                                                                variant="inline"
                                                                                type="template"
                                                                ></upload-file>
                                                                <alert [type]="messageFilesType" [message]="messageFiles" (remove)="messageFiles = ''"></alert>
                                                                <div *ngIf="secondPage" class="d-md-flex d-flex-column w-100">
                                                                    <h3 class ="file-name d-flex flex-column flex-sm-row m-md-0 my-3">
                                                                        <a [href]="secondPage?.url" target="_blank">{{secondPage.title ?? ''}}</a>
                                                                    </h3>
                                                                    <button class="btn btn-outline-primary btn-sm mx-2" type="button" (click)="onTemplateRemove(secondPage, 2)">
                                                                        {{ 'Премахни' | transloco }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <!-- course layout -->
                                                        <div class="col-12 mb-4">
                                                            <div class="d-flex">
                                                                <h3 class="me-auto">{{'Курс:' | transloco}}</h3>
                                                                <div class="d-flex flex-column">
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">X</span>
                                                                            <input  requiredField requiredFieldAttribute="secondCourseTitleXCoord"
                                                                                    type="text"
                                                                                    id="secondCourseTitleXCoord"
                                                                                    class="form-control"
                                                                                    [placeholder]="'X (хоризонтала)' | transloco "
                                                                                    formControlName="secondCourseTitleXCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondCourseTitleXCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleXCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleXCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleXCoord?.errors?.any">{{ errors?.preferences?.controls?.secondCourseTitleXCoord?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">Y</span>
                                                                            <input  requiredField requiredFieldAttribute="secondCourseTitleYCoord"
                                                                                    type="text"
                                                                                    id="secondCourseTitleYCoord"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Y (вертикала)' | transloco "
                                                                                    formControlName="secondCourseTitleYCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondCourseTitleYCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleYCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleYCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleYCoord?.errors?.any">{{ errors?.preferences?.controls?.secondCourseTitleYCoord?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">W</span>
                                                                            <input  requiredField requiredFieldAttribute="secondCourseTitleWidth"
                                                                                    type="text"
                                                                                    id="secondCourseTitleWidth"
                                                                                    class="form-control"
                                                                                    [placeholder]="'широчина' | transloco "
                                                                                    formControlName="secondCourseTitleWidth"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondCourseTitleWidth?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleWidth?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleWidth?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleWidth?.errors?.any">{{ errors?.preferences?.controls?.secondCourseTitleWidth?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">H</span>
                                                                            <input  requiredField requiredFieldAttribute="secondCourseTitleHeight"
                                                                                    type="text"
                                                                                    id="secondCourseTitleHeight"
                                                                                    class="form-control"
                                                                                    [placeholder]="'височина' | transloco "
                                                                                    formControlName="secondCourseTitleHeight"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondCourseTitleHeight?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleHeight?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleHeight?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleHeight?.errors?.any">{{ errors?.preferences?.controls?.secondCourseTitleHeight?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3">
                                                                            <p-dropdown [options]="certificateFontOptions"
                                                                                        optionLabel="label"
                                                                                        optionValue="value"
                                                                                        formControlName="secondCourseTitleFont"
                                                                                        appendTo="body"
                                                                                        styleClass="w-100">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">
                                                                                <i class="fa-solid fa-text-height fa-fw"></i>
                                                                            </span>
                                                                            <input  requiredField requiredFieldAttribute="secondCourseTitleFontSize"
                                                                                    type="text"
                                                                                    id="secondCourseTitleFontSize"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Размер на шрифт' | transloco "
                                                                                    formControlName="secondCourseTitleFontSize"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondCourseTitleFontSize?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleFontSize?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleFontSize?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondCourseTitleFontSize?.errors?.any">{{ errors?.preferences?.controls?.secondCourseTitleFontSize?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                        </div>
                                                        <!-- certificate number layout -->
                                                        <div class="col-12 mb-4">
                                                            <div class="d-flex">
                                                                <h3 class="me-auto">{{'Номер сертификат:' | transloco}}</h3>
                                                                <div class="d-flex flex-column">
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">X</span>
                                                                            <input  requiredField requiredFieldAttribute="certificateNumberXCoord"
                                                                                    type="text"
                                                                                    id=""
                                                                                    class="form-control"
                                                                                    [placeholder]="'X (хоризонтала)' | transloco "
                                                                                    formControlName="certificateNumberXCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.certificateNumberXCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberXCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberXCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberXCoord?.errors?.any">{{ errors?.preferences?.controls?.certificateNumberXCoord?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">Y</span>
                                                                            <input  requiredField requiredFieldAttribute="certificateNumberYCoord"
                                                                                    type="text"
                                                                                    id=""
                                                                                    class="form-control"
                                                                                    [placeholder]="'Y (вертикала)' | transloco "
                                                                                    formControlName="certificateNumberYCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.certificateNumberYCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberYCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberYCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberYCoord?.errors?.any">{{ errors?.preferences?.controls?.certificateNumberYCoord?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">W</span>
                                                                            <input  requiredField requiredFieldAttribute="certificateNumberWidth"
                                                                                    type="text"
                                                                                    id="certificateNumberWidth"
                                                                                    class="form-control"
                                                                                    [placeholder]="'широчина' | transloco "
                                                                                    formControlName="certificateNumberWidth"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.certificateNumberWidth?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberWidth?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberWidth?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberWidth?.errors?.any">{{ errors?.preferences?.controls?.certificateNumberWidth?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">H</span>
                                                                            <input  requiredField requiredFieldAttribute="certificateNumberHeight"
                                                                                    type="text"
                                                                                    id="certificateNumberHeight"
                                                                                    class="form-control"
                                                                                    [placeholder]="'височина' | transloco "
                                                                                    formControlName="certificateNumberHeight"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.certificateNumberHeight?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberHeight?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberHeight?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberHeight?.errors?.any">{{ errors?.preferences?.controls?.certificateNumberHeight?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3">
                                                                            <p-dropdown [options]="certificateFontOptions"
                                                                                        optionLabel="label"
                                                                                        optionValue="value"
                                                                                        formControlName="certificateNumberFont"
                                                                                        appendTo="body"
                                                                                        styleClass="w-100">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">
                                                                                <i class="fa-solid fa-text-height fa-fw"></i>
                                                                            </span>
                                                                            <input  requiredField requiredFieldAttribute="certificateNumberFontSize"
                                                                                    type="text"
                                                                                    id="certificateNumberFontSize"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Размер на шрифт' | transloco "
                                                                                    formControlName="certificateNumberFontSize"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.certificateNumberFontSize?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberFontSize?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberFontSize?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.certificateNumberFontSize?.errors?.any">{{ errors?.preferences?.controls?.certificateNumberFontSize?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                        </div>
                                                        <!-- held date layout  -->
                                                        <div class="col-12 mb-4">
                                                            <div class="d-flex">
                                                                <h3 class="me-auto">{{'Провеждане:' | transloco}}</h3>
                                                                <div class="d-flex flex-column">
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">X</span>
                                                                            <input  requiredField requiredFieldAttribute="heldDateNumberXCoord"
                                                                                    type="text"
                                                                                    id=""
                                                                                    class="form-control"
                                                                                    [placeholder]="'X (хоризонтала)' | transloco "
                                                                                    formControlName="heldDateNumberXCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.heldDateNumberXCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberXCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberXCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberXCoord?.errors?.any">{{ errors?.preferences?.controls?.heldDateNumberXCoord?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">Y</span>
                                                                            <input  requiredField requiredFieldAttribute="heldDateNumberYCoord"
                                                                                    type="text"
                                                                                    id=""
                                                                                    class="form-control"
                                                                                    [placeholder]="'Y (вертикала)' | transloco "
                                                                                    formControlName="heldDateNumberYCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.heldDateNumberYCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberYCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberYCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberYCoord?.errors?.any">{{ errors?.preferences?.controls?.heldDateNumberYCoord?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">W</span>
                                                                            <input  requiredField requiredFieldAttribute="heldDateNumberWidth"
                                                                                    type="text"
                                                                                    id="heldDateNumberWidth"
                                                                                    class="form-control"
                                                                                    [placeholder]="'широчина' | transloco "
                                                                                    formControlName="heldDateNumberWidth"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.heldDateNumberWidth?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberWidth?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberWidth?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberWidth?.errors?.any">{{ errors?.preferences?.controls?.heldDateNumberWidth?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">H</span>
                                                                            <input  requiredField requiredFieldAttribute="heldDateNumberHeight"
                                                                                    type="text"
                                                                                    id="heldDateNumberHeight"
                                                                                    class="form-control"
                                                                                    [placeholder]="'височина' | transloco "
                                                                                    formControlName="heldDateNumberHeight"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.heldDateNumberHeight?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberHeight?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberHeight?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberHeight?.errors?.any">{{ errors?.preferences?.controls?.heldDateNumberHeight?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3">
                                                                            <p-dropdown [options]="certificateFontOptions"
                                                                                        optionLabel="label"
                                                                                        optionValue="value"
                                                                                        formControlName="heldDateNumberFont"
                                                                                        appendTo="body"
                                                                                        styleClass="w-100">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">
                                                                                <i class="fa-solid fa-text-height fa-fw"></i>
                                                                            </span>
                                                                            <input  requiredField requiredFieldAttribute="heldDateNumberFontSize"
                                                                                    type="text"
                                                                                    id="heldDateNumberFontSize"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Размер на шрифт' | transloco "
                                                                                    formControlName="heldDateNumberFontSize"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.heldDateNumberFontSize?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberFontSize?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberFontSize?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.heldDateNumberFontSize?.errors?.any">{{ errors?.preferences?.controls?.heldDateNumberFontSize?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                        </div>
                                                        <!-- studnet layout -->
                                                        <div class="col-12 md-4">
                                                            <div class="d-flex">
                                                                <h3 class="me-auto">{{'Курсист:' | transloco}}</h3>
                                                                <div class="d-flex flex-column">
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">X</span>
                                                                            <input  requiredField requiredFieldAttribute="secondStudentXCoord"
                                                                                    type="text"
                                                                                    id=""
                                                                                    class="form-control"
                                                                                    [placeholder]="'X (хоризонтала)' | transloco "
                                                                                    formControlName="secondStudentXCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondStudentXCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentXCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentXCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentXCoord?.errors?.any">{{ errors?.preferences?.controls?.secondStudentXCoord?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">Y</span>
                                                                            <input  requiredField requiredFieldAttribute="secondStudentYCoord"
                                                                                    type="text"
                                                                                    id=""
                                                                                    class="form-control"
                                                                                    [placeholder]="'Y (вертикала)' | transloco "
                                                                                    formControlName="secondStudentYCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondStudentYCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentYCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentYCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentYCoord?.errors?.any">{{ errors?.preferences?.controls?.secondStudentYCoord?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">W</span>
                                                                            <input  requiredField requiredFieldAttribute="secondStudentWidth"
                                                                                    type="text"
                                                                                    id="secondStudentWidth"
                                                                                    class="form-control"
                                                                                    [placeholder]="'широчина' | transloco "
                                                                                    formControlName="secondStudentWidth"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondStudentWidth?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentWidth?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentWidth?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentWidth?.errors?.any">{{ errors?.preferences?.controls?.secondStudentWidth?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">H</span>
                                                                            <input  requiredField requiredFieldAttribute="secondStudentHeight"
                                                                                    type="text"
                                                                                    id="secondStudentHeight"
                                                                                    class="form-control"
                                                                                    [placeholder]="'височина' | transloco "
                                                                                    formControlName="secondStudentHeight"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondStudentHeight?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentHeight?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentHeight?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentHeight?.errors?.any">{{ errors?.preferences?.controls?.secondStudentHeight?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3">
                                                                            <p-dropdown [options]="certificateFontOptions"
                                                                                        optionLabel="label"
                                                                                        optionValue="value"
                                                                                        formControlName="secondStudentFont"
                                                                                        appendTo="body"
                                                                                        styleClass="w-100">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">
                                                                                <i class="fa-solid fa-text-height fa-fw"></i>
                                                                            </span>
                                                                            <input  requiredField requiredFieldAttribute="secondStudentFontSize"
                                                                                    type="text"
                                                                                    id="secondStudentFontSize"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Размер на шрифт' | transloco "
                                                                                    formControlName="secondStudentFontSize"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondStudentFontSize?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentFontSize?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentFontSize?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondStudentFontSize?.errors?.any">{{ errors?.preferences?.controls?.secondStudentFontSize?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                        </div>
                                                        <!-- topics layout -->
                                                        <div class="col-12 md-4">
                                                            <div class="d-flex">
                                                                <h3 class="me-auto">{{'Обхванати теми:' | transloco}}</h3>
                                                                <div class="d-flex flex-column">
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">X</span>
                                                                            <input  requiredField requiredFieldAttribute="secondTopicsXCoord"
                                                                                    type="text"
                                                                                    id=""
                                                                                    class="form-control"
                                                                                    [placeholder]="'X (хоризонтала)' | transloco "
                                                                                    formControlName="secondTopicsXCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondTopicsXCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsXCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsXCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsXCoord?.errors?.any">{{ errors?.preferences?.controls?.secondTopicsXCoord?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">Y</span>
                                                                            <input  requiredField requiredFieldAttribute="secondTopicsYCoord"
                                                                                    type="text"
                                                                                    id=""
                                                                                    class="form-control"
                                                                                    [placeholder]="'Y (вертикала)' | transloco "
                                                                                    formControlName="secondTopicsYCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondTopicsYCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsYCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsYCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsYCoord?.errors?.any">{{ errors?.preferences?.controls?.secondTopicsYCoord?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">W</span>
                                                                            <input  requiredField requiredFieldAttribute="secondTopicsWidth"
                                                                                    type="text"
                                                                                    id="secondTopicsWidth"
                                                                                    class="form-control"
                                                                                    [placeholder]="'широчина' | transloco "
                                                                                    formControlName="secondTopicsWidth"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondTopicsWidth?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsWidth?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsWidth?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsWidth?.errors?.any">{{ errors?.preferences?.controls?.secondTopicsWidth?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">H</span>
                                                                            <input  requiredField requiredFieldAttribute="secondTopicsHeight"
                                                                                    type="text"
                                                                                    id="secondTopicsHeight"
                                                                                    class="form-control"
                                                                                    [placeholder]="'височина' | transloco "
                                                                                    formControlName="secondTopicsHeight"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondTopicsHeight?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsHeight?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsHeight?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsHeight?.errors?.any">{{ errors?.preferences?.controls?.secondTopicsHeight?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3">
                                                                            <p-dropdown [options]="certificateFontOptions"
                                                                                        optionLabel="label"
                                                                                        optionValue="value"
                                                                                        formControlName="secondTopicsFont"
                                                                                        appendTo="body"
                                                                                        styleClass="w-100">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">
                                                                                <i class="fa-solid fa-text-height fa-fw"></i>
                                                                            </span>
                                                                            <input  requiredField requiredFieldAttribute="secondTopicsFontSize"
                                                                                    type="text"
                                                                                    id="secondTopicsFontSize"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Размер на шрифт' | transloco "
                                                                                    formControlName="secondTopicsFontSize"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondTopicsFontSize?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsFontSize?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsFontSize?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondTopicsFontSize?.errors?.any">{{ errors?.preferences?.controls?.secondTopicsFontSize?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr/>
                                                        </div>
                                                        <!-- date layout -->
                                                        <div class="col-12 md-4">
                                                            <div class="d-flex">
                                                                <h3 class="me-auto">{{'Дата:' | transloco}}</h3>
                                                                <div class="d-flex flex-column">
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">X</span>
                                                                            <input  requiredField requiredFieldAttribute="secondDateXCoord"
                                                                                    type="text"
                                                                                    id=""
                                                                                    class="form-control"
                                                                                    [placeholder]="'X (хоризонтала)' | transloco "
                                                                                    formControlName="secondDateXCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondDateXCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateXCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateXCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateXCoord?.errors?.any">{{ errors?.preferences?.controls?.secondDateXCoord?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">Y</span>
                                                                            <input  requiredField requiredFieldAttribute="secondDateYCoord"
                                                                                    type="text"
                                                                                    id=""
                                                                                    class="form-control"
                                                                                    [placeholder]="'Y (вертикала)' | transloco "
                                                                                    formControlName="secondDateYCoord"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondDateYCoord?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateYCoord?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateYCoord?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateYCoord?.errors?.any">{{ errors?.preferences?.controls?.secondDateYCoord?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-colmn flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">W</span>
                                                                            <input  requiredField requiredFieldAttribute="secondDateWidth"
                                                                                    type="text"
                                                                                    id="secondDateWidth"
                                                                                    class="form-control"
                                                                                    [placeholder]="'широчина' | transloco "
                                                                                    formControlName="secondDateWidth"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondDateWidth?.status === 'INVALID'"
                                                                            />
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateWidth?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateWidth?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateWidth?.errors?.any">{{ errors?.preferences?.controls?.secondDateWidth?.errors?.any }}</div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">H</span>
                                                                            <input  requiredField requiredFieldAttribute="secondDateHeight"
                                                                                    type="text"
                                                                                    id="secondDateHeight"
                                                                                    class="form-control"
                                                                                    [placeholder]="'височина' | transloco "
                                                                                    formControlName="secondDateHeight"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondDateHeight?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateHeight?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateHeight?.errors?.max">{{ 'Не може да е по-голямо от 10 000' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateHeight?.errors?.any">{{ errors?.preferences?.controls?.secondDateHeight?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-wrap flex-column flex-lg-row">
                                                                        <div class="col-lg-6 col-12 px-3">
                                                                            <p-dropdown [options]="certificateFontOptions"
                                                                                        optionLabel="label"
                                                                                        optionValue="value"
                                                                                        formControlName="secondDateFont"
                                                                                        appendTo="body"
                                                                                        styleClass="w-100">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-6 col-12 px-3 position-relative">
                                                                            <span class="input-group-text justify-content-center">
                                                                                <i class="fa-solid fa-text-height fa-fw"></i>
                                                                            </span>
                                                                            <input  requiredField requiredFieldAttribute="secondDateFontSize"
                                                                                    type="text"
                                                                                    id="secondDateFontSize"
                                                                                    class="form-control"
                                                                                    [placeholder]="'Размер на шрифт' | transloco "
                                                                                    formControlName="secondDateFontSize"
                                                                                    [attr.disabled]="loading ? true : null"
                                                                                    [class.is-invalid]="errors?.preferences?.controls?.secondDateFontSize?.status === 'INVALID'"
                                                                            />

                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateFontSize?.errors?.min">{{ 'Не може да е по-малко от 0' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateFontSize?.errors?.max">{{ 'Не може да е по-голямо от 200' | transloco }}</div>
                                                                            <div class="invalid-feedback" *ngIf="errors?.preferences?.controls?.secondDateFontSize?.errors?.any">{{ errors?.preferences?.controls?.secondDateFontSize?.errors?.any }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </tab>
                                </tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer border-light">
                    <div class="row md-4">
                        <div class="col-12 d-flex align-items-center justify-content-end my-2 text-end">
                            <div class="form-check form-switch d-flex flex-column justify-content-center">
                                <input class="form-check-input"
                                        type="checkbox"
                                        id="certificate"
                                        formControlName="certificate" />
                                <label class="form-check-label" for="certificate"></label>
                            </div>
                            <label for="certificate" class="me-auto me-md-2 text-md-end">{{ 'Издаване на сертификати без условия' | transloco }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card d-flex mb-5">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h3 class="my-0 fw-bold">{{'Секции' | transloco}}</h3>
                    <button type="button" class="btn btn-primary btn-sm" (click)="addSection()">
                        <i class="fa-solid fa-plus fa-fw"></i> {{'Добави' | transloco}}
                    </button>
                </div>
                <div class="card-body" formArrayName="sections">
                    <div cdkDropList class="section-list" (cdkDropListDropped)="drop($event)">
                        <div    *ngFor="let section of sectionControls.controls; let i = index"
                                class="section-select d-flex justify-content-between align-items-center"
                                cdkDrag
                                (click)="selectSection(i)"
                                [ngClass]="{'selected-section': i === selectedSectionIndex}"
                        >
                            <h2 class="m-0">
                                {{ getSectionTitleDisplay(section.get('type')?.value ?? 'Секция', i) }}
                            </h2>
                            <span class="py-0 px-2 me-3" (click)="removeSection(i)"><i class="fa-solid fa-fw fa-trash text-danger"></i></span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="form.get('selectedSection') && selectedSectionIndex !== null" class="card mb-3 mb-lg-5" [formGroup]="selectedSectionFormGroup">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h3 class="my-0 fw-bold">{{ ('Детайли на секция' | transloco) }}</h3>
                    <div class="form-check form-switch d-inline-block">
                        <input class="form-check-input" type="checkbox" id="sectionStatusToggle{{ selectedSectionIndex }}" formControlName="status">
                        <label class="form-check-label" for="sectionStatusToggle{{ selectedSectionIndex }}">{{ selectedSectionFormGroup.get('status')?.value ? ('Активен'|transloco) : ('Неактивен'|transloco) }}</label>
                    </div>
                </div>
                <div class="card-body" >
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group d-flex flex-column">
                                <label for="type">{{ 'Тип' | transloco }}</label>
                                <p-dropdown [options]="sectionTypeOptions"
                                            optionLabel="label"
                                            optionValue="value"
                                            formControlName="type"
                                            styleClass="w-100">
                                </p-dropdown>
                            </div>
                        </div>
                        <div class="col-lg-6" *ngIf="currentAllowedFieldsStatus['title']">
                            <div class="form-group d-flex flex-column">
                                <label for="title">{{ 'Заглавие' | transloco }}</label>
                                <input
                                        type="text"
                                        class="form-control"
                                        formControlName="title"
                                        [class.is-invalid]="selectedSectionFormGroup?.controls?.['title']?.status === 'INVALID'">

                                <div class="invalid-feedback" *ngIf="selectedSectionFormGroup?.controls?.['title']?.errors?.['maxlength']">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6" *ngIf="currentAllowedFieldsStatus['subtitle']">
                            <div class="form-group d-flex flex-column">
                                <label for="subtitle">{{ 'Подзаглавие' | transloco }}</label>
                                <input  type="text"
                                        class="form-control"
                                        formControlName="subtitle"
                                        [class.is-invalid]="selectedSectionFormGroup?.controls?.['subtitle']?.status === 'INVALID'">
                                <div class="invalid-feedback" *ngIf="selectedSectionFormGroup?.controls?.['subtitle']?.errors?.['maxlength']">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                            </div>
                        </div>
                        <div class="col-lg-6" *ngIf="currentAllowedFieldsStatus['alt']">
                            <div class="form-group d-flex flex-column">
                                <label for="alt">{{ 'Алтернативен текст' | transloco }}</label>
                                <input  type="text"
                                        class="form-control"
                                        formControlName="alt"
                                        [class.is-invalid]="selectedSectionFormGroup?.controls?.['alt']?.status === 'INVALID'">
                                <div class="invalid-feedback" *ngIf="selectedSectionFormGroup?.controls?.['alt']?.errors?.['maxlength']">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="currentAllowedFieldsStatus['description']">
                        <div class="form-group d-flex flex-column py-3">
                            <label for="itemDescription" class="col-12">{{ 'Съдържание:' | transloco }}</label>
                            <div class="col-12">
                                <input-text-editor    type="text"
                                                id="itemDescription"
                                                name="description"
                                                formControlName="description"
                                                [attr.disabled]="loading ? true : null"
                                                [class.is-invalid]="selectedSectionFormGroup?.controls?.['description']?.status === 'INVALID'"
                                ></input-text-editor>
                                <div class="invalid-feedback" *ngIf="selectedSectionFormGroup?.controls?.['description']?.errors?.['maxlength']">{{ 'Не може да е повече от 65535 символа' | transloco }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="currentAllowedFieldsStatus['image']">
                        <div class="col-lg-6">
                            <div class="form-group d-flex flex-column">
                                <label>{{ 'Снимка' | transloco }}</label>
                                <upload-image   (onFinished)="onFileFinished($event, false, selectedSectionIndex)"
                                                (onRemove)="removeFile(false, selectedSectionIndex)"
                                                [preview]="getPhotoUrl(false, selectedSectionIndex)"
                                                [withRemove]="true"
                                                [loading]="loading"
                                                [class.is-invalid]="selectedSectionFormGroup?.controls?.['image']?.status === 'INVALID'">
                                </upload-image>
                                <div class="invalid-feedback" *ngIf="selectedSectionFormGroup?.controls?.['image']?.errors?.['maxlength']">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                            </div>
                        </div>
                        <!--    Thumbnail of section
                                <div class="col-lg-6">
                            <div class="form-group d-flex flex-column">
                                <label>{{ 'Тъмбнейл' | transloco }}</label>
                                <upload-image   (onFinished)="onFileFinished($event, true, selectedSectionIndex)"
                                                (onRemove)="removeFile(true, selectedSectionIndex)"
                                                [preview]="getPhotoUrl(true, selectedSectionIndex)"
                                                [loading]="loading"
                                                [class.is-invalid]="selectedSectionFormGroup?.controls?.['thumbnail']?.status === 'INVALID'">
                                </upload-image>
                                <div class="invalid-feedback" *ngIf="selectedSectionFormGroup?.controls?.['thumbnail']?.errors?.['maxlength']">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                            </div>
                        </div> -->
                    </div>

                </div>
            </div>

            <div *ngIf="selectedSectionIndex !== null" class="card mb-3 mb-lg-5">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h3 class="my-0 fw-bold">{{('Елементи на Секция' | transloco) }}</h3>
                    <button type="button" class="btn btn-primary" (click)="addItem()" *ngIf="canAddItems">
                        <i class="fa-solid fa-plus fa-fw"></i> {{'Добави' | transloco}}
                    </button>
                </div>
                <div class="card-body p-0">
                    <div class="alert alert-warning m-2" *ngIf="!canAddItems">
                        <p class="mb-0">{{'Към този тип секция не могат да бъдат добавени елементи.' | transloco}}</p>
                    </div>
                    <div cdkDropList class="accordion accordion-flush" id="accordionFlushExample" (cdkDropListDropped)="dropItem($event)">
                        <div *ngFor="let item of selectedSectionItems.controls; let j = index" [cdkDragDisabled]="isItemsAccordionLocked" cdkDrag class="accordion-item" [formGroup]="getFormGroupAtIndex(j)">
                            <h2 class="accordion-header d-flex justify-content-between" [id]="'heading' + j">
                                <button class="accordion-button" (click)="toggleAccordionState(j)" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + j" aria-expanded="true" [attr.aria-controls]="'collapse' + j">
                                    {{ ('Елемент' | transloco) + ' ' + (j + 1) }}
                                </button>
                                <div class="d-flex align-items-center">
                                    <div class="form-check form-switch fs-6 pt-3 me-4">
                                        <input class="form-check-input mt-2" type="checkbox" [id]="'itemStatusToggle' + j" formControlName="status" [checked]="item.get('status')?.value">
                                        <label class="form-check-label" [for]="'itemStatusToggle' + j">
                                            {{ item.get('status')?.value ? ('Активен' | transloco) : ('Неактивен' | transloco) }}
                                        </label>
                                    </div>
                                    <span class="py-0 px-2 mt-3 me-3" (click)="removeItem(j)"><i class="fa-solid fa-fw fa-trash text-danger"></i></span>
                                </div>
                            </h2>
                            <div [id]="'collapse' + j" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + j" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div class="col-lg-6" *ngIf="currentAllowedItemsStatus['title']">
                                            <div class="form-group">
                                                <label>{{'Заглавие' | transloco}}</label>
                                                <input  type="text"
                                                        class="form-control"
                                                        formControlName="title"
                                                        [class.is-invalid]="item?.get('title')?.status === 'INVALID'">
                                                <div class="invalid-feedback" *ngIf="item?.get('title')?.errors?.['maxlength']">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6" *ngIf="currentAllowedItemsStatus['subtitle']">
                                            <div class="form-group">
                                                <label>{{'Подзаглавие' | transloco}}</label>
                                                <input type="text" class="form-control" formControlName="subtitle" [class.is-invalid]="item?.get('subtitle')?.status === 'INVALID'">
                                                <div class="invalid-feedback" *ngIf="item?.get('subtitle')?.errors?.['maxlength']">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6" *ngIf="currentAllowedItemsStatus['alt']">
                                            <div class="form-group">
                                                <label>{{'Алтернативен текст' | transloco}}</label>
                                                <input type="text" class="form-control" formControlName="alt" [class.is-invalid]="item?.get('alt')?.status === 'INVALID'">
                                                <div class="invalid-feedback" *ngIf="item?.get('alt')?.errors?.['maxlength']">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="currentAllowedItemsStatus['description']">
                                        <div class="form-group d-flex flex-column py-3">
                                            <label for="description" class="col-12">{{ 'Съдържание:' | transloco }}</label>
                                            <div class="col-12">
                                                <input-text-editor    type="text"
                                                                id="description"
                                                                name="description"
                                                                formControlName="description"
                                                                [attr.disabled]="loading ? true : null"
                                                                [class.is-invalid]="item?.get('description')?.status === 'INVALID'"
                                                ></input-text-editor>
                                                <div class="invalid-feedback" *ngIf="item?.get('description')?.errors?.['maxlength']">{{ 'Не може да е повече от 65535 символа' | transloco }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="currentAllowedItemsStatus['image']">
                                        <div class="col-lg-6">
                                            <div class="form-group d-flex flex-column">
                                                <label>{{ 'Снимка' | transloco }}</label>
                                                <upload-image   (onFinished)="onFileFinished($event, false, selectedSectionIndex, j)"
                                                                (onRemove)="removeFile(false, selectedSectionIndex, j)"
                                                                [preview]="getPhotoUrl(false, selectedSectionIndex, j)"
                                                                [loading]="loading"
                                                                [class.is-invalid]="item?.get('image')?.status === 'INVALID'">
                                                </upload-image>
                                                <div class="invalid-feedback" *ngIf="item?.get('image')?.errors?.['maxlength']">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                            </div>
                                        </div>
                                        <!--    Thumbnail of Item
                                                <div class="col-lg-6">
                                            <div class="form-group d-flex flex-column">
                                                <label>{{ 'Тъмбнейл' | transloco }}</label>
                                                <upload-image   (onFinished)="onFileFinished($event, true, selectedSectionIndex, j)"
                                                                (onRemove)="removeFile(true, selectedSectionIndex, j)"
                                                                [preview]="getPhotoUrl(true, selectedSectionIndex, j)"
                                                                [loading]="loading"
                                                                [class.is-invalid]="item?.get('thumbnail')?.status === 'INVALID'">
                                                </upload-image>
                                                 <div class="invalid-feedback" *ngIf="item?.get('thumbnail')?.errors?.['maxlength']">{{ 'Не може да е повече от 255 символа' | transloco }}</div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="submit-panel bg-white py-2 mb-5 text-center">
                <button type="button"
                        class="btn btn-primary"
                        [disabled]="loading || (form?.get('payments')?.value?.length === 0)"
                        (click)="onSubmit($event)"
                >
                    <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                    <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                    <span class="mx-2">{{ 'Запиши' | transloco }}</span>
                </button>
            </div>
        </form>


</page-wrap>
