import { Franchise } from "../franchise.model";
import { Badge } from "./badge.model";

export class Award {
    id?: number;
    name?: string;
    image?:	string;
    points?: number;
    counts?: number;
    total?:	number;
    status?: string;
    badge?: Badge;
    franchise?: Franchise;

    // custom fields
    labelTotalCounts: string = '';
    badgeImage: string = '';

    static fromJson(fields: any): Award {
        let item = Object.assign(new this(), fields);

        item.labelTotalCounts = item.totalCount();
        return item;
    }

    totalCount(): string {
        return `${this.counts ?? 0}/${this.total ?? 0}`;
    }
}
