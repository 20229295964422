import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { UserRoleName } from '../../models/user/user-role.model';
import { User } from '../../models/user/user.model';
import { AuthService } from '../../services/auth.service';

export interface MenuActionsComponentAction {
    text?: string;
    link?: string;
    icon?: string;
    highlight?: boolean;
    roles?: UserRoleName[];
    divider?: boolean;
    header?: boolean;
    id?: string;
    hide?: boolean;
    disabled?: boolean;
    queryParams?: any;
};

@Component({
    selector: 'menu-actions',
    templateUrl: './menu-actions.component.html',
    styleUrls: ['./menu-actions.component.scss']
})
export class MenuActionsComponent implements OnInit {
    protected innerActions: MenuActionsComponentAction[] = [];

    user: User|null = null;

    @Output() buttonClicked: EventEmitter<string> = new EventEmitter();

    @Input() defaultEmptyIcon: string = 'fa-solid';
    @Input() smallButtons: boolean = true;
    @Input() dropdownStartFrom: number = 1;
    @Input() dropdownOnly: boolean = false;
    @Input() dropdownIcon: string = 'fa-bars';
    @Input() dropdownClass: string = 'btn-primary';
    @Input() dropdownButtonText: string = '';
    @Input() roles: UserRoleName[]  = ['admin','marketing','partner','student','teacher'];
    @Input() rightOffset: boolean = true;
    @Input()
    set actions(value: MenuActionsComponentAction[]) {
        if (this.innerActions !== value) {
            this.innerActions = value?.filter(item => {
                return !item.hide
                    && (!this.user?.role?.name
                        || !item?.roles?.length
                        || item?.roles?.includes(this.user?.role?.name))
            })?.slice() || [];
        }
    }
    get actions(): MenuActionsComponentAction[] {
        return this.innerActions;
    }

    constructor(
        private authService: AuthService,
    ) {
        this.user = this.authService.getUser() ?? this.user;
    }

    ngOnInit(): void {

    }

    onButtonClicked(buttonId: string) {
        this.buttonClicked.emit(buttonId);
    }
}
