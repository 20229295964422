import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Franchise } from 'src/app/shared/models/franchise.model';
import { Group } from 'src/app/shared/models/group.model';
import { FranchiseService } from 'src/app/shared/services/franchise.service';

@Component({
    selector: 'sidebar-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    hide: boolean = false;
    company: Franchise|null = null;

    constructor(
        private franchiseService: FranchiseService,
    ) {

    }

    ngOnInit(): void {
        const subscribe = this.franchiseService.getCompanyDetails().subscribe(data => {
            this.company = data;
            this.hide = !!this.company?.isPreference('mainmenu.hide.calendar', true);
        });
        this.subscriptions.push(subscribe);
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

    @Input() headline: string|null = null;
    @Input() group: Group = new Group;
}
