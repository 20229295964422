import { ZoomLive } from "./zoom-live.model";

export class Live {
    online?: boolean;
    zoom?: ZoomLive;
    google?: string;

    static fromJson(fields: any): Live {
        let item = Object.assign(new this(), fields);
        item.zoom = fields?.zoom && ZoomLive.fromJson(fields?.zoom);
        return item;
    }
}
