import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

import { User } from 'src/app/shared/models/user/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService, UserServiceFilter, UserServicePaginate } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { GroupCourse } from 'src/app/shared/models/user/user-group-course.model';
import { ListingTableComponentColumn } from 'src/app/shared/components/listing-table/listing-table.component';
import { Paginate } from 'src/app/shared/models/paginate.model';
import { PointOther } from 'src/app/shared/models/scoreboard/point-other.model';
import { Certificate } from 'src/app/shared/models/certificate/certificate.model';
import { HomeworkFile } from 'src/app/shared/models/homework-file.model';
import { TabComponent } from 'src/app/shared/components/tabs';
import { Payment } from 'src/app/shared/models/payment.model';
import { Test } from 'src/app/shared/models/test.model';
import { Present } from '../../models/scoreboard/present.model';
import { Location } from '@angular/common';

type Tab = (
      'groups'
    | 'payments'
    | 'certificates'
    | 'homework'
    | 'projects'
    | 'exams'
    | 'score'
    | 'awards'
);

interface TabList {
    // entity: Paginate<GroupCourse|Payment|Certificate|HomeworkFile|Test|PointOther>;
    entity: Paginate<any>;
    filter: UserServicePaginate;
    columns?: ListingTableComponentColumn[];
};

@Component({
    selector: 'overview-student',
    templateUrl: './overview-student.component.html',
    styleUrls: ['./overview-student.component.scss']
})
export class OverviewStudentComponent implements OnInit, OnDestroy {
    protected readonly paginateMinLimit: number = 10;
    protected readonly tabsDefaultFilter: UserServicePaginate = {
        limit: this.paginateMinLimit,
        page: 2,
        include: 'group'
    };

    protected innerId: number|null = 0;
    protected innerLoadingQueue: number = 0;
    protected listSubscription?: Subscription;
    protected subscriptions: Subscription[] = [];

    student: User|null = null;
    user: User|null = null;
    search: string|null = null;
    searchDebounce: any = null;
    searchDebounceTimeout: number = environment.debounce.default;
    noResultsFround:boolean = false;
    careerPreferences: string[] = [];

    showRemoveConfirm: boolean = false;
    showStudentLoginAsConfirm: boolean = false;
    showStudentPaymentDialog: boolean = false;
    paymentItem: Payment|null = null;

    currentTab: Tab = 'groups';
    tabs: {[key in Tab]: TabList} = {
        groups: {
            entity: new Paginate<GroupCourse>,
            filter: Object.assign({}, this.tabsDefaultFilter),
        },
        payments: {
            entity: new Paginate<Payment>,
            filter: Object.assign({}, this.tabsDefaultFilter),
        },
        certificates: {
            entity: new Paginate<Certificate>,
            filter: Object.assign({}, this.tabsDefaultFilter),
        },
        homework: {
            entity: new Paginate<HomeworkFile>,
            filter: Object.assign({}, this.tabsDefaultFilter),
            columns: [
                {
                    name: translate('Съобщение'),
                    field: 'description',
                    limitWidth: true,

                }, {
                    name: translate('Група'),
                    field: 'col_group',
                    defaultValue: ' ',
                    template: true,
                }, {
                    name: translate('Изпратено на'),
                    field: 'col_date',
                    defaultValue: ' ',
                    template: true,
                }, {
                    name: translate('Проверено?'),
                    field: 'col_checked',
                    defaultValue: ' ',
                    template: true,
                }, {
                    name: translate('Точки'),
                    field: 'points',
                }, {
                    field: 'preview',
                    defaultValue: ' ',
                    template: true,
                }
            ]
        },
        projects: {
            entity: new Paginate<HomeworkFile>,
            filter: Object.assign({}, this.tabsDefaultFilter),
            columns: [
                {
                    name: translate('Задание'),
                    field: 'col_title',
                    defaultValue: ' ',
                    template: true,
                },
                {
                    name: translate('Група'),
                    field: 'col_group',
                    defaultValue: ' ',
                    template: true,
                },
                {
                    name: translate('Изпратено на'),
                    field: 'col_date',
                    defaultValue: ' ',
                    template: true,
                }, {
                    name: translate('Проверено?'),
                    field: 'col_checked',
                    defaultValue: ' ',
                    template: true,
                }, {
                    name: translate('Точки'),
                    field: 'points',
                }, {
                    field: 'preview',
                    defaultValue: ' ',
                    template: true,
                }
            ]
        },
        exams: {
            entity: new Paginate<Test>,
            filter: Object.assign({}, this.tabsDefaultFilter),
            columns: [
                {
                    name: translate('Започнат на:'),
                    field: 'col_start',
                    defaultValue: ' ',
                    template: true,
                },
                {
                    name: translate('Завършил на:'),
                    field: 'col_end',
                    defaultValue: ' ',
                    template: true,
                },
                {
                    name: translate('Група'),
                    field: 'col_group',
                    defaultValue: ' ',
                    template: true,
                },
                {
                    name: translate('Време'),
                    field: 'col_time',
                    defaultValue: ' ',
                    template: true,
                },
                {
                    name: translate('Точки'),
                    defaultValue: ' ',
                    template: true,
                    field: 'col_result',
                },
                {
                    name: translate('Тип'),
                    defaultValue: ' ',
                    template: true,
                    field: 'col_type',
                },
                {
                    name: translate('Оценка'),
                    field: 'col_exam',
                    defaultValue: ' ',
                    template: true,
                },
                {
                    name: '',
                    field: 'col_show',
                    defaultValue: ' ',
                    template: true,
                }
            ],
        },
        score: {
            entity: new Paginate<PointOther>,
            filter: Object.assign({}, this.tabsDefaultFilter),
            columns: [
                {
                    name: translate('Задание'),
                    field: 'title',
                },
                {
                    name: translate('Получени точки'),
                    field: 'current',
                },
                {
                    name: translate('Максимален брой'),
                    field: 'max',
                }
            ],
        },
        awards: {
            entity: new Paginate<Present>,
            filter: Object.assign({}, this.tabsDefaultFilter),
            columns: [
                {
                    name: translate('Награда'),
                    field: 'col_title',
                    defaultValue: ' ',
                    template: true,
                },
                {
                    name: translate('Изображение'),
                    field: 'col_award',
                    defaultValue: ' ',
                    template: true,
                },
                {
                    name: translate('Изпратено на'),
                    field: 'col_date',
                    defaultValue: ' ',
                    template: true,
                },
                {
                    name: translate('Получена'),
                    field: 'col_status',
                    defaultValue: ' ',
                    template: true,
                },
            ],
        },
    };

    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();
    @Input() loading: boolean = false;

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }

            setTimeout(() => this.loadingChange.emit(this.loading));
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    @Input()
    set id(val: number|null) {
        if (this.innerId !== val) {
            this.innerId = val;

            this.getUser();
        }
    }
    get id(): number|null {
        return this.innerId;
    }

    @Output() studentChange: EventEmitter<User> = new EventEmitter();

    constructor(
        private router: Router,
        private messageService: MessageService,
        private userService: UserService,
        private authService: AuthService,
        private locationService: Location,
    ) {
        this.user = this.authService.getUser();
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

    onButtonClicked(buttonId: string) {
        this.showStudentLoginAsConfirm = buttonId === 'login';
        this.showRemoveConfirm = buttonId === 'remove';
    }

    protected getUser() {
        const filter: UserServiceFilter = {
            include: [
                'cv',
                'certificates',
                'groups',
                'homeworks',
                'payments',
                'tests',
                'projects',
                'points',
                'awards'
            ],
        };

        if (this.user?.id == this.id) {
            filter.include = [
                'cv',
                'points',
                'tests',
            ];
        }

        this.loadingQueue++;
        const subscription = this.userService.getItem(this.id ?? 0, filter).subscribe({
            next: response => {
                this.loadingQueue--;

                this.student = response?.data;
                this.studentChange.emit(this.student);

                const payload: {[key in Tab]?: string} = {
                    groups: 'groups',
                    payments: 'payments',
                    certificates: 'certificates',
                    homework: 'homeworks',
                    exams: 'tests',
                    score: 'points',
                    awards: 'awards',
                    projects: 'projects',
                };


                for (let key in payload) {
                    const name: keyof User = payload[key as Tab] as keyof User;
                    const data: any[] = this.student?.[name] as any[];

                    this.tabs?.[key as Tab]?.entity?.append(data);
                    if (this.tabs?.[key as Tab]?.entity ?? false) {
                        this.tabs[key as Tab].entity.meta.per_page = this.paginateMinLimit;
                        this.tabs[key as Tab].entity.meta.total = data?.length === this.paginateMinLimit
                            ? this.paginateMinLimit + 1
                            : data?.length;

                        this.tabs[key as Tab].entity.meta.current_page = 1;
                        this.tabs[key as Tab].entity.meta.last_page = data?.length === this.paginateMinLimit ? 2 : 1;
                    }
                }

                this.careerPreferences = this.student?.getCareerPreferences() || [];
            },
            error: error => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'error',
                    detail: translate('Курсистът не съществува!'),
                });
                this.locationService.back();
            }
        });
        this.subscriptions.push(subscription);
    }

    protected getAdditionalData(tab: Tab, appendData: boolean = false): void {
        if (this.loading) {
            return;
        }

        if (appendData && this.tabs[tab]?.entity?.data?.length) {
            this.noResultsFround = false;
            if (this.tabs[tab]?.entity?.reachEnd()) {
                return;
            }
            this.tabs[tab].filter.page = this.tabs[tab]?.entity?.nextPage();
        } else if (!this.tabs[tab]?.entity?.data?.length) {
            if(appendData){
                this.noResultsFround = true;
            }
            this.tabs[tab].filter.page = 1;
        }

        if(this.noResultsFround && appendData) {
            return;
        }

        const action: {[key in Tab]?: Function} = {
            groups: this.userService.getGroups,
            payments: this.userService.getPayments,
            certificates: this.userService.getCertificates,
            homework: this.userService.getHomeworks,
            exams: this.userService.getExams,
            score: this.userService.getPoints,
            awards: this.userService.getAwards,
            projects: this.userService.getProjects,
        };

        if (!action[tab]) {
            return;
        }

        this.loadingQueue++;
        const subscription = action[tab]?.call(this.userService, this.id ?? 0, this.tabs[tab].filter).subscribe({
            next: (data: any) => {
                this.loadingQueue--;

                if (appendData && this.tabs[tab].entity) {
                    data.data = [
                        ...this.tabs[tab]?.entity?.data ?? [],
                        ...data?.data ?? [],
                    ];
                }

                this.tabs[tab].entity = data;
            }, error: (error: any) => {
                this.loadingQueue--;
            }
        });

        this.subscriptions.push(subscription);
    }

    onPayment(event: Event|MouseEvent, payment: Payment): boolean {
        event?.preventDefault();

        this.showStudentPaymentDialog = true;
        this.paymentItem = payment;

        return false;
    }

    onPaymentUpdated(updatedPayment: Payment) {
        if (this.tabs.payments?.entity?.data) {
            this.tabs.payments.entity.data = this.tabs.payments.entity.data.map(item => {
                if (item.id === updatedPayment.id) {
                    return updatedPayment;
                }
                return item;
            }).slice();;
        }
    }

    onClosePaymentAction(event: any) {
        if (event.action === 'close') {
            this.showStudentPaymentDialog = false;
            this.paymentItem = null;
        }
    }

    onRemoveConfirmed(status: string) {
        this.showRemoveConfirm = false;

        if (status === 'yes') {
            const subscription = this.userService.delete(this.student?.id ?? 0).subscribe({
                next: data => {
                    this.messageService.add({
                        detail: translate('Успешно премахнат курсист!'),
                        severity: 'success'
                    });

                    this.locationService.back();
                },
                error: error => {
                    this.messageService.add({
                        detail: translate('Възникна грешка при премахването на курсист!'),
                        severity: 'error'
                    });
                }
            });
            this.subscriptions.push(subscription);
        }
    }

    onStudentLoginAsConfirmed(action: string): void {
        this.showStudentLoginAsConfirm = false;

        if (action !== 'yes') {
            return;
        }

        const subscription = this.userService.signAs(this.student?.id ?? 0).subscribe({
            next: data => {
                this.authService.loginAs(data.data.token).subscribe({
                    next: data => {
                        this.messageService.add({
                            severity: 'success',
                            detail: translate('Успешно вписване като курсист!'),
                        });

                        this.student = null;
                        this.router.navigateByUrl('/');
                     }, error: error => {
                        this.messageService.add({
                            severity: 'error',
                            detail: translate('Възникна грешка при вписване като курсист!'),
                        });
                    }
                });
            },
            error: error => {
                this.messageService.add({
                    severity: 'error',
                    detail: translate('Възникна грешка при вписване като курсист!'),
                });
            }
        });

        this.subscriptions.push(subscription);
    }

    trackBy(index: number, item: any) {
        return item?.id ?? 0;
    }

    onScrollPage(event: any): void {
        // need to wait onTabChange event first, otherwise it could not load data
        // from real active tab
        setTimeout(() => {
            this.getAdditionalData(this.currentTab, true);
        }, 100);
    }

    onTabChange(event: {tab?: TabComponent, index: number}): void {
        this.currentTab = event?.tab?.id as Tab;
    }
}
