export class InputCareer {
    title?: string;
    info?: string;
    franchise?: number;
    company?: number;
    status?: ('active'|'inactive');
    date?: string;
    town?: number;
    works?: number[];
    employments?: number[];
    url?: string;
    external_url?: string;

    static fromJson(fields: any): InputCareer {
        let item = Object.assign(new this(), fields);
        return item;
    }

}
