import { GroupModulePresentation } from "./group-module-presentation.model";
import { GroupModuleVideo } from "./group-module-video.model";
import { Group } from "./group.model";
import { MaterialModule } from "./material.model";
import { Module } from "./module.model";
import { Poll } from "./polls/poll.model";

export type GroupModuleStatus = ('active'|'inactive');

export class GroupModule {
    id?: number;
    status?: GroupModuleStatus;
    title?: string;
    presentation?: GroupModulePresentation;
    created?: string;
    updated?: string;
    group?: Group;
    module?: Module;
    videos?: GroupModuleVideo[];
    videos_available?: number;
    materials?: MaterialModule[];
    polls?: Poll[];

    static fromJson(fields: any): GroupModule {
        let item = Object.assign(new this(), fields);

        item.group = Group.fromJson(fields?.group);
        item.module = Module.fromJson(fields?.module);
        item.presentation = GroupModulePresentation.fromJson(fields?.presentation);
        item.videos = fields?.videos?.map((item: any) => GroupModuleVideo.fromJson(item));

        return item;
    }

    toggleStatus(): GroupModuleStatus {
        return this.status === 'active' ? 'inactive' : 'active';
    }
}
