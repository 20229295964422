import { GroupEnrollment } from "./group-enrollment.model";
import { GroupLandingInstallment } from "./group-landing-installment.model";

export class GroupLanding {
    id?: number;
    title?: string;
    starting?: string;
    days_of_week?: string[];
    days_of_week_human?: string;
    course_conduction?: string[];
    price?: number;
    price_enrollment?: number|null;
    price_enrollment_date?: string;
    start?: string;
    enrollments?: GroupEnrollment[];
    installments?: GroupLandingInstallment[];

    static fromJson(fields: any): GroupLanding {
        let item = Object.assign(new this(), fields);
        item.enrollments = fields?.enrollments?.map((item: any) => GroupEnrollment.fromJson(item));
        item.installments = fields?.installments?.map((item: any) => GroupLandingInstallment.fromJson(item));

        return item;
    }

    static sortGroupsByEarlyDateAndPrice(groups: GroupLanding[]): GroupLanding[] {
        const currentDate = new Date().getTime();

        return groups?.map((group) => {
            const filteredEnrollments = group.enrollments
                ?.filter((item) => {
                    const dateValue = item.date ? new Date(item.date).getTime() : 0;
                    return currentDate < dateValue;
                })
                ?.sort((a, b) => {
                    const dateA = a.date ? new Date(a.date).getTime() : 0;
                    const dateB = b.date ? new Date(b.date).getTime() : 0;
                    return dateA - dateB;
                });

            const earlyDateAndPrice = filteredEnrollments?.length
                ? { price: Number(filteredEnrollments[0].price), date: filteredEnrollments[0].date || '' }
                : { price: Number(group.price), date: group.start || '' };

            return {
                ...group,

                price_enrollment: filteredEnrollments?.length ? earlyDateAndPrice.price : null,
                price_enrollment_date: filteredEnrollments?.length ? earlyDateAndPrice.date : null,
            };
        }).sort((a, b) => (new Date(a.start ?? '')).getTime() - (new Date(b.start ?? '')).getTime());
    }
}
