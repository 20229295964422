import { TestResultAnswer } from "./module-test-result-answer.model";

export class TestPracticeResult {
    id?: number;
    user?: number;
    result?: number;
    exam?: string;
    finished?: string;
    answers?: TestResultAnswer[];

    static fromJson(fields: any): TestPracticeResult {
        let item = Object.assign(new this(), fields);

        item.answers = fields?.answers?.map((item: any) => TestResultAnswer.fromJson(item));

        return item;
    }
}
