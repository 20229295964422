import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subscription } from 'rxjs';

import { Group } from '../../models/group.model';
import { Paginate } from '../../models/paginate.model';
import { GroupService, GroupServiceGroupFilter } from '../../services/group.service';
import { CourseService, CourseServiceIndex } from '../../services/course.service';
import { Course } from '../../models/course.model';
import { User } from '../../models/user/user.model';
import { UserService, UserServiceIndex, UserServiceRoleType } from '../../services/user.service';
import { MessageService, MessageServiceUsersIndex } from '../../services/message.service';
import { translate } from '@ngneat/transloco';
import { Franchise } from '../../models/franchise.model';
import { FranchiseService, FranchiseServiceCourseIndex, FranchiseServiceIndex } from '../../services/franchise.service';
import { AuthService } from '../../services/auth.service';
import { FranchiseCourse } from '../../models/franchise-course.model';

declare var bootstrap: any;

export interface FilterCourseCustomFilter {
    title: string;
    name: string;
    filter?: boolean;
    type: ('dropdown');
    value: number|string|number[]|string[]|null;
    defaultValue: number|string|number[]|string[]|null;
    options: Array<{
        label: string;
        value: string|number|null;
    }>;
};

interface Partner extends Franchise {
    imageError?: boolean;
};

@Component({
    selector: 'filter-course',
    templateUrl: './filter-course.component.html',
    styleUrls: ['./filter-course.component.scss']
})
export class FilterCourseComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('sidebar') sidebar?: ElementRef<HTMLDivElement>;

    protected readonly PAGINATION_LIMIT: number = 1000;
    protected readonly CACHING_MAX_TIME: number = environment.caching.default;
    protected readonly LOADING_TIMEOUT: number = 250;

    protected subscriptions: Subscription[] = [];
    protected innerLoadingQueue: number = 0;
    protected loading: boolean = false;
    protected loadingTimer: any = null;
    protected innerUsers?: Paginate<User>|null;
    protected innerShowSidebar: boolean = false;
    protected innerAbove: FilterCourseCustomFilter[] = [];
    protected innerBelow: FilterCourseCustomFilter[] = [];

    partners?: Paginate<Partner>;
    partnersFilter: FranchiseServiceIndex = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        sort: [{
            direction: 'asc',
            field: 'name'
        }, {
            direction: 'desc',
            field: 'updated_at'
        }]
    };
    selectedPartner: Franchise|null = null;

    courses?: Paginate<Course>;
    coursesFranchiseFilter: FranchiseServiceCourseIndex = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        sort: [{
            direction: 'asc',
            field: 'title'
        }]
    };

    coursesFilter: CourseServiceIndex = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        groups_availability: 'available',
        sort: [{
            direction: 'asc',
            field: 'title'
        }, {
            direction: 'desc',
            field: 'updated'
        }]
    };
    selectedCourse: Course|null = null;

    groups?: Paginate<Group>;
    groupsFilter: GroupServiceGroupFilter = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        sort: [{
            field: 'start_date', direction: 'desc',
        }, {
            field: 'end_date', direction: 'asc',
        }],
        include: 'students',
    };
    selectedGroup: Group|null = null;

    users?: Paginate<User>;
    usersMessagesFilter: MessageServiceUsersIndex = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        support: false,
        sort: [{direction:'asc', field: 'type'}, {direction: 'asc', field: 'name'}],
    };
    usersFilter: UserServiceIndex = {
        page: 1,
        limit: this.PAGINATION_LIMIT,
        sort: [{direction:'asc', field: 'type'}, {direction: 'asc', field: 'name'}],
    }
    selectedUser: User|null = null;
    selectedUsers: User[] = [];
    search: string|null = null;

    hasSelectedAnyFilter: boolean = false;

    currentUser: User|null = null;

    @Output() onPartner: EventEmitter<Franchise|null> = new EventEmitter;
    @Output() onCourse: EventEmitter<Course|null> = new EventEmitter;
    @Output() onGroup: EventEmitter<Group|null> = new EventEmitter;
    @Output() onUser: EventEmitter<User|null> = new EventEmitter;
    @Output() onCourseFilterCustom: EventEmitter<{originalEvent?: any, value: any, name: string}> = new EventEmitter;
    @Output() onDeselection: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onFilterClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onFilterApplied: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();

    @Input() withPartners: boolean = false;
    @Input() withAdministration: boolean = false;
    @Input() forMessages: boolean = false;
    @Input() onlyRole: UserServiceRoleType | undefined = 'student';
    @Input() withInstallments: boolean = false;

    @Input() partner: number|null = null;
    @Input() course: number|null = null;
    @Input() group: number|null = null;
    @Input() user: number|null = null;

    @Output() showSidebarChange: EventEmitter<boolean> = new EventEmitter();

    @Input()
    set above(val: FilterCourseCustomFilter[]) {
        if (val !== this.innerAbove || JSON.stringify(val ?? []) !== JSON.stringify(this.innerAbove ?? [])) {
            this.innerAbove = val;
            this.computeHasSelectedAnyFilter();
        }
    }
    get above(): FilterCourseCustomFilter[] {
        return this.innerAbove;
    }

    @Input()
    set below(val: FilterCourseCustomFilter[]) {
        if (val !== this.innerBelow || JSON.stringify(val ?? []) !== JSON.stringify(this.innerAbove ?? [])) {
            this.innerBelow = val;
            this.computeHasSelectedAnyFilter();
        }
    }
    get below(): FilterCourseCustomFilter[] {
        return this.innerBelow;
    }

    @Input()
    set showSidebar(val: boolean) {
        if (this.innerShowSidebar !== val) {
            this.innerShowSidebar = val;
            const bsOffcanvas = new bootstrap.Offcanvas(this.sidebar?.nativeElement);
            bsOffcanvas && bsOffcanvas[val ? 'show' : 'hide']();
        }
    }
    get showSidebar(): boolean {
        return this.innerShowSidebar;
    }

    @Input('users')
    set externalUsers(users: Paginate<User>|null) {
        if (this.innerUsers !== users) {
            this.innerUsers = users;
            this.usersMessagesFilter.page = 1;
            this.users = users || new Paginate<User>();
            this.groups?.reset();
            this.selectedGroup = null;
            this.selectedCourse = null;
            this.selectedUser = null;
        }
    }
    get externalUsers(): Paginate<User>|null {
        return this.innerUsers || null;
    }

    @Input() level: ('course-group-user'|'course-group'|'course'| 'none') = 'course-group-user';
    @Input() mode: ('classic'|'searchable'|'sidebar') = 'searchable';
    @Input() compact: boolean = true;

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;
            this.loadingTimer && clearTimeout(this.loadingTimer);

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
                setTimeout(() => {
                    this.loadingChange.emit(false);
                });
                return;
            }


            this.loadingTimer = setTimeout(() => {
                this.loading = this.innerLoadingQueue > 0;

                setTimeout(() => {
                    this.loadingChange.emit(this.loading);
                });
            }, this.LOADING_TIMEOUT);
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        protected authService: AuthService,
        protected franchiseService: FranchiseService,
        protected groupService: GroupService,
        protected courseService: CourseService,
        protected userService: UserService,
        protected messageService: MessageService,
    ) { }

    ngOnInit(): void {
        this.currentUser = this.authService?.getUser();

        if (this.currentUser?.can(['admin']) && this.withPartners) {
            this.getPartners();
        } else {
            this.getCourses();
        }

        if (this.withInstallments) {
            this.groupsFilter.include = ['students','enrollments','installments'];
        }
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());

        if (this.mode === 'sidebar') {
            const bsOffcanvas = this.sidebar?.nativeElement;
            bsOffcanvas && bsOffcanvas?.removeEventListener('shown.bs.offcanvas', this.toggleSidebar.bind(this, true));
            bsOffcanvas && bsOffcanvas?.removeEventListener('hidden.bs.offcanvas', this.toggleSidebar.bind(this, false));
        }

        this.loadingTimer && clearTimeout(this.loadingTimer);
    }

    ngAfterViewInit(): void {
        if (this.mode === 'sidebar') {
            const bsOffcanvas = this.sidebar?.nativeElement;
            bsOffcanvas && bsOffcanvas?.addEventListener('shown.bs.offcanvas', this.toggleSidebar.bind(this, true));
            bsOffcanvas && bsOffcanvas?.addEventListener('hidden.bs.offcanvas', this.toggleSidebar.bind(this, false));
        }

        this.computeHasSelectedAnyFilter();
    }

    toggleSidebar(visible: boolean = true): void {
        this.showSidebarChange.emit(visible);
    }

    protected getPartners(appendData: boolean = false): void {
        if (appendData && this.partners?.data?.length) {
            if (this.partners?.reachEnd()) {
                return;
            }
            this.partnersFilter.page = this.partners?.nextPage();
        } else if (!this.partners?.data?.length) {
            this.partnersFilter.page = 1;
        }

        this.loadingQueue++;
        const subscription = this.franchiseService
            .getList(this.partnersFilter, this.CACHING_MAX_TIME)
            .subscribe({
                next: data => {

                    this.loadingQueue--;

                    if (!data.data) {
                        return;
                    }

                    if (appendData && this.partners?.data?.length) {
                        data.data = [
                            ...this.partners?.data ?? [],
                            ...data.data,
                        ];
                    } else {
                        this.groups?.reset();
                    }

                    this.partners = data;

                    this.mode !=='classic' && this.partners?.prepend(Franchise.fromJson({
                        id: null,
                        logo: '',
                        name: '< ' + translate('Моля, изберете партньор') + ' >',
                    }));

                    if (this.partner && !this.selectedPartner) {
                        this.onPartnerToggle(null, this.partners?.data?.find(item => item.id === this.partner) || null);
                    } else {
                        this.getCourses();
                    }
                },
                error: error => {
                    this.loadingQueue--;
                }
            });
        this.subscriptions.push(subscription);
    }

    onScrollPartners(event?: any): void {
        this.getPartners(true);
    }

    onChangePartner(event?: {originalEvent?: Event, value: number}): void {
        this.onPartnerToggle(event?.originalEvent as Event, this.partners?.data?.find(item => item.id === event?.value) ?? null);
    }

    onPartnerToggle(event: Event|null, franchise: Franchise|null): boolean {
        event?.preventDefault();
        this.selectedPartner = this.selectedPartner?.id === franchise?.id ? null : franchise;
        this.groupsFilter.franchise = this.selectedPartner?.id ?? null;

        this.selectedCourse = null;
        this.selectedGroup = null;
        this.selectedUser = null;
        this.courses?.reset();
        this.groups?.reset();
        this.users?.reset();

        this.getCourses();

        this.onPartner.emit(this.selectedPartner);
        this.onDeselection.emit(this.selectedPartner ? false : true);

        this.computeHasSelectedAnyFilter();

        return false;
    }

    protected getCourses(appendData: boolean = false): void {
        const isFranchiseCourses: boolean = this.withPartners && (this.currentUser?.can(['admin']) ?? false) && (this.selectedPartner?.id ?? 0) > 0;

        if (appendData && this.courses?.data?.length) {
            if (this.courses?.reachEnd()) {
                return;
            }
            const nextPage = this.courses?.nextPage();

            this.coursesFilter.page = nextPage;
            this.coursesFranchiseFilter.page = nextPage;

        } else if (!this.courses?.data?.length) {
            this.coursesFilter.page = 1;
            this.coursesFranchiseFilter.page = 1;
        }

        const action: Observable<Paginate<Course>|Paginate<FranchiseCourse>> = isFranchiseCourses
            ? this.franchiseService.getCoursesList(this.selectedPartner?.id ?? 0, this.coursesFranchiseFilter, this.CACHING_MAX_TIME)
            : this.courseService.getList(this.coursesFilter, this.CACHING_MAX_TIME)
        ;

        this.loadingQueue++;
        const subscription = action.subscribe({
            next: response => {

                this.loadingQueue--;

                if (!response?.data) {
                    return;
                }

                if (appendData && this.courses?.data?.length) {
                    if (isFranchiseCourses) {
                        response.data = [
                            ...(this.courses?.data ?? []),
                            ...(response.data?.map((course: FranchiseCourse) => course.course) ?? [])
                        ] as FranchiseCourse[];
                    } else {
                        response.data = [
                            ...(this.courses?.data ?? []),
                            ...(response.data ?? [])
                        ];
                    }
                } else {
                    if (this.withAdministration) {
                        response.prepend(Course.fromJson({
                            id: 0,
                            title: translate('Администрация'),
                        }))
                    }

                    this.groups?.reset();

                    if (isFranchiseCourses) {
                        response.data = response?.data?.map((course: FranchiseCourse) => course.course) as FranchiseCourse[];
                    }
                }

                this.courses = response;

                this.mode !=='classic' && this.courses?.prepend(Course.fromJson({
                    id: null,
                    logo: '',
                    title: '< ' + translate('Моля, изберете курс') + ' >',
                }));

                if (this.course && !this.selectedCourse) {
                    this.onCourseToggle(null, this.courses?.data?.find(item => item.id === this.course) || null);
                }
            },
            error: error => {
                this.loadingQueue--;
            }
        });
        this.subscriptions.push(subscription);
    }

    onScrollCourses(event?: any): void {
        this.getCourses(true);
    }

    onChangeCourse(event?: {originalEvent?: Event, value: number}): void {
        this.onCourseToggle(event?.originalEvent as Event, this.courses?.data?.find(item => item.id === event?.value) ?? null);
    }

    onCourseToggle(event: Event|null, course: Course|null): boolean {
        event?.preventDefault();
        this.selectedCourse = this.selectedCourse?.id === course?.id ? null : course;

        if (this.level !== 'course') {
            this.selectedGroup = null;
            this.selectedUser = null;
            this.groups?.reset();
            this.users?.reset();

            if (this.selectedCourse?.id) {
                this.getGroups();
            } else if (this.withAdministration && this.level === 'course-group-user') {
                this.getUsers(false, true);
            }
        }

        this.onCourse.emit(this.selectedCourse);
        this.onDeselection.emit(this.selectedCourse ? false : true);

        this.computeHasSelectedAnyFilter();

        return false;
    }

    protected getGroups(appendData: boolean = false): void {
        if (appendData && this.groups?.data?.length) {
            if (this.groups?.reachEnd()) {
                return;
            }
            this.groupsFilter.page = this.groups?.nextPage();
        } else if (!this.groups?.data?.length) {
            this.groupsFilter.page = 1;
        }

        this.groupsFilter.course = this.selectedCourse?.id;

        if (this.selectedPartner?.id) {
            this.groupsFilter.franchise = this.selectedPartner?.id;
        }

        this.loadingQueue++;
        const subscription = this.groupService
            .getList(this.groupsFilter, this.CACHING_MAX_TIME)
            .subscribe({
                next: data => {

                    this.loadingQueue--;

                    if (!data.data) {
                        return;
                    }

                    if (appendData && this.groups?.data?.length) {
                        data.data = [
                            ...this.groups?.data ?? [],
                            ...data.data,
                        ];
                    } else {
                        this.selectedUser = null;
                        this.users?.reset();
                    }
                    this.groups = data;

                    this.mode !=='classic' && this.groups.prepend(Group.fromJson({
                        id: null,
                        title: '< ' + translate('Моля, изберете група') + ' >',
                    }));

                    if (this.group && !this.selectedGroup) {
                        this.onGroupToggle(null, this.groups?.data?.find(item => item.id === this.group) || null);
                    }
                },
                error: error => {
                    this.loadingQueue--;
                }
            });
        this.subscriptions.push(subscription);;
    }

    onScrollGroups(event?: any): void {
        this.getGroups(true);
    }

    onChangeGroup(event?: {originalEvent?: Event, value: number}): void {
        this.onGroupToggle(event?.originalEvent as Event, this.groups?.data?.find(item => item.id === event?.value) ?? null);
    }

    onGroupToggle(event: Event|null, group: Group|null): boolean {
        event?.preventDefault();
        this.selectedGroup = this.selectedGroup?.id === group?.id ? null : group;

        if (this.level === 'course-group-user') {
            this.selectedUser = null;
            this.users?.reset();

            if (this.selectedGroup?.id) {
                this.getUsers(false);
            }
        }

        this.onGroup.emit(this.selectedGroup);
        this.onDeselection.emit(this.selectedGroup ? false : true);

        this.computeHasSelectedAnyFilter();

        return false;
    }

    protected getUsers(appendData: boolean = false, support: boolean = false): void {
        if (this.forMessages) {
            this.getMessageUsers(appendData, support);
        } else {
            this.getGloballyUsers(appendData);
        }
    }

    onChangeCustomFilter(event: {originalEvent?: Event|MouseEvent, value: any}, name?: string): void {
        this.onCourseFilterCustom.emit({
            originalEvent: event?.originalEvent ?? null,
            value: event?.value,
            name: name ?? '',
        });

        this.computeHasSelectedAnyFilter();
    }

    protected getMessageUsers(appendData: boolean = false, support: boolean = false): void {
        if (appendData && this.users?.data?.length) {
            if (this.users?.reachEnd()) {
                return;
            }
            this.usersMessagesFilter.page = this.users?.nextPage();
        } else if (!this.users?.data?.length) {
            this.usersMessagesFilter.page = 1;
        }

        this.usersMessagesFilter.group = this.selectedGroup?.id;
        this.usersMessagesFilter.support = support;

        this.loadingQueue++;

        const subscription = this.messageService
            .getUsers(this.usersMessagesFilter)
            .subscribe({
                next: data => {
                    this.loadingQueue--;

                    if (!data.data) {
                        return;
                    }

                    if (appendData && this.users?.data?.length) {
                        data.data = [
                            ...this.users?.data ?? [],
                            ...data.data,
                        ];
                    }
                    this.users = data;

                    if (this.user && !this.selectedUser) {
                        this.onUserToggle(null, this.users?.data?.find(item => item.id === this.user) || null);
                    }
                },
                error: error => {
                    this.loadingQueue--;
                }
            });

        this.subscriptions.push(subscription);
    }

    protected getGloballyUsers(appendData: boolean = false): void {
        if (appendData && this.users?.data?.length) {
            if (this.users?.reachEnd()) {
                return;
            }
            this.usersFilter.page = this.users?.nextPage();
        } else if (!this.users?.data?.length) {
            this.usersFilter.page = 1;
        }

        this.usersFilter.group = this.selectedGroup?.id;
        this.usersFilter.role = this.onlyRole;

        if (this.selectedPartner?.id) {
            this.usersFilter.franchise = this.selectedPartner?.id;
        }

        this.loadingQueue++;

        const subscription = this.userService
            .getList(this.usersFilter, this.CACHING_MAX_TIME)
            .subscribe({
                next: data => {
                    this.loadingQueue--;

                    if (!data.data) {
                        return;
                    }

                    if (appendData && this.users?.data?.length) {
                        data.data = [
                            ...this.users?.data ?? [],
                            ...data.data,
                        ];
                    }
                    this.users = data;

                    if (this.user && !this.selectedUser) {
                        this.onUserToggle(null, this.users?.data?.find(item => item.id === this.user) || null);
                    }

                    this.mode !=='classic' && this.users.prepend(User.fromJson({
                        id: null,
                        image: null,
                        name: '< ' + translate('Моля, изберете курсист') + ' >'
                    }));

                    this.users.data?.map((item: User & {fullName?: string, searchBy?: string}) => {
                        item.fullName = item.getFullName();
                        item.searchBy = item.fullName + ' ' + item?.email;
                    });
                },
                error: error => {
                    this.loadingQueue--;
                }
            });
        this.subscriptions.push(subscription);
    }

    onScrollUsers(event?: any): void {
        this.getUsers(true);
    }

    onChangeUser(event?: {originalEvent?: Event, value: number}): void {
        this.onUserToggle(event?.originalEvent as Event, this.users?.data?.find(item => item.id === event?.value) ?? null);
    }

    onUserToggle(event: Event|null, user: User|null): boolean {
        event?.preventDefault();
        this.selectedUser = this.selectedUser?.id === user?.id ? null : user;

        this.onUser.emit(this.selectedUser);
        this.onDeselection.emit(this.selectedUser ? false : true);

        this.computeHasSelectedAnyFilter();

        return false;
    }

    onFilterClearClicked(event?: MouseEvent|Event): void {
        this.selectedPartner = null;
        this.selectedCourse = null;
        this.selectedGroup = null;
        this.selectedUser = null;
        this.selectedUsers = [];

        this.onFilterClear.emit();

        this.computeHasSelectedAnyFilter();
    }

    computeHasSelectedAnyFilter(): void {
        setTimeout(() => {
            this.hasSelectedAnyFilter =
                   (this.selectedPartner?.id ?? 0) > 0
                || (this.selectedCourse?.id ?? 0) > 0
                || (this.selectedGroup?.id ?? 0) > 0
                || (this.selectedUser?.id ?? 0) > 0
                || this.selectedUsers?.length > 0
                || this.above?.findIndex(item => item.defaultValue !== item.value) >= 0
                || this.below?.findIndex(item => item.defaultValue !== item.value) >= 0
            ;

            this.onFilterApplied.emit(this.hasSelectedAnyFilter);
        });
    }
}
