import {
    Component,
    ContentChildren,
    QueryList,
    AfterContentInit,
    AfterViewInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    OnInit,
    Inject,
} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {TabComponent} from './../';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'tabs',
    templateUrl: 'tabs.component.html',
    styleUrls: [ 'tabs.component.scss' ],
    host: {
        '(window:resize)': 'onWindowResize($event)'
    },
})
export class TabsComponent implements AfterViewInit, AfterContentInit, OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];
    protected sendChangeTabTimer: any = null;

    protected innerUnique: string = Math.random().toString(36).substring(2, 9);
    protected innerId: string = '';
    protected currentTab: TabComponent|null = null;
    protected makeActive: string = '';
    protected passRerouting: boolean = false;
    protected windowResizeTimer: any = null;
    protected previousToggler:('tab'|'accordion') = 'tab';

    @Input('fullWidth') fullWidth: boolean = false;
    @Input('fallbackToAccordion') fallbackToAccordion: boolean = true;
    @Input('paramName') paramName: string = 'tab';
    @Input('paramType') type: ('index'|'label'|'id') = 'label';
    @Input() variant: ('default'|'orange') = 'default';
    @Input() hideToolbar: boolean = false;
    @Input()
    set active(value: string|null) {
        if (this.makeActive !== value) {
            this.makeActive = value && value.length
                ? value
                : this.makeActive;

            const findTab = this.findTab(this.makeActive);
            if (findTab.id && findTab.id !== this.currentTab?.id) {
                this.selectedTabIndex = this.tabs?.toArray().findIndex(item => item.id === findTab.id) ?? 0;
                this.selectTab(findTab, true);

                this.showTabContents = false;
                setTimeout(() => {
                    this.showTabContents = true;
                }, 10);
            }
        }
    }
    get active() {
        return this.makeActive;
    }

    set unique(value) { } // disable set the unique string
    get unique() {
        return [this.id, this.innerUnique].filter(item => item).join('_');
    }

    @Input()  // unique snake-case name of the tab, used for URLs and comparisons
    set id(value: string) {
        if (this.innerId !== value) {
            this.innerId = value;
        }
    }
    get id(): string {
        return this.innerId;
    }

    @Output() tabChange: EventEmitter<{tab?: TabComponent, index: number}> = new EventEmitter();
    @ContentChildren(TabComponent, {descendants: true}) tabs?: QueryList<TabComponent>;

    routeSubscribe: any = null;
    paramsSubscribe: any = null;
    selectedTabIndex: number = 0;
    toggler: ('tab'|'accordion') = 'tab';
    showTabContents: boolean = true;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Inject(DOCUMENT) private document: any,
    ) {
        this.paramsSubscribe = this.route.params.subscribe(params => {
            let found: TabComponent|null = null;
            this.makeActive = params[this.paramName] || this.makeActive;

            if (this.makeActive
                && this.makeActive.length
                && (found = this.findTab(this.makeActive))
            ) {
                this.selectTab(found);
            }

            this.paramsSubscribe && this.paramsSubscribe.unsubscribe();
        });

        this.routeSubscribe = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd|any) => {
                let currentRoute = this.route.root;
                while (currentRoute.children[0] !== undefined) {
                    currentRoute = currentRoute.children[0];
                }

                let tab: string = currentRoute.snapshot.params[this.paramName] || '';
                if (!this.passRerouting && tab && typeof tab === 'string') {
                    let foundTab = this.findTab(tab);
                    foundTab && this.selectTab(foundTab);
                }

                this.passRerouting = false;
            });
    }

    ngOnInit(): void {

    }

    ngOnDestroy() {
        this.routeSubscribe && this.routeSubscribe.unsubscribe();
        this.paramsSubscribe && this.paramsSubscribe.unsubscribe();
        this.subscriptions?.map(item => item && item.unsubscribe());

        this.sendChangeTabTimer && clearTimeout(this.sendChangeTabTimer);
        this.windowResizeTimer && clearTimeout(this.windowResizeTimer);
    }

    ngAfterContentInit() {
        let activeTabs = this.tabs?.filter(tab => tab.active);

        if (activeTabs?.length === 0) {
            let findTab = this.findTab(this.makeActive);
            if (!findTab.id) {
                findTab = this.tabs?.first ?? findTab;
            }
            this.selectedTabIndex = this.tabs?.toArray().findIndex(item => item.id === findTab.id) ?? 0;
            this.selectTab(findTab);
        }
    }

    ngAfterViewInit() {
        const subscription = this.route.queryParams.subscribe((data: any) => {
            if (this.paramName in data) {
                const value = data[this.paramName];
                let tabIndex: number = this.type === 'index'
                    ? (Number(value) || 0)
                    : this.tabs?.toArray().findIndex(tab => tab[this.type === 'label' ? 'label' : 'id'] === value) ?? 0
                tabIndex = tabIndex < 0 ? 0 : tabIndex;

                this.selectedTabIndex = tabIndex;
                this.selectTab(this.findTabByIndex(this.selectedTabIndex));
            }
        });
        this.subscriptions.push(subscription);

        this.toggler = typeof window !== 'undefined' && window.innerWidth < 992 && this.fallbackToAccordion ? 'accordion' : 'tab';
    }

    selectTab(tab: TabComponent, redirect: boolean = false) {
        if (this.currentTab && tab?.unique === this.currentTab.unique) {
            return;
        }

        this.tabs?.toArray()?.forEach(tab => tab.active = false);
        tab.active = true;

        this.currentTab = tab ?? null;

        this.sendChangeTabTimer && clearTimeout(this.sendChangeTabTimer);
        this.sendChangeTabTimer = setTimeout(() => {
            this.tabChange.emit({tab, index: this.selectedTabIndex});

            tab.tabChange.emit(tab);
        }, 50);

        redirect && setTimeout(() => {
            const tabValue: string|number = this.type === 'index' ? this.selectedTabIndex
                : this.type === 'label' ? (tab?.label ?? '')
                : this.type === 'id' ? (tab?.id ?? '')
                : 0;

            this.router.navigate([], {
                queryParams: {
                    ...this.route?.snapshot?.queryParams,
                    [this.paramName]: tabValue,
                },
                state: {
                    skipMoveTop: true,
                },
                replaceUrl: true,
            });
        }, 10);
    }

    protected findTab(id: string): TabComponent {
        if (!this.tabs) {
            return new TabComponent;
        }

        let found = this.tabs?.filter(tab => tab.id === id);
        return found.length ? found[0] : new TabComponent;
    }

    protected findTabByIndex(index: number): TabComponent {
        if (!this.tabs) {
            return new TabComponent;
        }

        return index >= 0 && index <= (this.tabs || [])?.length
            ? (this.tabs?.get(index) ?? new TabComponent)
            : new TabComponent;
    }

    onTabSelectChanged(index: number): void {
        const tab = this.findTabByIndex(index);
        this.selectedTabIndex = index;
        this.selectTab(tab, true);
    }

    onAccordionToggle(event: any, toggle: ('show'|'hide')): void {

        if (toggle === 'show') {
            const index: number = Number(event.target.closest('.accordion-item')?.getAttribute('data-index') || 0) || 0;
            this.selectedTabIndex = index;
            this.selectTab(this.findTabByIndex(index), true);
        }

        this.toggler = 'tab';
        this.fallbackToAccordion && setTimeout(() => {
            this.toggler = 'accordion';
        });
    }

    onWindowResize(event?: any): void {
        const focusElement: Element|null = this.document.activeElement;

        this.windowResizeTimer && clearTimeout(this.windowResizeTimer);
        this.windowResizeTimer = setTimeout(() => {
            this.toggler = window?.innerWidth < 992 && this.fallbackToAccordion ? 'accordion' : 'tab';



            // Hack: Refresh material tab group as soon as it should be visible ( >= 992px screens)
            // in order to re-rendering tab contents
            if (this.previousToggler !== this.toggler) {
                this.showTabContents = false;

                setTimeout(() => {
                    this.showTabContents = true;

                    if (!this.document.activeElement?.isEqualNode(focusElement) && focusElement) {
                        setTimeout(() => {
                            (focusElement as HTMLElement)?.focus();
                        });
                    }
                }, 10);
            }

            this.previousToggler = this.toggler;
        }, 15);
    }
}
