import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user/user.model';

@Pipe({
    name: 'userName'
})
export class UserNamePipe implements PipeTransform {
    transform(user: any|User): string {
        const theUser: User = user instanceof User ? user
            : Object.assign(new User, user);

        return theUser.getFullName();
    }
}
