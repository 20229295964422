import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { User } from '../models/user/user.model';
import { ApiService } from './api.service';
import { FilterSort } from '../models/filter-sort.model';
import { PreferenceData } from '../models/preference.model';
import { UserTerm } from '../models/user/user-term.model';
import { NotificationCount } from '../models/notification-count.model';

export type PaymentServiceIndexSortType = (
    | 'id'
    | 'price'
    | 'date'
    | 'status'
    | 'updated'
);

export interface UserServiceInputTerms {
    name: string;
    groups: number[];
};

export interface ProfileServiceListFilter  {
    limit?: number,
    page?: number,
    sort?: PaymentServiceIndexSortType|FilterSort<PaymentServiceIndexSortType>[],
    expiration?: string,
    status?: string,
    include?: string,
};

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(
        protected api: ApiService,
    ) { }

    getProfile(): Observable<{data: User}> {
        return this.api.get<{data: User}>("/profile").pipe(
            map(data => {
                data.data = User.fromJson(data?.data);
                return data;
            })
        );
    }


    updateProfile({
        name = undefined,
        lastname = undefined,
        image = undefined,
        phone = undefined,
        birthday = undefined,
        town = undefined,
        facebook = undefined,
        tiktok = undefined,
        github = undefined,
        linkedin = undefined,
        website = undefined,
        education = undefined,
        experience = undefined,
        languages = undefined,
        description = undefined,
        school = undefined,
        grade = undefined,
        medical_notes = undefined,
        parent_name = undefined,
        parent_phone = undefined,
        parent_email = undefined,
        password = undefined,
        repassword = undefined,
        password_current = undefined,
    }): Observable<{data: User}> {
        return this.api.post<{data: User}>('/profile', {
            name,
            lastname,
            image,
            phone,
            birthday,
            town,
            facebook,
            tiktok,
            github,
            linkedin,
            website,
            education,
            experience,
            languages,
            description,
            school,
            grade,
            medical_notes,
            parent_name,
            parent_phone,
            parent_email,
            password,
            repassword,
            password_current,
        })
    }

    getPreferences(): Observable<{data: PreferenceData}> {
        return this.api.get<{data: PreferenceData}>('/profile/preferences').pipe(
            map(data => {
                return data
            })
        )
    }

    setPreferences(preferences: PreferenceData): Observable<PreferenceData> {
        return this.api.post('/profile/preferences', preferences).pipe(
            map(data => {
                data.data = PreferenceData.fromJson(data?.data)
                return data
            })
        )
    }

    /**
     * Get course (group) terms and conditions for acceptance by the student
     * @returns
     */
    getTerms(): Observable<{data: UserTerm[]}> {
        return this.api.get('/profile/terms').pipe(
            map(response => {
                response.data = response?.data?.map((item: any) => UserTerm.fromJson(item))
                return response
            })
        );
    }

    /**
     * Set course (group) terms and conditions acceptance status by the student
     * @param data
     * @returns
     */
    setTerms(data: UserServiceInputTerms): Observable<{data: UserTerm[]}> {
        return this.api.post('/profile/terms', data).pipe(
            map(response => {
                response.data = response?.data?.map((item: any) => UserTerm.fromJson(item))
                return response
            })
        );
    }

    /**
     * Get the current user's number of notifications.
     * @returns
     */
    getNotificationsCount(): Observable<{data: NotificationCount}>{
        return this.api.get('/profile/notifications').pipe(
            map(response => {
                response.data = NotificationCount.fromJson(response.data);
                return response
            })
        );
    }

}
