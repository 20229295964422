import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Teacher } from '../../models/teacher.model';
import { User } from '../../models/user/user.model';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'card-teacher',
    templateUrl: './card-teacher.component.html',
    styleUrls: ['./card-teacher.component.scss']
})
export class CardTeacherComponent implements OnInit {
    protected innerUser: User | null = new User();

    visible: boolean = false;
    user: User = new User;

    @Input() userRole: string = '';
    @Input() teachers: any[] = [];
    @Input() innerDialogContent: boolean = false;
    @Input() selectedTeachers: number[] = [];

    @Output() teachersAdded: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() teachersRemoved: EventEmitter<number[]> = new EventEmitter<number[]>();

    constructor(
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.user = this.authService?.getUser() ?? this.user;
    }

    showDialog() {
        this.visible = true;
    }

    showTeacherInfoModal(teacher: Teacher): void {
        if (teacher.show) {
            teacher.show = false;
        } else {
            teacher.show = true;
        }
    }

    toggleTeacher(teacher: Teacher): void {
        if (this.isSelectedTeacher(teacher)) {
            this.removeTeacher(teacher);
        } else {
            this.addTeacher(teacher);
        }
    }


    isSelectedTeacher(teacher: Teacher): boolean {
        return this.selectedTeachers.includes(teacher.id as number);
    }

    protected removeTeacher(teacher: Teacher): void {

        if (teacher && teacher.id) {
            const index = this.selectedTeachers.indexOf(teacher.id);
            if (index !== -1) {
                this.selectedTeachers.splice(index, 1);
                this.selectedTeachers = this.selectedTeachers.slice();
                this.teachersRemoved.emit(this.selectedTeachers);
            }
        }
    }

    protected addTeacher(teacher?: Teacher) {
        if (teacher && teacher.id) {
            this.selectedTeachers.push(teacher.id);
            this.teachersAdded.emit(this.selectedTeachers);
        }
    }

}
