import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { Certificate } from '../models/certificate/certificate.model';
import { FilterSort } from '../models/filter-sort.model';
import { Paginate } from '../models/paginate.model';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

export type CertificateServiceIndexIncludeType = (
    'type'
    | 'type.parent'
    | 'createdUser'
    | 'updatedUser'
);

export type CertificateServiceIndexSortType = (
    'id'
    | 'title'
    | 'image'
    | 'status'
    | 'slug'
    | 'views'
    | 'date'
    | 'updated_at'
    | 'created_at'
);
export interface CertificateServiceIndex {
    limit?: number;
    page?: number;
    sort?: CertificateServiceIndexSortType | FilterSort<CertificateServiceIndexSortType>[];
    q?: string;
    include?: string | CertificateServiceIndexIncludeType[];
    franchise?: number;
    course?: number;
    group?: number;
    user?: number;
};

export interface CertificateServiceInput {
    user: number;
    group: number;
};

@Injectable({
    providedIn: 'root',
})
export class CertificatesService {

    constructor(
        protected api: ApiService,
        protected authService: AuthService,
    ) { }

    /**
     * Get List of certificates
     *
     * @param filter
     * @returns
     */
    getItems(filter?: CertificateServiceIndex): Observable<Paginate<Certificate>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CertificateServiceIndexSortType);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));

        return this.api.get('/certificates', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<Certificate>(), data);
                data.data = data.data?.map((item: any) => Certificate.fromJson(item));
                return data;
            })
        )
    }

    /**
     * Get certificate by his UUID address
     * @param uuid
     * @returns
     */
    getItemByUuid(uuid: string): Observable<{data: Certificate}> {
        return this.api.get('/certificates/uuid/' + uuid).pipe(
            map(response => {
                response.data = Certificate.fromJson(response?.data);
                return response;
            })
        );
    }

    /**
     * Get certificate by ID
     * @param id
     * @returns
     */
    getItem(id: number): Observable<{data: Certificate}> {
        return this.api.get('/certificates/' + id).pipe(
            map(response => {
                response.data = Certificate.fromJson(response?.data);
                return response;
            })
        );
    }

    /**
     * Generate a new certificate
     * @param data
     * @returns
     */
    add(data: CertificateServiceInput): Observable<{data: Certificate}> {
        return this.api.post('/certificates', data).pipe(
            map(response => {
                response.data = Certificate.fromJson(response?.data);
                return response;
            })
        );
    }

    /**
     * Update an existing certificate by ID
     * @param id
     * @returns
     */
    update(id: number): Observable<{data: Certificate}> {
        return this.api.post('/certificates/' + id).pipe(
            map(response => {
                response.data = Certificate.fromJson(response?.data);
                return response;
            })
        );
    }

    /**
     * Delete a certificate by ID
     * @param id
     * @returns
     */
    delete(id: number): Observable<{data: any}>{
        return this.api.delete('/certificates/' + id);
    }
}
