import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Franchise } from 'src/app/shared/models/franchise.model';
import { User } from 'src/app/shared/models/user/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FranchiseService } from 'src/app/shared/services/franchise.service';

@Component({
    selector: 'sidebar-cv',
    templateUrl: './cv.component.html',
    styleUrls: ['./cv.component.scss']
})
export class SidebarCvComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    @Input() hide: boolean = false;

    user = new User();
    company: Franchise|null = null;

    constructor(
        private authService: AuthService,
        private franchiseService: FranchiseService
    ) {
        this.user = this.authService.getUser() ?? this.user;

        const subscription = this.franchiseService.getCompanyDetails().subscribe(data => {
            this.company = data
            this.hide = !!this.company?.isPreference('mainmenu.hide.careers', true);
        });
        this.subscriptions.push(subscription);

    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }
}
