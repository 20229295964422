import { FormPartnerCustomizationComponentColorName }  from './form-partner-customization.component';

export const themeDefault: {[name in FormPartnerCustomizationComponentColorName]: string} = {
    themeColorPrimary: '#3C4B66',
    themeColorPrimaryDark: '#263146',
    themeColorPrimaryLight: '#257cc0',

    themeColorSecondary: '#f8c300',
    themeColorSecondaryDark: '#F8C300',
    themeColorSecondaryLight: '#ffd53e',

    themeColorThird: '#F87300',
    themeColorThirdDark: '#F87300',
    themeColorThirdLight: '#F87300',

    themeColorText: '#000',
    themeColorTextDark: '#3C4B66',
    themeColorTextLight: '#f8f9fa',
    themeColorTextInvert: '#fff',

    themeColorLink: '#0d6efd',
    themeColorLinkHover: '#0a58ca',
    themeColorLinkActive: '#0a58ca',

    themeColorSidebar: '#ebedee',
    themeColorSidebarActive: '#f9f9f9',
    themeColorSidebarText: '#3C4B66',
    themeColorSidebarTextHover: '#3C4B66',
    themeColorSidebarTextActive: '#3C4B66',

    themeColorBtnPrimary: '#3C4B66',
    themeColorBtnPrimaryHover: '#334057',
    themeColorBtnPrimaryActive: '#303c52',
    themeColorBtnPrimaryText: '#fff',
    themeColorBtnSecondary: '#6c757d',
    themeColorBtnSecondaryHover: '#5c636a',
    themeColorBtnSecondaryActive: '#565e64',
    themeColorBtnSecondaryText: '#fff',
    themeColorBtnSignup: '#f8c300',
    themeColorBtnSignupHover: '#ffce1c',
    themeColorBtnSignupActive: '#ffce1c',
    themeColorBtnSignupText: '#003F63',
};


export const themeLight: {[name in FormPartnerCustomizationComponentColorName]: string} = {
    themeColorPrimary: '#f8fcff',
    themeColorPrimaryDark: '#efefef',
    themeColorPrimaryLight: '#f4faff',

    themeColorSecondary: '#fa5000',
    themeColorSecondaryDark: '#fa3d00',
    themeColorSecondaryLight: '#ff6015',

    themeColorThird: '#4b6e85',
    themeColorThirdDark: '#4b6e85',
    themeColorThirdLight: '#4b6e85',

    themeColorText: '#455a64',
    themeColorTextDark: '#3C4B66',
    themeColorTextLight: '#f8f9fa',
    themeColorTextInvert: '#000',

    themeColorLink: '#0d6efd',
    themeColorLinkHover: '#0a58ca',
    themeColorLinkActive: '#0a58ca',

    themeColorSidebar: '#f5f5f5',
    themeColorSidebarActive: '#fff',
    themeColorSidebarText: '#444',
    themeColorSidebarTextHover: '#606060',
    themeColorSidebarTextActive: '#fa001f',

    themeColorBtnPrimary: '#4677a5',
    themeColorBtnPrimaryHover: '#3c6f9f',
    themeColorBtnPrimaryActive: '#5a8dbc',
    themeColorBtnPrimaryText: '#fff',
    themeColorBtnSecondary: '#9e9e9e',
    themeColorBtnSecondaryHover: '#546e7a',
    themeColorBtnSecondaryActive: '#78909c',
    themeColorBtnSecondaryText: '#fff',
    themeColorBtnSignup: '#f8c300',
    themeColorBtnSignupHover: '#ffce1c',
    themeColorBtnSignupActive: '#ffce1c',
    themeColorBtnSignupText: '#003F63',
};
