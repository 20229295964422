import { UntypedFormControl, UntypedFormGroup, Validators, FormGroup, FormArray } from '@angular/forms';
import { Component } from '@angular/core';
import { ApiValidation } from 'src/app/shared/services/api-validation.service';
import { User } from 'src/app/shared/models/user/user.model';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { translate } from '@ngneat/transloco';
import { AuthService } from '../../services/auth.service';
import { ProfileService, UserServiceInputTerms } from '../../services/profile.service';
import { UserTerm } from '../../models/user/user-term.model';
import { Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'modal-terms',
    templateUrl: './modal-terms.component.html',
    styleUrls: ['./modal-terms.component.scss']
})
export class ModalTermsComponent {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;
    protected readonly CACHING_MAX_TIME: number = environment.caching.default;

    protected subscriptions: Subscription[] = [];
    protected innerLoadingQueue: number = 0;

    @Output() onClose: EventEmitter<boolean> = new EventEmitter();

    visible: boolean = false;
    loading: boolean = false;
    user: User|null = null;
    form: UntypedFormGroup|null = null;
    errors: any = {};
    userTerms: UserTerm[] = [];
    terms: string|null = '';
    message: string = '';
    items: Array<{title?: string, value?: number}> = [];
    item: number = 0;

    mode: ('confirm'|'submit') = 'confirm';

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    get paymentControls(): FormArray<FormGroup>|null {
        return this.form?.get('payments') as FormArray;
    }

    constructor(
        private authService: AuthService,
        private profileService: ProfileService,
        private apiValidation: ApiValidation,
        private messageService: MessageService,
    ) { }

    ngOnInit(): void {
        this.authService.getStatus().subscribe(status => {
            if (status === false) {
                this.visible = false;
            } else {
                this.user = this.authService.getUser();

                if (this.user?.can(['student'])) {
                    this.getTerms();
                }
            }
        });
    }

    protected initForm() {
        this.errors = {};
        this.form = new UntypedFormGroup({
            name: new UntypedFormControl('', [Validators.required]),
        });
    }

    onConfirm(event?: MouseEvent): void {
        event?.preventDefault();

        this.initForm();
        this.mode = 'submit';
    }

    onSubmit(event?: MouseEvent): void {
        event?.preventDefault();

        if (this.loading) {
            return;
        }


        if (this.form?.invalid) {
            this.errors = this.form?.controls;

            this.messageService.add({
                severity: 'error',
                summary: translate('Грешка!'),
                detail: translate('Моля, проверете полетата отново!'),
            });
            this.form.markAllAsTouched();
            this.paymentControls?.markAllAsTouched();

            return;
        }


        const formData: UserServiceInputTerms = {
            name: this.form?.get('name')?.value ?? '',
            groups: this.userTerms?.map(item => {
                return item?.id ?? 0;
            }) ?? [],
        };

        this.loadingQueue++;
        const subscription = this.profileService.setTerms(formData).subscribe({
            next: (data: any) => {
                this.loadingQueue--;
                this.messageService.add({
                    severity: 'success',
                    detail: translate('Успешно приети условия за ползване!'),
                });

                this.visible = false;
                this.onClose.emit(true);
            },
            error: error => {
                this.loadingQueue--;
                this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: data.message
                    });
                    this.errors = data.errors;
                });
            }
        });
        this.subscriptions.push(subscription);
    }

    protected getTerms(appendData: boolean = false): void {
        this.loadingQueue++;
        const subscription = this.profileService.getTerms().subscribe({
            next: response => {
                this.form = null;
                this.errors = {};

                this.mode = 'confirm';
                this.userTerms = response?.data ?? [];

                this.items = response?.data?.map(item => {
                    return {
                        title: [item?.course, item?.group].join(' – '),
                        value: item?.id
                    }
                });

                this.item = this.userTerms?.[0]?.id ?? 0;
                this.terms = this.userTerms?.[0]?.terms ?? '';

                this.visible = response.data?.length > 0;
                this.loadingQueue--;

                if (!this.items.length) {
                    this.onClose.emit(true);
                }
            },
            error: error => {
                this.onClose.emit(true);
                this.loadingQueue--;
            },
        });
        this.subscriptions.push(subscription);
    }

    onChangeCourse(event?: any): void {
        const id: number = event?.value ?? 0;
        this.terms = this.userTerms?.find(item => item.id === id)?.terms ?? '';
    }
}
