import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/shared/models/user/user.model';
import { Career } from 'src/app/shared/models/career.model';
import { CareerService, CareerServiceIncludeType, CareerServiceSortType } from 'src/app/shared/services/career.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiValidation } from 'src/app/shared/services/api-validation.service';
import { translate } from '@ngneat/transloco';
import { PageWrapComponentBreadcrumbItem } from '../page-wrap/page-wrap.component';

@Component({
    selector: 'page-job-details',
    templateUrl: './page-job-details.component.html',
    styleUrls: ['./page-job-details.component.scss']
})
export class PageJobDetailsComponent implements OnInit {
    protected subscriptions: Subscription[] = [];
    applyForm: UntypedFormGroup;

    user: User = new User;
    loading: boolean = true;
    career: Career = new Career();

    @Input() navigation: PageWrapComponentBreadcrumbItem[] = [];
    @Input('jobId') id: number|null = null;
    @Input() jobsListUrl: string = '/careers/jobs';

    includes: CareerServiceIncludeType[] = ['company','town','employments','works','franchise.town'];
    showConfirm: boolean = false;
    showAlert: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private careerService: CareerService,
        private authService: AuthService,
        private apiValidation: ApiValidation,
        private messageService: MessageService,
        private fb: UntypedFormBuilder
    ) {
        this.applyForm = this.fb.group({
            gdpr: [false, Validators.requiredTrue],
        });
    }

    ngOnInit(): void {

        this.user = this.authService.getUser() ?? new User;
        this.getCareer();
        this.getUser();
    }

    getUser() {
        const subscription = this.authService
            .getStatus()
            .subscribe((status) => {
                this.user = this.authService.getUser() ?? new User;
            });
        this.subscriptions.push(subscription);
    }

    getCareer() {
        const subscription = this.careerService.getItem(this.id ?? 0, this.includes).subscribe({
            next: ({ data }) => {
                this.loading = false;
                this.career = data;
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    detail: translate('Обявата не е намерена!')
                });

                this.router.navigateByUrl(this.jobsListUrl);
            }
        });

        this.subscriptions.push(subscription);
    }

    applyJob(event?: MouseEvent|Event) {
        event?.preventDefault();

        this.showConfirm = false;

        const subscription = this.careerService.applyCareer(this.id ?? 0).subscribe({
            next: (data) => {
                this.messageService.add({
                    severity: 'success',
                    detail: translate('Успешно кандидатствахте за позицията {{jobOffer}}', {jobOffer: this.career.title}),
                });

                this.career.applied = true;
            },
            error: (error) => {
                this.apiValidation.checkBadRequest(error, null).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        detail: data?.first || data?.message || translate('Моля, опитайте по-късно'),
                    });
                });
            }
        });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy() {
        this.subscriptions.map((item) => item?.unsubscribe());
    };

}
