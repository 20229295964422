<ng-container *ngIf="teacher?.id">
    <div class="card" id="basicInfo">
        <div class="card-body">
            <div class="row">
                <div class="col-auto">
                    <div class="m-3">
                        <avatar type="drop" [user]="teacher" size="big" class="d-none d-lg-inline-block"></avatar>
                        <avatar type="drop" [user]="teacher" size="small" class="d-lg-none"></avatar>
                    </div>
                </div>
                <div class="col">
                    <span class="d-none d-md-block" *ngIf="teacher?.id !== user?.id">
                        <a routerLink="/messages" [queryParams]="{conversation: teacher?.id, tab: 'conversation'}" class="btn btn-primary btn-sm float-end">
                            <i class="fa-regular fa-envelope fa-fw"></i>
                            {{ 'Напиши съобщение' | transloco }}
                        </a>
                    </span>

                    <ng-container *ngIf="user && (user | roleAny: 'admin') && teacher?.franchise">
                        <h2 class="mb-0">
                            <small class="badge rounded-pill text-bg-danger small me-1" *ngIf="teacher?.status === 'inactive'">{{ 'Неактивен' | transloco }}</small>
                            {{ teacher | userName }}
                        </h2>
                        <h3>{{ 'Франчайз: \{{name}\}' | transloco:{name: teacher?.franchise?.name} }}</h3>
                    </ng-container>

                    <h2 *ngIf="!(user && (user | roleAny: 'admin') && teacher?.franchise)">{{ teacher | userName }}</h2>

                    <div class="row">
                        <div class="col-12 col-md">
                            <dl>
                                <dt>E-mail</dt>
                                <dd class="text-nowrap">
                                    <i class="fa-solid fa-at fa-fw me-2"></i>
                                    <a *ngIf="teacher?.email?.length" [href]="'mailto:' + teacher?.email" target="_blank" (click)="$event.stopPropagation()">{{ teacher?.email ?? '-' }}</a>
                                    <ng-container *ngIf="!teacher?.email?.length">{{ 'Няма' | transloco }}</ng-container>
                                </dd>
                            </dl>
                        </div>
                        <div class="col-12 col-md">
                            <dl>
                                <dt>{{ 'Телефон' | transloco }}</dt>
                                <dd class="text-nowrap">
                                    <i class="fa-solid fa-phone fa-fw me-2"></i>
                                    <a *ngIf="teacher?.phone?.length" [href]="'tel:' + teacher?.phone" target="_blank" (click)="$event.stopPropagation()">{{ teacher?.phone ?? '-' }}</a>
                                    <ng-container *ngIf="!teacher?.phone?.length">{{ 'Няма' | transloco }}</ng-container>
                                </dd>
                            </dl>
                        </div>
                        <div class="col-12 col-md">
                            <dl>
                                <dt>{{ 'Дата на регистрация' | transloco }}</dt>
                                <dd>
                                    <i class="fa-regular fa-address-card fa-fw me-2"></i>
                                    <ng-container *ngIf="teacher?.created">{{ teacher?.created | dateLocale:"dd MMMM yyyy":null:'':('Не е посочено' | transloco ) }}</ng-container>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="user?.id !== teacher?.id"
         class="mt-5"
         infinite-scroll
         [infiniteScrollDistance]="2"
         (scrolled)="onScrollPage($event)"
    >
        <tabs paramType="id" class="mt-5 compact" variant="orange" (tabChange)="onTabChange($event)">
            <tab [label]="'Учебни групи' | transloco" id="groups">
                <div class="my-2" [class.mt-2]="tabs?.groups?.entity?.data?.length">
                    <div class="row mx-0 my-3 align-items-stretch" *ngIf="tabs?.groups?.entity?.data?.length">
                        <div class="col-lg-6 mb-2 mb-md-3" *ngFor="let group of tabs?.groups?.entity?.data; let index = index; trackBy: trackBy">
                            <card-group-small   [group]="group"
                                                [user]="user"
                                                [withEdit]="!!(user && (user | roleAny: 'partner':'admin'))"
                                                [withRemove]="false"
                            ></card-group-small>
                        </div>
                    </div>

                    <div *ngIf="!tabs?.groups?.entity?.data?.length" class="alert alert-warning">
                        {{ 'Няма налични учебни групи.' | transloco }}
                    </div>
                </div>
            </tab>

            <tab [label]="'Заетост' | transloco" id="schedule">
                <div class="my-2">
                    <calendar       className="mb-4"
                                    mode="view"
                                    [userId]="teacher?.id ?? null"
                                    defaultView="timeGridWeek"
                    ></calendar>
                </div>
            </tab>
        </tabs>
    </div>
</ng-container>
