import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { translate } from '@ngneat/transloco';

export interface ModalConfirmComponentAction {
    name?: string;
    action?: string;
    btnClass?: string;
    tooltip?: string;
    icon?: string;
};

@Component({
    selector: 'modal-confirm',
    templateUrl: './modal-confirm.component.html',
    styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent implements OnInit, OnDestroy {
    @Output() onAction: EventEmitter<string> = new EventEmitter();

    @Output() onShow: EventEmitter<any> = new EventEmitter();
    @Output() onHide: EventEmitter<any> = new EventEmitter();

    @Input() loading: boolean = false;
    @Input() delay: any = {'yes': 1, 'no': 0};
    @Input() headline: string|null = '';
    @Input() body: string|null = translate('Желаете ли продължение на действието?');
    @Input() actions: ModalConfirmComponentAction[] = [{
        name: translate('Да'), action: 'yes', btnClass: 'btn-primary', tooltip: translate('Потвърждение'), icon: 'fa-solid fa-check fa-fw'
    }, {
        name: translate('Не'), action: 'no', btnClass: 'btn-secondary', tooltip: translate('Отказване'), icon: 'fa-solid fa-xmark fa-fw'
    }];

    @Input() skippable: boolean = true;
    @Input() closable: boolean = true;

    constructor() { }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {

    }

    onCloseAction(e: any): void {
        if (this.skippable) {
            this.onAction.emit('close');
        }
    }

    onButtonClicked(event: Event, action?: string): void {
        event.preventDefault();
        this.onAction.emit(action);
    }

    onShowDialog(event?: any): void {
        this.onShow.emit(event);
    }

    onHideDialog(event?: any): void {
        this.onHide.emit(event);
    }

}
