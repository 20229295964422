export class CareerWork {
    id?: number;
    title?: string;

    static fromJson(fields: any): CareerWork {
        let item = Object.assign(new this(), fields);
        return item;
    }
};


