<div class="wrap {{ customClass || ''}}" (mouseenter)="isButtonHover = true" (mouseleave)="isButtonHover = false" [attr.title]="errors?.file" [class.disabled]="disabled">

    <div *ngIf="!templateDefault && progress && progress > 0" class="progress">
        <div class="progress-bar bg-warning" role="progressbar" [style]="'width: ' + progress + '%'" [attr.aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100"></div>
    </div>

    <ng-container *ngIf="templateDefault || templateLoading">
        <div *ngIf="templateDefault && !progress" class="{{ customClass || ''}}">
            <ng-container *ngTemplateOutlet="templateDefault, context: {$implicit: isButtonHover}"></ng-container>
        </div>

        <div *ngIf="templateLoading && progress">
            <ng-container *ngTemplateOutlet="templateLoading"></ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="!templateDefault && !templateLoading">
        <button class="btn btn-block button {{ btnClass }}"
            [ngClass]="{
                'btn-outline-danger': errors?.file?.length,
                'btn-outline-primary': !errors?.file?.length,
                'btn-sm': smallerButton,
                'active': isButtonHover,
                'text-nowrap': true
            }"
            [disabled]="uploading || disabled"
        >
            <i *ngIf="errors?.file?.length" class="fa fa-fw text-warning fa-exclamation-triangle"></i>
            <i *ngIf="uploading" class="fa fa-spinner fa-pulse fa-fw"></i>
            {{ buttonLabel }}
        </button>
    </ng-container>

    <label [for]="'fileUpload' + id"></label>

    <input [id]="'fileUpload' + id"
           #inputFile
           [disabled]="uploading || disabled"
           tabIndex="0"
           class="upload"
           type="file"
           (change)="onFileSelected($event)"
           [multiple]="(files || 0) > 1"
    />
</div>

<div *ngIf="templateAdditional">
    <ng-container *ngTemplateOutlet="templateAdditional"></ng-container>
</div>
