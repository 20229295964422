import { Course } from "./course.model";
import { FranchiseShort } from "./franchise-short.model";

export class GroupShort {
    id?: number;
    title?: string;
    date_start?: string;
    date_to?: string;
    course?: Course;
    franchise?: FranchiseShort;

    static fromJson(fields: any): GroupShort {
        let item = Object.assign(new this(), fields);
        item.course = Course.fromJson(fields?.course);
        item.franchise = FranchiseShort.fromJson(fields?.franchise);
        return item;
    }
}
