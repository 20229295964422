export type CourseCategoryTreeCourseStatus = ('active'|'inactive');

export class CourseCategoryTreeCourse {
    id?: number;
    design_id?: number;
    title?: string;
    logo?: string;
    url?: string;
    position?: number;
    status?: CourseCategoryTreeCourseStatus;
    active?: boolean;

    static fromJson(fields?: any): CourseCategoryTreeCourse {
        let item = Object.assign(new this(), fields);

        return item;
    }
}
