<a [routerLink]="'/courses/' + group?.id" *ngIf="type === 'course'">
    <ng-container [ngTemplateOutlet]="item"></ng-container>
</a>

<a [routerLink]="'/groups/' + group?.id" *ngIf="type === 'show'">
    <ng-container [ngTemplateOutlet]="item"></ng-container>
</a>

<a [routerLink]="'/homework/groups/' + group?.id" *ngIf="type === 'homework'">
    <ng-container [ngTemplateOutlet]="item"></ng-container>
</a>

<div *ngIf="type === 'view'">
    <ng-container [ngTemplateOutlet]="item"></ng-container>
</div>

<ng-template #item>
    <div class="course-card d-flex flex-column flex-lg-row my-3" [class.card-link]="type === 'show' || type === 'homework'">
        <div class="col-lg-6 col-12">
            <div class="course-icon">
                <img [src]="group?.course?.image" [alt]="group?.title || (!isCompetition ? ('Курс' | transloco) : ('Състезание' | transloco))" [class.inactive]="group?.status === 'inactive'">
            </div>
        </div>
        <div class="col-lg-6 col-12 p-lg-3">
            <div class="course-cont d-flex flex-column align-items-start justify-content-center">

                <h3 class="mb-3">
                    <small class="badge rounded-pill text-bg-danger small me-1" *ngIf="group?.status === 'inactive'">{{ 'Неактивна' | transloco }}</small>
                    {{group?.course?.title}}
                </h3>

                <h4>{{ group?.title }}</h4>

                <p *ngIf="group?.parsed_date_start && group?.parsed_date_to">
                    {{group?.parsed_date_start|dateLocale:"d MMMM y"}} - {{group?.parsed_date_to|dateLocale:"d MMMM y"}}
                </p>
                <p *ngIf="group?.parsed_date_exam">
                    <span class="w-bold">{{('Дата на изпит:' | transloco)}}</span> {{group?.parsed_date_exam|dateLocale:"d MMM y"}}
                </p>
                <p *ngIf="group?.parsed_date_certificate">
                    <span class="w-bold">{{('Сертификат:' | transloco)}}</span> {{group?.parsed_date_certificate|dateLocale:"d MMM y"}}
                </p>

                <div class="pt-2 pb-3" *ngIf="group?.facebook?.length && type === 'view'">
                    <a target="_blank" [href]="group?.facebook" (click)="preventPropagation($event)" class="my-2 py-3 px-0" >
                        <i class="fa-brands fa-square-facebook fa-fw fa-lg"></i>
                        {{ !isCompetition ? ('Група на курса във Facebook' | transloco) : ('Група на състезанието във Facebook' | transloco) }}
                    </a>
                </div>

                <div class="mt-auto mb-auto mb-lg-1 d-flex justify-content-end w-100" *ngIf="type !== 'homework'">

                    <a target="_blank" [href]="'groups/' + group?.id + '/live'" (click)="preventPropagation($event)"
                        class="live-btn m-2 py-3 px-0 me-1" *ngIf="group?.live?.online && group?.live?.zoom?.id">
                        <span class="sr-only">{{ 'На живо' | transloco }}</span>
                    </a>
                    <a target="_blank" [href]="group?.live?.google" (click)="preventPropagation($event)"
                        class="live-btn m-2 py-3 px-0 me-1" *ngIf="group?.live?.online && group?.live?.google?.length && !group?.live?.zoom?.id">
                        <span class="sr-only">{{ 'На живо' | transloco }}</span>
                    </a>
                    <div class="btn btn-primary d-lg-block d-none m-2 fw-bold" *ngIf="type === 'show' || type === 'course'">
                        {{ !isCompetition ? ('Към курса' | transloco) : ('Към състезанието' | transloco) }}
                    </div>
                </div>

                <ng-content></ng-content>
            </div>
        </div>
    </div>
</ng-template>
