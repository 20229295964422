import { Component, OnDestroy, OnInit } from '@angular/core';
import { FranchiseService } from 'src/app/shared/services/franchise.service';
import { Subscription } from 'rxjs';
import { Franchise } from 'src/app/shared/models/franchise.model';

@Component({
  selector: 'sidebar-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class SidebarRatingComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    company: Franchise|null = null;

    constructor(
        private franchiseService: FranchiseService,
    ) { }

    ngOnInit(): void {
        const subscription = this.franchiseService?.getCompanyDetails().subscribe(data => {
            this.company = data;
        });
        this.subscriptions.push(subscription);

    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

}
