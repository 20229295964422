import { Award } from "./award.model";

export class Present {
    id?: number;
    quantity?: number;
    points?: number;
    date?: string;
    award?: Award;

    static fromJson(fields: any): Present {
        let item = Object.assign(new this(), fields);
        item.award = Award.fromJson(fields?.award);
        return item;
    }
}
