import { LOCALE_ID, NgModule, APP_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeBg from '@angular/common/locales/bg';
// import localeRu from '@angular/common/locales/ru';
// import localeUk from '@angular/common/locales/uk';
import { MessageService } from 'primeng/api'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import packageJson from './../../package.json';
import { UnauthInterceptor } from './shared/interceptors/unauth.interceptor';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';
import { environment } from 'src/environments/environment';
import { NgChartsModule } from 'ng2-charts';

// registerLocaleData(localeRu);
// registerLocaleData(localeUk);
registerLocaleData(localeBg);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({
            appId: packageJson.name
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        TranslocoRootModule,
        NgChartsModule,
    ],
    providers: [
        {
            provide: APP_ID,
            useValue: packageJson.name
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthInterceptor,
            multi: true
        }, {
            provide: LOCALE_ID,
            useValue: environment.locales.default.replace('_', '-'),
        },
        MessageService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
