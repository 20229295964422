<page-wrap
    [headline]="(mode === 'add'
        ? ('Създаване на тест' | transloco)
        : ('Редактиране на тест' | transloco )) + ' - ' + (isGlobal ? (course?.title ?? '') : (group?.course?.title ?? ''))"
    [navigation]="[{
        title: isGlobal ? ('Курсове' | transloco) : ('Групи' | transloco),
        link: isGlobal ? '/courses/' : '/groups/',
    }, {
        title: (isGlobal ? (course?.title ?? '') : (group?.course?.title ?? '')),
        link: isGlobal
            ? '/courses/' + course?.id
            : '/groups/' + group?.id
    }, {
        title: mode === 'add' ? ('Добавяне на тест' | transloco) : ('Редактиране на тест' | transloco)
    }]"
>
    <ng-template section="sidebar"></ng-template>

    <loading [loading]="loading"
             [hasData]="(group?.course?.id ?? 0) > 0"
             class="position-relative"
             style="z-index:12"></loading>

    <ng-template section="actions" >
        <menu-actions   *ngIf="id && mode === 'edit' && !loading"
                        [actions]="[
                            { text: ('Премахване' | transloco), icon: 'fa-solid fa-trash-can', id:'remove' },
                        ]"
                        [roles]="['admin','partner']"
                        (buttonClicked)="onButtonClicked($event)"
        ></menu-actions>
    </ng-template>

    <form *ngIf="form" (submit)="onSubmit($event)" [formGroup]="form" novalidate class="standard-form">
        <div class="card mb-3 mb-lg-5">
            <div class="card-header">

                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="my-0 fw-bold">{{ 'Основни' | transloco }}</h3>
                    </div>
                    <div class="col-auto">
                        <div class="form-check form-switch d-inline-block">
                            <input  class="form-check-input"
                                    type="checkbox"
                                    id="status"
                                    formControlName="status"
                                    [checked]="form.controls['status'].value" />
                            <label class="form-check-label" for="status">{{ form.controls['status'].value ? ('Активен статус'|transloco) : ('Неактивен статус'|transloco) }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fieldType" class="form-label">{{ 'Тип на теста'| transloco }}</label>

                            <p-dropdown inputId="fieldType"
                                        [class.is-invalid]="form.controls['type'].invalid && form.controls['type'].touched"
                                        [filter]="false"
                                        [options]="typesList"
                                        formControlName="type"
                                        optionValue="title"
                                        optionLabel="description"
                                        styleClass="w-100"
                            ></p-dropdown>

                            <ng-container *ngIf="form.controls['type'].touched">
                                <div *ngIf="form.controls['type']?.errors?.['required']" class="invalid-feedback">{{ 'Задължително поле' | transloco }}</div>
                                <div *ngIf="form.controls['type']?.errors?.['notunique']" class="invalid-feedback">{{ 'Избрана е невалидна стойност'| transloco }}</div>
                                <div *ngIf="form.controls['type']?.errors?.['any']" class="invalid-feedback">{{ form.controls['type']?.errors?.['any'] }}</div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fieldModule" class="form-label">{{ 'Към модул' | transloco }}</label>

                            <p-dropdown inputId="fieldModule"
                                        [class.is-invalid]="form.controls['module'].invalid && form.controls['module'].touched"
                                        [filter]="true"
                                        [options]="modulesList"
                                        formControlName="module"
                                        optionValue="value"
                                        optionLabel="label"
                                        optionDisabled="disabled"
                                        styleClass="w-100"
                            ></p-dropdown>

                            <ng-container *ngIf="form.controls['module'].touched">
                                <div *ngIf="form.controls['module']?.errors?.['required']" class="invalid-feedback">{{ 'Задължително поле' | transloco }}</div>
                                <div *ngIf="form.controls['module']?.errors?.['min']" class="invalid-feedback">{{ 'Полето не е избрано'| transloco }}</div>
                                <div *ngIf="form.controls['module']?.errors?.['notunique']" class="invalid-feedback">{{'Избрана е невалидна стойност'| transloco }}</div>
                                <div *ngIf="form.controls['module']?.errors?.['any']" class="invalid-feedback">{{ form.controls['module']?.errors?.['any'] }}</div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fieldName" class="form-label">{{ 'Заглавие на теста' | transloco }}</label>

                            <input  type="text"
                                    id="fieldName"
                                    class="form-control"
                                    formControlName="name"
                                    [class.is-invalid]="form.controls['name'].invalid && form.controls['name'].touched"
                            />

                            <ng-container *ngIf="form.controls['name'].touched">
                                <div *ngIf="form.controls['name']?.errors?.['required']" class="invalid-feedback">{{ 'Задължително поле' | transloco }}</div>
                                <div *ngIf="form.controls['name']?.errors?.['maxlength']" class="invalid-feedback">{{ 'Полето не трябва да надвишава 100 символа'| transloco }}</div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fieldCourse" class="form-label">{{ 'Име на курс' | transloco }}</label>

                            <input  type="text"
                                    id="fieldCourse"
                                    class="form-control"
                                    formControlName="course"
                                    [class.is-invalid]="form.controls['course'].invalid && form.controls['course'].touched"
                            />

                            <ng-container *ngIf="form.controls['course'].touched">
                                <div *ngIf="form.controls['course']?.errors?.['required']" class="invalid-feedback">{{ 'Задължително поле' | transloco }}</div>
                                <div *ngIf="form.controls['course']?.errors?.['maxlength']" class="invalid-feedback">{{ 'Полето не трябва да надвишава 150 символа'| transloco }}</div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fieldSpecialty" class="form-label">{{ 'Специалност'| transloco }}</label>

                            <input  type="text"
                                    id="fieldSpecialty"
                                    class="form-control"
                                    formControlName="specialty"
                                    [class.is-invalid]="form.controls['specialty'].invalid && form.controls['specialty'].touched"
                            />

                            <ng-container *ngIf="form.controls['specialty'].touched">
                                <div *ngIf="form.controls['specialty']?.errors?.['required']" class="invalid-feedback">{{ 'Задължително поле' | transloco }}</div>
                                <div *ngIf="form.controls['specialty']?.errors?.['maxlength']" class="invalid-feedback">{{ 'Полето не трябва да надвишава 150 символа'| transloco }}</div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fieldProfession" class="form-label">{{ 'Професия' | transloco }}</label>

                            <input  type="text"
                                    id="fieldProfession"
                                    class="form-control"
                                    formControlName="profession"
                                    [class.is-invalid]="form.controls['profession'].invalid && form.controls['profession'].touched"
                            />

                            <ng-container *ngIf="form.controls['profession'].touched">
                                <div *ngIf="form.controls['profession']?.errors?.['required']" class="invalid-feedback">{{ 'Задължително поле' | transloco }}</div>
                                <div *ngIf="form.controls['profession']?.errors?.['maxlength']" class="invalid-feedback">{{ 'Полето не трябва да надвишава 150 символа' | transloco }}</div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fieldDuration" class="form-label">{{ 'Продължителност (в мин.)' | transloco }}</label>

                            <input  type="number"
                                    min="0"
                                    id="fieldDuration"
                                    class="form-control"
                                    formControlName="duration"
                                    [class.is-invalid]="form.controls['duration'].invalid && form.controls['duration'].touched"
                            />

                            <ng-container *ngIf="form.controls['duration'].touched">
                                <div *ngIf="form.controls['duration']?.errors?.['required']" class="invalid-feedback">{{ 'Задължително поле' | transloco }}</div>
                                <div *ngIf="form.controls['duration']?.errors?.['min']" class="invalid-feedback">{{ 'Минимумът е 1 минута' | transloco }}</div>
                                <div *ngIf="form.controls['duration']?.errors?.['max']" class="invalid-feedback">{{ 'Максимумът е 1440 минути (24 часа)'| transloco }}</div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="fieldMaxPoint" class="form-label">{{ 'Максимален брой точки' | transloco }}</label>

                            <input  type="number"
                                    min="0"
                                    id="fieldMaxPoint"
                                    class="form-control"
                                    formControlName="max_points"
                                    [class.is-invalid]="form.controls['max_points'].invalid && form.controls['max_points'].touched"
                            />

                            <ng-container *ngIf="form.controls['max_points'].touched">
                                <div *ngIf="form.controls['max_points']?.errors?.['required']" class="invalid-feedback">{{ 'Задължително поле' | transloco }}</div>
                                <div *ngIf="form.controls['max_points']?.errors?.['min']" class="invalid-feedback">{{ 'Минимума е 1т.' | transloco }}</div>
                                <div *ngIf="form.controls['max_points']?.errors?.['max']" class="invalid-feedback">{{ 'Максимума е 1000т.'| transloco }}</div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3 mb-lg-5">
            <div class="card-header">
                <h3 class="my-0 fw-bold">
                    {{ 'Въпроси към теста' | transloco }}
                    <span *ngIf="countQuestions">{{ '(\{{ activeQuestions }\} от \{{ totalQuestions }\} общо)' | transloco:{activeQuestions: countQuestionsActive, totalQuestions: countQuestions} }}</span>
                </h3>
            </div>

            <div class="card-body p-0">
                <div class="bg-light py-2 px-3 border-0 action-panel" [class.overlapping]="actionPanelOverlapping">
                    <div class="row">
                        <div class="col-auto d-inline-flex align-items-center order-0 order-md-0">
                            <div class="btn-group" role="group" aria-label="Basic outlined example">
                                <button type="button"
                                        [disabled]="countQuestions < 1"
                                        class="btn btn-outline-primary bg-transparent btn-sm border-0"
                                        (click)="onQuestionsToggle($event, true)"
                                >
                                    <i class="fa-solid fa-circle-arrow-down fa-fw fa-lg"></i>
                                    {{ 'Разгъни' | transloco }}
                                </button>
                                <button type="button"
                                        [disabled]="countQuestions < 1"
                                        class="btn btn-outline-primary bg-transparent btn-sm border-0"
                                        (click)="onQuestionsToggle($event, false)"
                                >
                                    <i class="fa-solid fa-circle-arrow-up fa-fw fa-lg"></i>
                                    {{ 'Свий' | transloco }}
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-md d-inline-flex align-items-center order-2 order-md-1">
                            <div class="input-group my-2 question-input-group">
                                <input type="text"
                                        class="form-control my-0 question-input shadow-none"
                                        [placeholder]="'Въведете нов въпрос' | transloco"
                                        [(ngModel)]="question"
                                        [ngModelOptions]="{standalone: true}"
                                        (keydown.enter)="addQuestion($event)"
                                        #questionInput
                                />

                                <upload type="file"
                                        [files]="1"
                                        [label]="'Избор на файл' | transloco"
                                        [labelUploading]="'Качване...' | transloco"
                                        [disabled]="loading"
                                        class="btn btn-default upload-photo p-0"
                                        [title]="'Добавяне на файл' | transloco"

                                        (onFilesFinished)="addQuestionOnFileUpload($event)"
                                        (onFileProgress)="loading = true"
                                >
                                    <ng-template itemTemplate="default" let-isButtonHover>
                                        <i class="fa-solid fa-camera fa-fw p-0 upload-icon" [class.hover]="isButtonHover"></i>
                                    </ng-template>

                                    <ng-template itemTemplate="loading">
                                        <div class="text-center">
                                            <i class="d-inline-block fa-solid fa-spinner fa-fw fa-pulse mx-auto text-secondary mx-auto"></i>
                                        </div>
                                    </ng-template>
                                </upload>

                                <button class="btn btn-primary add-new-question" type="button" [title]="'Добавяне на въпрос' | transloco" (click)="addQuestion($event)">
                                    <i class="fa-solid fa-paper-plane fa-fw"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-auto ms-auto d-inline-flex align-items-center order-1 order-md-2">
                            <div class="dropdown">
                                <button class="btn btn-primary dropdown-toggle mass-options"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        (show.bs.dropdown)="actionPanelOverlapping = true"
                                        (hidden.bs.dropdown)="actionPanelOverlapping = false"
                                >
                                    {{ 'Опции' | transloco}}
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <button type="button" class="dropdown-item" (click)="onActionQuestions($event, 'inactive')">
                                            <i class="fa-regular fa-eye-slash fa-fw"></i>

                                            {{ 'Скриване на избраните' | transloco}}
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="dropdown-item" (click)="onActionQuestions($event, 'active')">
                                            <i class="fa-regular fa-eye fa-fw"></i>

                                            {{ 'Показване на избраните' | transloco}}
                                        </button>
                                    </li>
                                    <li>
                                        <hr class="dropdown-divider"></li>
                                    <li>
                                        <button type="button" class="dropdown-item" (click)="onActionQuestions($event, 'delete')">
                                            <i class="fa-regular fa-trash-can fa-fw text-danger"></i>

                                            {{ 'Премахване избраните' | transloco }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion accordion-flush mx-3" #questionsAccordion formArrayName="questions" [class.mt-3]="questionControls?.controls?.length">
                    <div *ngFor="let question of questionControls?.controls; let questionIndex = index"
                        class="accordion-item mb-3 bg-light"
                        [formGroupName]="questionIndex"
                        #questionItem
                    >
                        <h2 class="accordion-header mb-0 d-flex align-items-center justify-content-between" [id]="'panelAccordionHeader' + questionIndex">
                            <div class="form-check px-3 py-2 position-relative">
                                <input class="form-check-input mx-0"
                                    type="checkbox"
                                    formControlName="select"
                                    [id]="'selectQuestion' + questionIndex"
                                />
                                <label class="form-check-label position-absolute" style="inset:0; z-index: 1" [for]="'selectQuestion' + questionIndex"></label>
                            </div>

                            <div
                                    class="accordion-button btn btn-default collapsed ps-0 pe-2 py-3"
                                    [ngClass]="{
                                        'text-muted text-decoration-line-through': question.controls['status'].value === false,
                                        'collapsed': !question.controls['is_new'].value
                                    }"
                                    data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#panelAccordionCollapse' + questionIndex"
                                    aria-expanded="false"
                                    [attr.aria-controls]="'panelAccordionCollapse' + questionIndex"
                                    #accordionToggleButton
                            >
                                {{ questionIndex + 1 }}.

                                <div class="w-100 mx-2">
                                    <input-inline-text formControlName="question"
                                                class="d-block is-invalid"
                                                [placeholder]="'Напишете въпрос в това поле' | transloco"
                                                [class.is-invalid]="question.controls['question'].invalid && question.controls['question'].touched"
                                                (click)="$event.stopPropagation();"
                                                (edit)="accordionToggleButton?.setAttribute('data-bs-target', '')"
                                                (save)="accordionToggleButton?.setAttribute('data-bs-target', '#panelAccordionCollapse' + questionIndex)"
                                                (cancel)="accordionToggleButton?.setAttribute('data-bs-target', '#panelAccordionCollapse' + questionIndex)"
                                    ></input-inline-text>

                                    <ng-container *ngIf="question.controls['question'].touched">
                                        <div *ngIf="question.controls['question']?.errors?.['maxlength']" class="invalid-feedback">{{ 'Полето надвишава 255 символа'  | transloco}}</div>
                                        <div *ngIf="question.controls['question']?.errors?.['required']" class="invalid-feedback">{{ 'Задължително поле' | transloco }}</div>
                                    </ng-container>
                                </div>
                            </div>

                            <small class="badge rounded-pill text-bg-danger mx-1 fw-normal align-self-center" *ngIf="question.controls['status'].value === false">Скрит</small>

                            <button class="btn btn-default shadow-none border-0 dropdown-toggle px-3 more-options" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa-solid fa-ellipsis-vertical fa-fw"></i>
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <upload type="file"
                                            [files]="1"
                                            [label]="'Избор на файл' | transloco"
                                            [labelUploading]="'Качване...' | transloco"
                                            [disabled]="loading"
                                            class="btn btn-default p-0 w-100 rounded-0 border-0"
                                            (onFilesFinished)="addQuestionOnFileUpload($event, question, questionItem)"
                                            (onFileProgress)="loading = true"

                                    >
                                        <ng-template itemTemplate="default" let-isButtonHover>
                                            <div class="dropdown-item upload-item text-start" [class.hover]="isButtonHover">
                                                <i class="fa-solid fa-camera fa-fw"></i>
                                                {{ 'Добавяне на снимка' | transloco}}
                                            </div>
                                        </ng-template>
                                    </upload>
                                </li>
                                <li><hr class="dropdown-divider"></li>
                                <li>
                                    <button type="button" class="dropdown-item" *ngIf="question.controls['status'].value" (click)="onActionQuestions($event, 'inactive', questionIndex)">
                                        <i class="fa-regular fa-eye-slash fa-fw"></i>

                                        {{ 'Скриване' | transloco}}
                                    </button>
                                </li>
                                <li>
                                    <button type="button" class="dropdown-item" *ngIf="!question.controls['status'].value" (click)="onActionQuestions($event, 'active', questionIndex)">
                                        <i class="fa-regular fa-eye fa-fw"></i>

                                        {{ 'Показване'  | transloco }}
                                    </button>
                                </li>
                                <li><hr class="dropdown-divider"></li>
                                <li>
                                    <button type="button" class="dropdown-item" (click)="onActionQuestions($event, 'delete', questionIndex)">
                                        <i class="fa-regular fa-trash-can fa-fw text-danger"></i>
                                        {{ 'Премахване' | transloco }}
                                    </button>
                                </li>
                            </ul>
                        </h2>
                        <div [id]="'panelAccordionCollapse' + questionIndex"
                            class="accordion-collapse collapse"
                            [class.show]="question.controls['is_new'].value"
                            [attr.aria-labelledby]="'panelAccordionHeader' + questionIndex"
                        >
                            <div class="accordion-body">

                                <div class="row">
                                    <div class="col-md-10 col-lg-8">
                                        <div *ngIf="question.controls['image'].value?.length" class="preview mb-4">
                                            <button type="button"
                                                    class="btn btn-default btn-sm text-danger shadow-none py-1 px-2"
                                                    (click)="onImageRemove($event, question)"
                                                    [title]="'Премахване на снимката' | transloco"
                                            >
                                                <i class="fa-regular fa-trash-can fa-fw"></i>
                                            </button>
                                            <a [href]="question.controls['image'].value" target="_blank"><img [src]="question.controls['image'].value" /></a>
                                        </div>

                                        <h3 class="fw-bold">{{ 'Възможни отговори: \{{count}\}'  | transloco:{count: question.controls['answers'].value?.length} }} </h3>

                                        <ul class="list-unstyled mb-0">
                                            <!-- only this section is within question answers -->
                                            <ng-container  formArrayName="answers">
                                                <li *ngFor="let answer of $any(question.get('answers'))?.controls; let answerIndex = index"
                                                    class="mb-3 d-flex align-items-stretch w-100"
                                                    [formGroupName]="answerIndex"
                                                >
                                                    <div class="list-group-item d-flex align-items-center px-3 py-2 position-relative" [title]="'Задай като правилен отговор' | transloco">
                                                        <input class="form-check-input me-1"
                                                                type="radio"
                                                                formControlName="is_correct"
                                                                [attr.name]="'answers' + questionIndex"
                                                                [id]="'answer_' + questionIndex + '_' + answerIndex"
                                                                [value]="true"
                                                                (change)="onActionAnswer($event, question, 'correct', answerIndex)"
                                                        />
                                                        <label class="form-check-label position-absolute"
                                                                style="inset:0; z-index: 1"
                                                                [for]="'answer_' + questionIndex + '_' + answerIndex"></label>
                                                    </div>

                                                    <div class="d-flex border align-items-center rounded-2 py-1 ps-3 pe-1 w-100"
                                                        [ngClass]="{
                                                            'border-light bg-light ': !answer?.controls['is_correct']?.value,
                                                            'text-muted ': answer.controls['status'].value === false,
                                                            'border-success text-dark bg-opacity-10 bg-success ': answer?.controls['is_correct']?.value === true
                                                        }"
                                                    >
                                                        <div class="mt-1 number-items">{{ answerIndex + 1 }}.</div>

                                                        <div class="w-100 mx-2">
                                                            <input-inline-text formControlName="answer"
                                                                        class="d-block is-invalid"
                                                                        [class.text-decoration-line-through]="answer.controls['status'].value === false"
                                                                        [placeholder]="'Напишете възможен отговор в това поле' | transloco"
                                                                        [class.is-invalid]="answer.controls['answer'].invalid && answer.controls['answer'].touched"
                                                                        (click)="$event.stopPropagation();"
                                                            ></input-inline-text>

                                                            <ng-container *ngIf="answer.controls['answer'].touched">
                                                                <div *ngIf="answer.controls['answer']?.errors?.['maxlength']" class="invalid-feedback">{{ 'Полето надвишава 255 символа' | transloco}}</div>
                                                                <div *ngIf="answer.controls['answer']?.errors?.['required']" class="invalid-feedback">{{ 'Задължително поле' | transloco }}</div>
                                                            </ng-container>

                                                            <div *ngIf="answer.controls['image'].value?.length" class="preview mt-4 mb-2">
                                                                <button type="button"
                                                                        class="btn btn-default btn-sm text-danger shadow-none py-1 px-2"
                                                                        (click)="onImageRemove($event, answer)"
                                                                        [title]="'Изтриване на снимката' | transloco"
                                                                >
                                                                    <i class="fa-regular fa-trash-can fa-fw"></i>
                                                                </button>
                                                                <a [href]="answer.controls['image'].value" target="_blank"><img [src]="answer.controls['image'].value" /></a>
                                                            </div>
                                                        </div>

                                                        <small class="badge rounded-pill text-bg-danger mx-1 fw-normal align-self-center" *ngIf="answer.controls['status'].value === false">{{ 'Скрит' | transloco}}</small>

                                                        <button class="btn btn-default shadow-none border-0 more-options dropdown-toggle px-3 align-self-start" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i class="fa-solid fa-ellipsis-vertical fa-fw"></i>
                                                        </button>
                                                        <ul class="dropdown-menu">
                                                            <li>
                                                                <upload type="file"
                                                                        [files]="1"
                                                                        [label]="'Избор на файл' | transloco"
                                                                        [labelUploading]="'Качване...' | transloco"
                                                                        [disabled]="loading"
                                                                        class="btn btn-default p-0 w-100 rounded-0 border-0"
                                                                        (onFilesFinished)="addAnswerOnFileUpload($event, question, answer, answerActionPanel)"
                                                                        (onFileProgress)="loading = true"

                                                                >
                                                                    <ng-template itemTemplate="default" let-isButtonHover>
                                                                        <div class="dropdown-item upload-item text-start" [class.hover]="isButtonHover">
                                                                            <i class="fa-solid fa-camera fa-fw"></i>
                                                                            {{ 'Добавяне на снимка' | transloco}}
                                                                        </div>
                                                                    </ng-template>
                                                                </upload>
                                                            </li>
                                                            <li><hr class="dropdown-divider"></li>
                                                            <li>
                                                                <button type="button" class="dropdown-item" *ngIf="answer.controls['status'].value" (click)="onActionAnswer($event, question, 'inactive', answerIndex)">
                                                                    <i class="fa-regular fa-eye-slash fa-fw"></i>

                                                                    {{ 'Скриване' | transloco }}
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button type="button" class="dropdown-item" *ngIf="!answer.controls['status'].value" (click)="onActionAnswer($event, question, 'active', answerIndex)">
                                                                    <i class="fa-regular fa-eye fa-fw"></i>

                                                                    {{ 'Показване' | transloco }}
                                                                </button>
                                                            </li>
                                                            <li><hr class="dropdown-divider"></li>
                                                            <li>
                                                                <button type="button" class="dropdown-item" (click)="onActionAnswer($event, question, 'delete', answerIndex)">
                                                                    <i class="fa-regular fa-trash-can fa-fw text-danger"></i>
                                                                    {{ 'Премахни' | transloco }}
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ng-container>

                                            <!-- keep this outside of answer section -->
                                            <li #answerActionPanel>
                                                <div class="col-12 col-md d-inline-flex align-items-center order-2 order-md-1">
                                                    <div class="px-4">&nbsp;</div>

                                                    <div class="input-group">
                                                        <input type="text"
                                                                class="form-control my-0 py-2 answer-input shadow-none"
                                                                [placeholder]="'Въведете възможен отговор' | transloco"
                                                                formControlName="answer"
                                                                (keydown.enter)="addAnswer($event, question, answerActionPanel, answerInput)"
                                                                #answerInput
                                                        />

                                                        <upload type="file"
                                                                [files]="1"
                                                                [label]="'Избор на файл' | transloco"
                                                                [labelUploading]="'Качване...' | transloco"
                                                                [disabled]="loading"
                                                                class="btn btn-default upload-photo p-0"
                                                                [title]="'Добавяне на файл' | transloco"

                                                                (onFilesFinished)="addAnswerOnFileUpload($event, question, undefined, answerActionPanel, answerInput)"
                                                                (onFileProgress)="loading = true"
                                                        >
                                                            <ng-template itemTemplate="default" let-isButtonHover>
                                                                <i class="fa-solid fa-camera fa-fw p-0 upload-icon" [class.hover]="isButtonHover"></i>
                                                            </ng-template>

                                                            <ng-template itemTemplate="loading">
                                                                <div class="text-center">
                                                                    <i class="d-inline-block fa-solid fa-spinner fa-fw fa-pulse mx-auto text-secondary mx-auto"></i>
                                                                </div>
                                                            </ng-template>
                                                        </upload>

                                                        <button class="btn btn-primary add-new-answer"
                                                                type="button"
                                                                [title]="'Добавяне на възможен отговор' | transloco"
                                                                (click)="addAnswer($event, question, answerActionPanel, answerInput)"
                                                        >
                                                            <i class="fa-solid fa-paper-plane fa-fw"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="submit-panel bg-white py-2 mb-5 text-center">
            <button class="btn btn-primary"
                        [disabled]="loading ? true : false"
                        type="submit"
                >
                    <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                    <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                    {{ 'Запиши' | transloco }}
            </button>
        </div>
    </form>
</page-wrap>

<modal-confirm *ngIf="showDelete"
               [body]="'Желаете ли да премахнете този тест?' | transloco"
               [headline]="'Премахване на тест?' | transloco"
               [skippable]="true"
               (onAction)="onDeleteConfirmAction($event)"
></modal-confirm>

<modal-confirm *ngIf="showWarning"
                [body]="warning"
                [headline]="'Предупреждение' | transloco"
                [actions]="warningActions"
                [skippable]="true"
                (onAction)="onSubmit($event)"
></modal-confirm>
