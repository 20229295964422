import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ApiValidation } from 'src/app/shared/services/api-validation.service';
import { User } from 'src/app/shared/models/user/user.model';
import { FranchiseServiceInputFranchise } from 'src/app/shared/services/franchise.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FranchiseService } from 'src/app/shared/services/franchise.service';
import { Franchise } from 'src/app/shared/models/franchise.model';
import { translate } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { FranchisePreference } from 'src/app/shared/models/franchise-preference.model';
import { themeDefault, themeLight } from './form-partner-customization.themes';

export type FormPartnerCustomizationComponentColorName = (
      'themeColorPrimary'
    | 'themeColorPrimaryDark'
    | 'themeColorPrimaryLight'
    | 'themeColorSecondary'
    | 'themeColorSecondaryDark'
    | 'themeColorSecondaryLight'
    | 'themeColorThird'
    | 'themeColorThirdDark'
    | 'themeColorThirdLight'
    | 'themeColorText'
    | 'themeColorTextDark'
    | 'themeColorTextLight'
    | 'themeColorTextInvert'
    | 'themeColorLink'
    | 'themeColorLinkHover'
    | 'themeColorLinkActive'
    | 'themeColorSidebar'
    | 'themeColorSidebarActive'
    | 'themeColorSidebarText'
    | 'themeColorSidebarTextHover'
    | 'themeColorSidebarTextActive'
    | 'themeColorBtnPrimary'
    | 'themeColorBtnPrimaryHover'
    | 'themeColorBtnPrimaryActive'
    | 'themeColorBtnPrimaryText'
    | 'themeColorBtnSecondary'
    | 'themeColorBtnSecondaryHover'
    | 'themeColorBtnSecondaryActive'
    | 'themeColorBtnSecondaryText'
    | 'themeColorBtnSignup'
    | 'themeColorBtnSignupHover'
    | 'themeColorBtnSignupActive'
    | 'themeColorBtnSignupText'
);

interface FormPartnerCustomizationComponentTheme {
    [name: string]: {
        label: string;
        colors: {[name in FormPartnerCustomizationComponentColorName]: string};
    },
};

@Component({
    selector: 'form-partner-customization',
    templateUrl: './form-partner-customization.component.html',
    styleUrls: ['./form-partner-customization.component.scss']
})
export class FormPartnerCustomizationComponent implements OnInit, OnDestroy {
    protected readonly PREFERENCE_NAME: string = FranchisePreference.PREFERENCE_THEME_COLORS;
    protected readonly COLOR_DEFAULT_THEME: string = 'default';

    protected innerLoadingQueue: number = 0;
    protected innerReset: boolean = false;
    protected subscriptions: Subscription[] = [];
    protected innerId: number|null = null;
    protected innerSubmit: boolean = false;

    protected colorThemes: FormPartnerCustomizationComponentTheme = {
        [this.COLOR_DEFAULT_THEME]: {
            label: translate('По подразбиране'),
            colors: themeDefault,
        },
        ThemeLight: {
            label: translate('Светла тема'),
            colors: themeLight,
        },
    };

    colorThemesList: Array<{name: string, value: string}> = [];
    colorTheme: string = '';

    protected colorsList: {[name in FormPartnerCustomizationComponentColorName]: string} = {
        themeColorPrimary: '',
        themeColorPrimaryDark: '',
        themeColorPrimaryLight: '',

        themeColorSecondary: '',
        themeColorSecondaryLight: '',
        themeColorSecondaryDark: '',

        themeColorThird: '',
        themeColorThirdDark: '',
        themeColorThirdLight: '',

        themeColorText: '',
        themeColorTextDark: '',
        themeColorTextLight: '',
        themeColorTextInvert: '',

        themeColorLink: '',
        themeColorLinkHover: '',
        themeColorLinkActive: '',

        themeColorSidebar: '',
        themeColorSidebarActive: '',
        themeColorSidebarText: '',
        themeColorSidebarTextHover: '',
        themeColorSidebarTextActive: '',

        themeColorBtnPrimary: '',
        themeColorBtnPrimaryHover: '',
        themeColorBtnPrimaryActive: '',
        themeColorBtnPrimaryText: '',
        themeColorBtnSecondary: '',
        themeColorBtnSecondaryHover: '',
        themeColorBtnSecondaryActive: '',
        themeColorBtnSecondaryText: '',
        themeColorBtnSignup: '',
        themeColorBtnSignupHover: '',
        themeColorBtnSignupActive: '',
        themeColorBtnSignupText: '',
    };

    form: FormGroup|null = null;
    errors: any = {};

    user: User = new User;
    franchise: Franchise|null = null;
    company: Franchise|null = null;
    showRefreshAskModal: boolean = false;

    @Output('franchise') onFranchiseLoad: EventEmitter<Franchise> = new EventEmitter();

    @Output() resetChange: EventEmitter<boolean> = new EventEmitter();

    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();
    @Input() loading: boolean = false;

    @Output() submitChange: EventEmitter<boolean> = new EventEmitter();
    @Input()
    set submit(value: boolean) {
        if (this.innerSubmit !== value) {
            this.innerSubmit = false;
            value && this.onSubmit();

            setTimeout(() => {
                this.submitChange.emit(this.innerSubmit);
            });
        }
    }
    get submit(): boolean {
        return this.innerSubmit;
    }

    @Input()
    set id(value: number|null) {
        if (this.innerId !== value) {
            this.innerId = value;
            this.innerId && this.getFranchise(this.innerId);
        }
    }
    get id(): number|null {
        return this.innerId;
    }

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }

            this.loadingChange.emit(this.loading);
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        private el: ElementRef,
        private router: Router,
        private authService: AuthService,
        private messageService: MessageService,
        private apiValidation: ApiValidation,
        private franchiseService: FranchiseService,

    ) { }

    ngOnInit(): void {
        this.user = this.authService.getUser() ?? this.user;

        for (let name in this.colorsList) {
            this.colorsList[name as FormPartnerCustomizationComponentColorName]  = this.colorsList[name as FormPartnerCustomizationComponentColorName] || this.getFieldValueFromCssVariable(name);
        }

        this.colorThemesList = Object.keys(this.colorThemes)?.map(name => {
            return {
                name: this.colorThemes[name].label,
                value: name,
            };
        });

        this.initForm();

        const subscription = this.franchiseService.getCompanyDetails().subscribe(data => {
            this.company = data;
        });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.map(item => item?.unsubscribe());
    }

    onSubmit(event?: any): void {
        if (this.loading) {
            return;
        }

        this.form?.updateValueAndValidity();

        if (this.form?.invalid) {
            this.errors = this.form?.controls;
            this.messageService.add({
                severity: 'error',
                summary: translate('Грешка!'),
                detail: translate('Моля, проверете полетата отново!'),
            });
            return;
        }

        let properties: string[] = [];
        for (let name in this.colorsList) {
            let val = this.form?.get(name)?.value ?? '';
            val?.length > 0 && properties.push(name + '=' + this.form?.get(name)?.value);
        }

        const formData: FranchiseServiceInputFranchise = {
            name: this.form?.get('name')?.value || undefined,
            preferences: this.mergePreferences(this.franchise?.preferences ?? [], [{
                name: this.PREFERENCE_NAME,
                type: 'string',
                visibility: 'public',
                value: properties.join(','),
            }]),
        };

        this.loadingQueue++;
        const userSubscription = this.franchiseService.updateFranchise(this?.franchise?.id ?? 0, formData).subscribe({
            next: (data: any) => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'success',
                    summary: translate('Успешно редактиран запис!'),
                    detail: data.message
                });

                if (this.franchise?.id  === this.user?.franchise?.id || this.franchise?.id === this.company?.id) {
                    this.showRefreshAskModal = true;
                }
            },
            error: (error: any) => {
                this.loadingQueue--;
                this.apiValidation.checkBadRequest(error, this.form, true).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: data.message
                    });
                    this.errors = data.errors;
                });
            }
        });
        this.subscriptions.push(userSubscription);
    }

    protected initForm(): void {
        this.form = new FormGroup({
            name: new FormControl(this.franchise?.name ?? '', [
                Validators.maxLength(100),
            ])
        });

        for (let field in this.colorsList) {
            this.form?.addControl(field, new FormControl(this.colorsList[field as FormPartnerCustomizationComponentColorName] ?? '', [
                Validators.minLength(0),
                Validators.maxLength(20),
            ]));
        }
    }

    protected updateForm(): void {
        if (!this.form) {
            return;
        }

        this.form.get('name')?.setValue(this.franchise?.name ?? '');

        const variables = (this.franchise?.preferences?.find(item => item.name === this.PREFERENCE_NAME)?.value as string)?.split(',');
        for (let variable in variables) {
            const [name, value] = variables[variable]?.split('=');
            this.form.get(name)?.setValue(value);
        }

        this.form?.markAsUntouched();
    }

    protected getFranchise(id: number): void {
        this.loadingQueue++;
        const subscription = this.franchiseService.getFranchise(id).subscribe({
            next: data => {
                this.loadingQueue--;
                this.franchise = data?.data;
                this.onFranchiseLoad.emit(this.franchise);
                this.updateForm();
            },
            error: error => {
                this.messageService.add({
                    severity: 'error',
                    detail: translate('Партньорът не съществува!'),
                });
                this.router.navigateByUrl('/partners');
            }
        });
        this.subscriptions.push(subscription);
    }

    protected mergePreferences(existing: FranchisePreference[], updates: FranchisePreference[]): FranchisePreference[] {
        const updatedNames = updates.map(pref => pref.name);
        const merged = existing
            .slice()
            .filter(pref => !updatedNames.includes(pref.name))
            .concat(updates)
            .map(item => (item.value = '' + (item.value ?? ''), item));

        return merged;
    }

    protected getCssVariable(camelCaseName: string): string {
        return '--' + camelCaseName?.replace(/([A-Z])/g, '-$1').toLowerCase();
    }

    protected getFieldValueFromCssVariable(camelCaseName: string): any {

        return this.getCssVariableValue(this.getCssVariable(camelCaseName));
    }

    protected getCssVariableValue(variableName: string): string {
        const root = this.el.nativeElement.ownerDocument.documentElement;
        return getComputedStyle(root).getPropertyValue(variableName).trim();
    }

    onReset(): void {
        const theme = this.colorThemes[this.colorTheme]?.colors
            ?? this.colorThemes[this.COLOR_DEFAULT_THEME]?.colors
            ?? {colors: {}};

        for (let field in this.colorsList) {
            this.form?.get(field)?.setValue(theme[field as FormPartnerCustomizationComponentColorName] ?? this.getFieldValueFromCssVariable(field));
        }
    }

    onModalRefresh(event?: string): void {
        this.showRefreshAskModal = false;

        if (event !== 'yes') {
            return;
        }

        setTimeout(() => {
            window?.location?.reload();
        }, 150);
    }

    onChangeColor(event?: any): void {
        this.colorTheme = '';
    }
}
