import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../models/user/user.model';
import { AuthService } from '../../services/auth.service';
import { NavigationService, NavigationServiceMenu } from '../../services/navigation.service';
import { NotificationService } from '../../services/notification.service';
import { FranchiseService } from '../../services/franchise.service';
import { Franchise } from '../../models/franchise.model';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];

    user: User|null = null;
    now: Date = new Date();
    navigation: NavigationServiceMenu[] = [];
    company: Franchise|null = null;
    navCalendarHide: boolean = false;
    navMessagesHide: boolean = false;

    currentNotificationsCount: number = 0;

    constructor(
        protected authService: AuthService,
        protected navigationService: NavigationService,
        protected notificationService: NotificationService,
        protected franchiseService: FranchiseService,
    ) {}

    ngOnInit(): void {
        this.user = this.authService.getUser() ?? this.user;

        const authSubscription = this.authService.getStatus().subscribe(status => {
            this.user = this.authService.getUser();
        });
        this.subscriptions.push(authSubscription);

        this.navigation = this.navigationService.getNavigation();

        const notificationsCountSubscription = this.notificationService.getMessagesCount().subscribe(count => {
            this.currentNotificationsCount = count;
        });
        this.subscriptions.push(notificationsCountSubscription);

        const companySubscription = this.franchiseService.getCompanyDetails().subscribe(data => {
            this.company = data;

            this.navCalendarHide = this.company?.isPreference('mainmenu.hide.calendar', true) ?? false;
            this.navMessagesHide = this.company?.isPreference('mainmenu.hide.messages', true) ?? false;
        });
        this.subscriptions.push(companySubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

}
