import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, map } from 'rxjs';
import { User } from '../models/user/user.model';
import { FilterSort } from '../models/filter-sort.model';
import { UserAuth } from '../models/user/user-auth.model';
import { Paginate } from '../models/paginate.model';
import { UserRole, UserRoleName } from '../models/user/user-role.model';
import { PointOther } from '../models/scoreboard/point-other.model';
import { Payment } from '../models/payment.model';
import { Certificate } from '../models/certificate/certificate.model';
import { HomeworkFile } from '../models/homework-file.model';
import { GroupCourse } from '../models/user/user-group-course.model';
import { UserImportResult } from '../models/user/user-import-result.model';
import { Present } from '../models/scoreboard/present.model';
import { ProjectFile } from '../models/project-file.model';
import { TestUserResult } from '../models/test-user-result.model';

export type UserServiceTitleType = (
    'teacher'
    | 'partner'
    | 'student'
);

export type UserServiceRoleType = (
    'admin'
    | 'partner'
    | 'teacher'
    | 'student'
);

export type UserServiceSortType = (
    | 'id'
    | 'name'
    | 'lastname'
    | 'email'
    | 'status'
    | 'type'
    | 'updated_at'
    | 'created_at'
);

export type UserServiceIncludes = (
    'groups'
    | 'groupsTeachers'
    | 'certificates'
    | 'homeworks'
    | 'tests'
    | 'payments'
    | 'points'
    | 'cv'
    | 'awards'
    | 'projects'
);

export interface UserServicePaginate {
    page?: number;
    limit?: number;
    include?: any;
};

export interface UserServiceIndex extends UserServicePaginate {
    sort?: UserServiceSortType|FilterSort<UserServiceSortType>[];
    role?: UserServiceRoleType;
    q?: string;
    except?: string;
    group?: number;
    course?: number;
    franchise?: number;
    status?: ('active'|'inactive');
};

export interface UserServiceIndexPoints {
    page?: number;
    limit?: number;
    include?: string|UserServiceIncludes|UserServiceIncludes[];
};

export interface UserServiceFilter {
    include?: string|UserServiceIncludes|UserServiceIncludes[];
};

export interface InputUser {
    role?: UserRoleName;
    email?: string;
    password?: string;
    name?: string;
    surname?: string;
    lastname?: string;
    phone?: string;
    image?: string;
    franchise?: number;
    status?: ('active'|'inactive');
    birthday?: string;
    town?: number;
    description?: string;
    teacher_status?: ('active'|'inactive');
    school?: string;
    grade?: number;
    medical_notes?: string;
    parent_name?: string;
    parent_phone?: string;
    parent_email?: string;
    facebook?: string;
    github?: string;
    website?: string;
    linkedin?: string;
    tiktok?: string;
    position?: string;
};

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private api: ApiService) {}

    getList(filter?: UserServiceIndex, maxCacheTime?: number): Observable<Paginate<User>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as UserServiceSortType);

        return this.api.get('/users', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<User>(), data);
                data.data = data.data?.map((item: any)=> User.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get User Info
     * @param id
     * @param filter
     * @returns
     */
    getItem(id: number, filter?: UserServiceFilter): Observable<{ data: User }> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.include?.length && (typeof filter.include === 'object') && (filter['include'] = filter.include?.join(','));

        return this.api.get('/users/' + id, {params: filter}).pipe(
            map(data => {
                data.data = User.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Get groups where user is related to
     *
     * @param id
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getGroups(id: number, filter?: UserServicePaginate, maxCacheTime?: number): Observable<Paginate<GroupCourse>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/users/' + id + '/groups', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<GroupCourse>(), data);
                data.data = data.data?.map((item: any)=> GroupCourse.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get scoreboard results per user
     *
     * @param id
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getPoints(id: number, filter?: UserServicePaginate, maxCacheTime?: number): Observable<Paginate<PointOther>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/users/' + id + '/points', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<PointOther>(), data);
                data.data = data.data?.map((item: any)=> PointOther.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get awards for user
     *
     * @param id
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getAwards(id: number, filter?: UserServicePaginate, maxCacheTime?: number): Observable<Paginate<Present>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/users/' + id + '/awards', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<Present>(), data);
                data.data = data.data?.map((item: any)=> Present.fromJson(item));
                return data;
            })
        );
    }


    /**
     * Get list of payments
     *
     * @param id
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getPayments(id: number, filter?: UserServicePaginate, maxCacheTime?: number): Observable<Paginate<Payment>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/users/' + id + '/payments', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<Payment>(), data);
                data.data = data.data?.map((item: any)=> Payment.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get list of certificates
     *
     * @param id
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getCertificates(id: number, filter?: UserServicePaginate, maxCacheTime?: number): Observable<Paginate<Certificate>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/users/' + id + '/certificates', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<Certificate>(), data);
                data.data = data.data?.map((item: any)=> Certificate.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get Homework
     *
     * @param id
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getHomeworks(id: number, filter?: UserServicePaginate, maxCacheTime?: number): Observable<Paginate<HomeworkFile>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/users/' + id + '/homeworks', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<HomeworkFile>(), data);
                data.data = data.data?.map((item: any)=> HomeworkFile.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get Projects
     *
     * @param id
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getProjects(id: number, filter?: UserServicePaginate, maxCacheTime?: number): Observable<Paginate<ProjectFile>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/users/' + id + '/projects', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<ProjectFile>(), data);
                data.data = data.data?.map((item: any)=> ProjectFile.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get list of tests or exams
     *
     * @param id
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getExams(id: number, filter?: UserServicePaginate, maxCacheTime?: number): Observable<Paginate<TestUserResult>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);

        return this.api.get('/users/' + id + '/tests', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<TestUserResult>(), data);
                data.data = data.data?.map((item: any)=> TestUserResult.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Add User
     *
     * @param id
     * @param data
     * @returns
     */
    add(data: InputUser): Observable<{data: User}> {
        return this.api.post('/users', data).pipe(
            map(data => {
                data.data = User.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Edit User
     *
     * @param id
     * @param data
     * @returns
     */
    edit(id: number, data: InputUser): Observable<{data: User}> {
        return this.api.post('/users/' + id, data).pipe(
            map(data => {
                data.data = User.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Sign as another user
     *
     * @param id User ID
     */
    signAs(id: number): Observable<{data: UserAuth}> {
        return this.api.post('/users/' + id + '/signas').pipe(
            map(data => {
                data.data = UserAuth.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Remove user from database
     * @param id
     * @returns
     */
    delete(id: number): Observable<{data: any}> {
        return this.api.delete('/users/' + id);
    }

    /**
     * Get Available Roles
     * @returns
     */
    getRoles(): Observable<{data: UserRole}> {
        return this.api.get('/users/roles').pipe(
            map(data => {
                data.data = UserRole.fromJson(data?.data);
                return data;
            })
        );
    }

    importCsv(file:Blob, filename: string, franchise: number = 0): Observable<{data: UserImportResult}> {
        let data = new FormData();
        data.append('file', file, filename);

        franchise > 0 && data.append('franchise', '' + franchise);

        return this.api.post('/users/imports', data, {
            headers: {headers:{
                'Content-Type': 'application/octet-stream'}
            },
        }).pipe(
            map(data => {
                data.data = UserImportResult.fromJson(data?.data);
                return data;
            })
        );
    }
}
