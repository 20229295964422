import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    transform(value?: Date): Date|string {
        const time: moment.Moment = moment(value);

        if ( moment().diff(time, 'day') < 1) {
            return time.format('HH:mm').toString();
        }

        return time.fromNow();
    }
}
