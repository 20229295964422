<div class="news custom-p-carousel mb-4" *ngIf="messages?.length">
    <p-carousel [value]="messages"
                [circular]="(messages?.length ?? 0) > 1"
                [numVisible]="1"
                [numScroll]="1"
                [showNavigators]="false"
                [showIndicators]="(messages?.length ?? 0) > 1"
                [autoplayInterval]="autoplayInterval"
    >
        <ng-template pTemplate="item" let-message>
            <div class="new-bubble d-flex p-3 m-2 mb-5">
                <div class="ps-2 d-flex flex-column">
                    <div class="d-flex">
                        <div class="message-icon pe-2">
                            <i class="icon-email"></i>
                        </div>
                        <div>
                            <p class="pe-2">{{ message?.description }}</p>
                            <small class="mt-auto date">{{ message?.start_date | dateLocale:'short' }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel>
</div>
