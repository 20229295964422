import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Group } from '../../models/group.model';
import { CourseShort } from '../../models/course-short.model';
import { FranchiseCourse } from '../../models/franchise-course.model';
import { Course } from '../../models/course.model';

@Component({
    selector: 'card-group',
    templateUrl: './card-group.component.html',
    styleUrls: ['./card-group.component.scss']
})
export class CardGroupComponent implements OnInit {
    group: Group|null = new Group;

    @Input('group')
    set groupValue(val: Group|Course|FranchiseCourse|undefined) {
        if (this.group !== val) {
            if (val instanceof CourseShort) {
                this.group = Group.fromJson(val);
            } else if (val instanceof FranchiseCourse) {
                this.group = FranchiseCourse.fromJson(val.course);
                this.group.title = '';
                this.group.course = Course.fromJson(Object.assign({}, val?.course, {title: val?.course?.title}))
                this.group.id = val.id;
            } else if (val instanceof Course) {
                this.group = Group.fromJson(val);
                this.group.title = '';
                this.group.course = Course.fromJson(Object.assign({}, val, {title: val?.title}))
                this.group.id = val.id;
            } else {
                this.group = val ?? null;
            }
        }
    }
    get groupValue(): Group|undefined {
        return this.group ?? undefined;
    }

    @Input() type: (null|'view'|'show'|'homework'|'course') = null;

    @Input() isCompetition: boolean = false;

    constructor(
        protected router: Router,
    ) { }

    ngOnInit(): void {}

    preventPropagation(event?: MouseEvent): boolean {
        event?.stopPropagation();
        return true;
    }

}
