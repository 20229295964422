import { Injectable, inject } from '@angular/core';
import { Database, getDatabase, ref, onValue, DataSnapshot } from '@angular/fire/database';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, share } from 'rxjs';
import { CookieService } from './cookie.service';

@Injectable({
    providedIn: 'root'
})
export class RuntimeService {
    private readonly BUILD_NAME: string = 'build';
    private readonly DATABASE_PATH: string = 'build';

    protected subjectRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected database: Database = inject(Database);
    protected firstRun: boolean = true;

    constructor(
        private cookieService: CookieService,
    ) {
        if (!this.cookieService.isSupported()) {
            return;
        }

        const build = this.getBuild();
        const db = getDatabase(this.database.app);
        const reference = ref(db, `${this.DATABASE_PATH}/${environment?.name}`);

        onValue(reference, (snapshot: DataSnapshot) => {
            const buildSnapshot = '' + (snapshot.val() ?? '');

            if (build !== buildSnapshot) {
                const buildUnset: boolean = !build || !build?.length;

                try {
                    this.cookieService.set(this.BUILD_NAME, buildSnapshot ?? '', 365, '/');
                } catch (e) { }

                if (!buildUnset && !this.firstRun) {
                    this.subjectRefresh.next(true);
                }
            }

            this.firstRun = false;
        });

        setTimeout(() => {
            this.firstRun = false;
        }, 5000);
    }

    getBuild(): string {
        if (!this.cookieService.isSupported()) {
            return '';
        }
        return this.cookieService.get(this.BUILD_NAME) ?? '';
    }

    /**
     * Returns status for refresh
     */
    askRefresh(): Observable<boolean> {
        return this.subjectRefresh.asObservable().pipe(share());
    }
}
