export class TestResultAnswer {
    id?: number;
    question_id?: number;
    question?: string;
    question_image?: string;
    question_photo?: {
        src: string,
        caption: string,
        thumb: string
    };
    answer?: string;
    result?: number;
    correct?: boolean;
    answer_image?: string;
    answer_photo?: {
        src: string,
        caption: string,
        thumb: string
    };

    static fromJson(fields: any): TestResultAnswer {
        let item = Object.assign(new this(), fields);
        return item;
    }
}
