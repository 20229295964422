import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
import { Inject, Injectable, isDevMode, NgModule, PLATFORM_ID } from '@angular/core';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { isPlatformServer } from '@angular/common';

import { environment } from '../environments/environment';
import { RuntimeService } from './shared/services/runtime.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    protected readonly DEBUG: boolean = !environment.production;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private document: any,
        private http: HttpClient,
        private runtimeService: RuntimeService,
    ) {}

    getTranslation(lang: string) {
        let path = '/';
        if (isPlatformServer(this.platformId)) {
            const location = this.document?.location;
            const baseHref = this.document?.getElementsByTagName('base')?.[0]?.getAttribute('href') ?? '/';
            path = location?.hostname + (location?.port?.length && ![null, 80, 443].includes(location?.port) ? ':' + location.port : '') + baseHref;
            path = path.replace(/\/+/g, '/');
            path = location?.protocol + "//" + path;

            console.info("SSR I18n: " + path + 'assets/i18n/' + lang + '.json?v=' + this.runtimeService?.getBuild());
        }

        return this.http.get<Translation>(path + 'assets/i18n/' + lang + '.json?v=' + this.runtimeService?.getBuild());
    }
}

@NgModule({
    exports: [ TranslocoModule ],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: environment.locales.available?.map((item: any) => {
                    return {
                        id: item.id ?? '',
                        label: item.label ?? '',
                    }
                }),
                defaultLang: environment?.locales?.default ?? 'bg_BG',
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            })
        },
        {
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        },
        provideTranslocoMessageformat({
            locales: environment.locales.available?.map((item: any) => item.id.replace('_', '-')),
        }),
    ]
})
export class TranslocoRootModule {}
