export type UserPreferenceType = ('integer'|'string'|'boolean');

export class UserPreference {
    id?: number;
    type?: UserPreferenceType;
    name?: string;
    value?: boolean|string|number|null;

    static fromJson(fields: any): UserPreference {
        let item = Object.assign(new this(), fields);

        item.value = item?.type === 'integer' ? Number(item.value)
            : item?.type === 'boolean' ? String(item.value).trim().toLowerCase() === 'true'
            : item?.type === 'string' ? String(item.value)
            : null;

        return item;
    }
}
