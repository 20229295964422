import { Group } from "./group.model";
import { ProjectFile } from "./project-file.model";
import { User } from "./user/user.model";

export class Project {
    id?: number;
    status?: string;
    max_points?: number;
    title?: string;
    description?: string;
    latest?: ProjectFile;
    user?: User;
    group?: Group;
    files?: ProjectFile[];

    static fromJson(fields: any): Project {
        let item = Object.assign(new this(), fields);
        item.latest = ProjectFile.fromJson(fields?.latest);
        item.user = User.fromJson(fields?.user);
        item.group = Group.fromJson(fields?.group);

        item.files = fields?.files?.map((item: any) => ProjectFile.fromJson(item));
        return item;
    }
}
