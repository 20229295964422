<div class="flex-column flex-shrink-0 d-none d-md-block d-lg-block d-xl-block side-nav" style="width: 4.5rem;" >
    <ul class="nav nav-pills nav-flush flex-column mb-auto text-center flex-nowrap">

        <li class="nav-item" *ngFor="let menu of navigation">
            <a href="#"
                #menuItem
                class="nav-link py-3 position-relative"
                [routerLink]="menu.url"
                routerLinkActive="active"
                [routerLinkActiveOptions]="menu.options || {exact: false}"
                [attr.data-bs-title]="menu.title"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                (mouseenter)="onNavItemEnter($event, menuItem)"
                (mouseleave)="onNavItemLeave($event, menuItem)"
                (click)="onNavItemLeave($event, menuItem)"
            >
                <i [class]="menu.icon" [class.position-relative]="true">
                    <span *ngIf="(menu?.title === 'Съобщения' || menu?.title === 'Messages') && currentNotificationsCount > 0" class="notification-indicator" [class.smaller]="currentNotificationsCount > 99">
                        {{ currentNotificationsCount < 100 ? currentNotificationsCount : ('99+') }}
                    </span>
                </i>
            </a>
        </li>

    </ul>
    <ul *ngIf="company?.phone || company?.facebook || !company?.id || company?.primary"
        class="nav nav-pills nav-flush flex-column mb-auto text-center flex-nowrap py-3 mt-3 bottom-side-nav" >
        <li *ngIf="company?.phone || !company || company?.primary">
            <a [href]="'tel:' + (company?.phone?.length ? company?.phoneFormatted : '359889990030')"
                #phone
                class="nav-link my-2 side-social-icon"
                [attr.data-bs-title]="'Телефон за връзка' | transloco"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                target="_blank"
                (mouseenter)="onNavItemEnter($event, phone)"
                (mouseleave)="onNavItemLeave($event, phone)"
            >
                <i class="icon-phone fill-icon"></i>
            </a>
        </li>
        <li *ngIf="!company?.id || company?.primary || company?.facebook">
            <a [href]="company?.facebook?.length ? company?.facebook : 'https://www.facebook.com/advanceacademy.bg'"
                #email
                class="nav-link my-2 side-social-icon"
                [attr.data-bs-title]="'Посетете Facebook страницата ни' | transloco"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                target="_blank"
                (mouseenter)="onNavItemEnter($event, email)"
                (mouseleave)="onNavItemLeave($event, email)"
            >
                <i class="fa-brands fa-facebook-f fill-icon"></i>
            </a>
        </li>
    </ul>
</div>
