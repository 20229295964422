import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { ScriptService } from '../../services/script.service';
import { RuntimeService } from '../../services/runtime.service';
import { I18nService } from '../../services/i18n.service';

@Component({
    selector: 'input-text-editor',
    templateUrl: './input-text-editor.component.html',
    styleUrls: ['./input-text-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextEditorComponent),
            multi: true
        }
    ]
})
export class InputTextEditorComponent implements OnInit, OnDestroy {
    protected apiUrl = (environment?.scheme ?? 'http') + "://"
        + (environment?.domain ?? 'localhost')
        + (!environment?.port || ['443', '80'].indexOf('' + (environment?.port ?? '80')) >= 0 ? '' : ':' + environment?.port)
        + '/api/';

    private innerValue: string = '';

    private onTouchedCallback: () => void = () => { };
    private onChangeCallback: (_: any) => void = () => { };

    @Output() onChange = new EventEmitter();

    @Input() uploadImageApi: string = '/uploads/image';

    @Input()
    set value(value: any) {
        if (this.innerValue !== value) {
            this.innerValue = value;
            this.onChangeCallback(value);
            this.onChange.emit(value);
        }

        this.onTouchedCallback();
    }
    get value(): any {
        return this.innerValue;
    }

    writeValue(value: any) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    registerOnChange(fn: (_: any) => void) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouchedCallback = fn;
    }

    public ckEditor: any = null;

    constructor(
        private i18nService: I18nService,
        private scriptService: ScriptService,
        private authService: AuthService,
        private runtimeService: RuntimeService,
    ) { }

    ngOnInit(): void {
        const authToken = this.authService.user.getToken();
        const locale = this.i18nService.getLocaleDetails().language ?? '';

        (async () => {
            await this.scriptService.loadScript('/assets/js/ckeditor5/translations/' + locale + '.js?' + this.runtimeService?.getBuild());

            const status = await this.scriptService.loadScript('/assets/js/ckeditor5/ckeditor.js?' + this.runtimeService?.getBuild());
            if (status.loaded) {
                // @ts-ignore
                this.ckEditor = window['ClassicEditor'];

                this.ckEditor.defaultConfig.simpleUpload = Object.assign(this.ckEditor.defaultConfig.simpleUpload, {
                    uploadUrl: this.apiUrl?.replace(/\/+$/, '') + '/' + this.uploadImageApi.replace(/^\//, ''),
                    headers: {
                        Authorization: 'Bearer ' + authToken,
                    }
                });
            }
        })();
    }

    ngOnDestroy(): void {

    }

}
