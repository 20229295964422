import { Module } from "./module.model";

export class CourseShort {
    id?: number;
    title?: string;
    image?: string;
    logo?: string;
    level?: number;
    duration?: number;
    description?: string;
    // price?: string;
    modules?: Module[];

    static fromJson(fields: any): CourseShort {
        let item = Object.assign(new this(), fields);
        item.modules = fields?.modules?.map((employment: Module) => Module.fromJson(module));
        return item;
    }
}
