import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef } from '@angular/core';
import { ItemTemplateDirective } from '../../directives/item-template.directive';
import { UploadComponentQueue, UploadComponentType } from '../upload/upload.component';
import { translate } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'upload-blog',
    templateUrl: './upload-blog.component.html',
    styleUrls: ['./upload-blog.component.scss']
})
export class UploadBlogComponent implements OnInit, AfterContentInit {
    @ContentChildren(ItemTemplateDirective) templates?: QueryList<any>;

    @Input() disabled: boolean = false;
    @Input() files: number = 1;
    @Input() loading: boolean = false;
    @Input() withRemove: boolean = true;
    @Input() preview: string|null = null;
    @Input() maxSize: number = 30;
    @Input() labelRemove: string = translate('Премахни');
    @Input() labelOpen: string = translate('Отвори');
    @Input() type: UploadComponentType = 'blog';

    @Output() onFinished: EventEmitter<any> = new EventEmitter();
    @Output() onUploaded: EventEmitter<any> = new EventEmitter();
    @Output() onError: EventEmitter<any> = new EventEmitter();
    @Output() onRemove: EventEmitter<any> = new EventEmitter();

    templateDefault?: TemplateRef<any>;
    templateLoading?: TemplateRef<any>;
    templateAdditional?: TemplateRef<any>;

    constructor(
        private messageService: MessageService,
    ) { }

    ngOnInit(): void {
    }

    onFilesFinished(event?: any): void {
        this.onFinished.emit(event);
    }

    onFileUploaded(event?: any): void {
        this.onUploaded.emit(event);
    }

    onFileError(event?: any): void {
        if (!this.onError?.observed) {
            this.messageService.add({
                severity: 'error',
                detail: event?.error?.error?.errors[Object.keys(event.error?.error?.errors)[0] ?? '']?.[0]?.message
                    ?? event?.error?.error?.message
                    ?? event?.error
                    ?? translate('Възникна неизвестна грешка!')
            });
        }

        this.onError.emit(event);
    }

    onFileRemove(event?: any): void {
        this.onRemove.emit();
    }

    ngAfterContentInit() {
        this.templates?.forEach((item) => {
            switch (item.getType()) {
                case 'default':
                    this.templateDefault = item.template;
                    break;

                case 'loading':
                    this.templateLoading = item.template;
                    break;

                case 'additional':
                    this.templateAdditional = item.template;
                    break;
            }
        });
    }
}
