<p-dialog
    [visible]="true"
    (visibleChange)="onClose($event)"
    [closable]="true"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
    styleClass="mx-3 mx-sm-0"
    [header]="'Имате непопълнени анкети' | transloco"
>

    <div class="container">
        <div class="row">
            <div class="col-12">
               <div *ngFor="let poll of polls; let last = last">
                    <div class="poll d-flex border-bottom justify-content-between align-items-center py-3" [class.border-bottom]="!last">
                        <h2 class="m-0">{{poll?.popup_title ?? poll?.title ?? ('Анкета' | transloco)}}</h2>
                        <button class="btn btn-primary px-3" (click)="onSelectPoll({value: poll})">
                            {{'Преглед' | transloco}}
                            <i class="fa-solid fa-solid fa-chevron-right fa-fw"></i>
                        </button>
                    </div>
               </div>
            </div>
        </div>
    </div>

</p-dialog>
