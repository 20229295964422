<p-dialog
    [visible]="true"
    (visibleChange)="onCloseAction($event)"
    [closable]="true"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
    styleClass="mx-3 mx-sm-0"
    [header]="('Редактиране на плащане' | transloco)"
>

    <form class="standard-form payment-details-form" *ngIf="form" [formGroup]="form">
        <div class="row">
            <div class="col-lg-4 mb-3">
                <label for="price">{{ 'Цена BGN:' | transloco }}</label>
                <input  requiredField requiredFieldAttribute="price"
                        type="text"
                        id="price"
                        class="form-control"
                        formControlName="price"
                        [class.is-invalid]="form.controls['price'].invalid"
                >
                <div class="invalid-feedback" *ngIf="form.controls['price']?.errors?.['required']">{{ 'Задължително поле' | transloco }}</div>
            </div>
            <div class="col-lg-4 mb-3">
                <label for="type">{{ 'Тип' | transloco }}</label>
                <p-dropdown requiredField requiredFieldAttribute="type"
                            [class.is-invalid]="form.controls['type'].invalid"
                            [options]="paymentTypes"
                            formControlName="type"
                            optionValue="id"
                            optionLabel="name"
                            styleClass="w-100"
                            inputId="type"
                ></p-dropdown>
                <div class="invalid-feedback" *ngIf="form.controls['type']?.errors?.['required']">{{ 'Задължително поле' | transloco }}</div>
            </div>
            <div class="col-lg-4 mb-3">
                <label for="due">{{ 'Срок на плащане' | transloco }}</label>
                <p-calendar requiredField
                            inputId="due"
                            class="d-block"
                            [class.is-invalid]="errors?.date?.status === 'INVALID'"
                            [inputStyleClass]="'form-control w-100 ' + (errors?.date?.status === 'INVALID' ? 'is-invalid' : '')"
                            formControlName="date"
                            todayButtonStyleClass="btn btn-sm btn-primary"
                            clearButtonStyleClass="btn btn-sm btn-primary"
                            hourFormat="24"
                            dateFormat="dd.mm.yy"
                            [firstDayOfWeek]="1"
                            [showIcon]="true"
                            [selectOtherMonths]="true"
                            [showButtonBar]="true"
                            [baseZIndex]="9999"
                            appendTo="body"
                ></p-calendar>
                <div class="invalid-feedback" *ngIf="errors?.date?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-4 mb-3">
                <input-payment-chooser  [label]="'Метод на плащане:' | transloco"
                                        formControlName="method"
                ></input-payment-chooser>
            </div>

            <div class="col-12 col-sm-6 col-md-auto mb-3">
                <div class="form-check form-check-inline d-flex flex-column h-100 ps-0 mb-3 mb-md-0">
                    <label [for]="'status'" class="mb-1 w-100">{{ 'Платена вноска:' | transloco }}</label>
                        <div class="form-check form-switch my-auto">
                            <input requiredField [requiredFieldAttribute]="'status'"
                                type="checkbox"
                                id="status"
                                formControlName="status"
                                [placeholder]="'Цена' | transloco"
                                [disabled]="loading"
                                class="form-check-input"
                            >
                        </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <label [for]="'description'">{{ 'Описание:' | transloco }}</label>
                <textarea requiredField requiredFieldAttribute="description"
                            rows="3"
                            type="text"
                            [id]="'description'"
                            class="form-control"
                            formControlName="description"
                            [placeholder]="'Описание' | transloco"
                            [attr.disabled]="loading ? true : null"
                ></textarea>
            </div>
        </div>
    </form>

    <ng-template pTemplate="footer">
        <div class="row">
            <div class="col-12">
                <button type="button"
                        class="btn btn-primary mx-2"
                        [disabled]="loading"
                        (click)="onSubmit($event)"
                >
                    <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                    <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                    <span>{{ 'Запиши' | transloco }}</span>
                </button>

                <button type="button"
                        class="btn btn-secondary"
                        [disabled]="loading"
                        type="button"
                        (click)="onCloseAction($event)"
                >

                        <i class="fa-solid fa-xmark fa-fw"></i>
                        {{ 'Отказ' | transloco }}

                </button>
            </div>
        </div>
    </ng-template>

</p-dialog>
