import { ElementRef, Injectable } from '@angular/core';

export interface DomServiceMoveToOptions {
    topOffset?: number;
    left?: number;
    behavior?: ScrollBehavior;
    startsWithin?: number;
};

@Injectable({
    providedIn: 'root'
})
export class DomService {
    protected scrollScreenTimer: any = null;

    constructor() { }

    closestTo(el: any, selector: string): any|null {
        do {
            if ((el.matches || el.matchesSelector).call(el, selector)) {
                break;
            }
        } while (el = el.parentElement);

        return el;
    }

    /**
     * Move page vertically by given element
     *
     * @param target Which element is attached to or at what position (px)
     * @param options
     */
    moveTo(target?: ElementRef|HTMLElement|string|number, options?: DomServiceMoveToOptions): void {
        options = Object.assign({
            topOffset: 120,
            left: 0,
            behavior: 'smooth',
            startsWithin: 250,
        }, options ?? {});

        this.scrollScreenTimer && clearTimeout(this.scrollScreenTimer);
        this.scrollScreenTimer = setTimeout(() => {
            if (typeof window === 'undefined') {
                return;
            }
            let top: number;

            if (typeof target === 'number') {
                top = target;
            } else {
                let element: HTMLElement | null = null;

                if (target instanceof ElementRef) {
                    element = target.nativeElement;
                } else if (target instanceof HTMLElement) {
                    element = target;
                } else if (typeof target === 'string') {
                    element = document.querySelector(target);
                }

                top = element ? element.getBoundingClientRect().top + window.scrollY : 0;
            }

            const position = Math.max(0, top - (options?.topOffset ?? 120));
            window?.scrollTo({
                left: options?.left ?? 0,
                top: position,
                behavior: options?.behavior ?? 'smooth'
            });

            if (this.scrollScreenTimer) {
                clearTimeout(this.scrollScreenTimer);
            }
        }, options?.startsWithin);
    }

    moveToTop(): void {
        if (typeof document !== 'undefined' && 'documentElement' in document && 'scrollTop' in document.documentElement) {
            document.documentElement.scrollTop = 0;
        }

        // Safari (and older Chrome) fallback
        if (typeof document !== 'undefined' && 'body' in document && 'scrollTop' in document.body) {
            document.body.scrollTop = 0;
        }
    }

    moveToBottom(): void {
        setTimeout(() => {
            if (typeof document !== 'undefined' && 'documentElement' in document && 'scrollTop' in document.documentElement) {
                document.documentElement.scrollTop = document.documentElement.scrollHeight;
            }

            // Safari (and older Chrome) fallback
            if (typeof document !== 'undefined' && 'body' in document && 'scrollTop' in document.body) {
                document.body.scrollTop = document.body.scrollHeight;
            }
        });
    }
}
